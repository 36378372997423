import { CONFIG } from "../constants/config";
import APIClient from "./api";

const baseUrl = new URL(CONFIG.URL);

const buildInquiryRedirectUri = (offeringId, investmentId) => {
  const url = new URL(
    `/offering/${offeringId}/verify/result/${investmentId}`,
    baseUrl
  );
  return url.toString();
};

class InquiryClient {
  /**
   * @param {string} id
   * @returns {Promise<object>}
   */
  static async get(id) {
    const response = await APIClient().get(`/v2/inquiries/${id}`);
    return response.data;
  }

  /**
   *
   * @param {object} data
   * @param {string} data.offeringId
   * @param {string} data.investmentId
   * @returns {Promise<object>}
   */
  static async start({ offeringId, investmentId }) {
    const response = await APIClient().post("/v2/inquiries/start", {
      offering_id: offeringId,
      investment_id: investmentId,
      redirect_uri: buildInquiryRedirectUri(offeringId, investmentId),
    });

    return response.data;
  }

  static async status(userId, inquiryId) {
    const response = await APIClient().get(
      `/v2/inquiries/status/${userId}/${inquiryId}`
    );

    return response.data;
  }
}

export default InquiryClient;
