import React, { useContext } from "react";
import * as Icon from "react-feather";

import clsx from "clsx";
import styles from "./ProgressBar.module.css";

const CurrentStepContext = React.createContext(null);

const ProgressBarStep = ({ index, children, icon, className }) => {
  const currentIndex = useContext(CurrentStepContext);
  const isActive = currentIndex >= index;

  return (
    <li className={clsx(className, { [styles.active]: isActive })}>
      {icon && (
        <span className={clsx(styles.icon, { [styles.active]: isActive })}>
          {icon}
        </span>
      )}
      <span className={clsx("d-md-block", "px-1")}>{children}</span>
    </li>
  );
};

export const OpenOfferingProgressBar = ({ index }) => {
  return (
    <CurrentStepContext.Provider value={index}>
      <div className="container">
        <ul className={styles.list}>
          <ProgressBarStep
            index={1}
            icon={<Icon.UserPlus size={16} color="white" />}
          >
            Register
          </ProgressBarStep>
          <ProgressBarStep
            index={2}
            icon={<Icon.UserCheck size={16} color="white" />}
          >
            Questionnaire
          </ProgressBarStep>
          <ProgressBarStep
            index={3}
            icon={<Icon.Shield size={16} color="white" />}
          >
            KYC/KYB
          </ProgressBarStep>
          <ProgressBarStep
            index={4}
            icon={<Icon.FileText size={16} color="white" />}
          >
            Subscription
          </ProgressBarStep>
          <ProgressBarStep
            index={5}
            icon={<Icon.FileText size={16} color="white" />}
          >
            Agreement
          </ProgressBarStep>
          <ProgressBarStep
            index={6}
            icon={<Icon.DollarSign size={16} color="white" />}
          >
            Payment
          </ProgressBarStep>
          <ProgressBarStep
            index={7}
            icon={<Icon.Lock size={16} color="white" />}
          >
            Password
          </ProgressBarStep>
          <ProgressBarStep
            index={8}
            icon={<Icon.FileText size={16} color="white" />}
          >
            Accreditation
          </ProgressBarStep>
        </ul>
        <div className="progress">
          <div
            className="progress-bar progress-bar-striped progress-bar-animated"
            role="progressbar"
            aria-valuemin="0"
            aria-valuemax="100"
            style={{ width: `${index * (100 / 8)}%` }}
          ></div>
        </div>
      </div>
    </CurrentStepContext.Provider>
  );
};

const ProgressBar = ({ index }) => {
  return (
    <CurrentStepContext.Provider value={index}>
      <div className="container">
        <ul className={styles.list}>
          <ProgressBarStep
            index={1}
            icon={<Icon.UserCheck size={16} color="white" />}
          >
            Questionnaire
          </ProgressBarStep>
          <ProgressBarStep
            index={2}
            icon={<Icon.Shield size={16} color="white" />}
          >
            Offering
          </ProgressBarStep>
          <ProgressBarStep
            index={3}
            icon={<Icon.FileText size={16} color="white" />}
          >
            Subscription
          </ProgressBarStep>
          <ProgressBarStep
            index={4}
            icon={<Icon.FileText size={16} color="white" />}
          >
            Agreement
          </ProgressBarStep>
          <ProgressBarStep
            index={5}
            icon={<Icon.DollarSign size={16} color="white" />}
          >
            Payment
          </ProgressBarStep>
        </ul>
        <div className="progress">
          <div
            className="progress-bar progress-bar-striped progress-bar-animated"
            role="progressbar"
            aria-valuemin="0"
            aria-valuemax="100"
            style={{ width: `${index * 20}%` }}
          ></div>
        </div>
      </div>
    </CurrentStepContext.Provider>
  );
};

export default ProgressBar;
