import UserTokenService from "../services/UserTokenService";
import APIClient from "./api";

class InvestmentClient {
  /**
   * @param {string} id
   * @return {Promise<object>}
   */
  static async get(id) {
    // Not sure where it's coming from but id is showing as a string with the value undefined
    if (!id || id === "undefined") {
      return;
    }
    const response = await APIClient().get(`/v2/investments/${id}`);
    return response.data;
  }

  /**
   * @return {Promise<object>}
   */
  static async currentInvestment() {
    const token = UserTokenService.token();
    if (!token) return null;
    const response = await APIClient().get(`/v2/investments/current`);
    return response.data;
  }

  /**
   * @return {Promise<object>}
   */
  static async all(params = {}) {
    const response = await APIClient().get(`/v2/investments`, { params });
    return response.data;
  }

  /**
   * @param {string} id
   * @param {object} data
   * @return {Promise<object>}
   */
  static async update(id, data = {}) {
    const response = await APIClient().patch(`/v2/investments/${id}`, data);
    return response.data;
  }

  /**
   * @param {string} id
   * @param {string} stage
   * @param {object} data
   * @return {Promise<object>}
   */
  static async updateStage(id, stage, data = {}) {
    const response = await APIClient().patch(
      `/v2/investments/${id}/stage/${stage}`,
      data
    );
    return response.data;
  }

  /**
   * @param {string} offeringId
   * @return {Promise<object>}
   */
  static async createInvestment(offeringId) {
    const response = await APIClient().post(`/v2/investments/`, {
      offering_id: offeringId,
    });
    return response.data;
  }

  static async getAgreementDocusignUrl(id, envelopeId, type) {
    const response = await APIClient().get(
      `/investment/${id}/agreement/${envelopeId}/url/${type}`
    );
    return response.data;
  }

  /**
   * @param {string} id
   * @param {string} status
   * @param {{ description?: string }} data
   * @return {Promise<object>}
   */
  static async legacyUpdateStatus(id, status, data = {}) {
    const response = await APIClient().post(
      `/investment/${id}/status/${status}`,
      data
    );
    return response.data;
  }

  static async legacyCreatePaymentIntent(id) {
    const response = await APIClient().post(`/investment/${id}/payment-intent`);
    return response.data;
  }

  static async legacyCreateEnvelope(id, templateId) {
    const response = await APIClient().post(
      `/investment/${id}/agreement/${templateId}`
    );
    return response.data;
  }

  static async resetInquiry(investmentId, inquiryId) {
    await APIClient().delete(
      `/v2/investments/${investmentId}/inquiry/${inquiryId}`
    );
  }
}

export default InvestmentClient;
