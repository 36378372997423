import { useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import AppHeader from '../../layouts/appHeader';
import Spinner from '../../components/spinner';

import axios from 'axios';

const Deposit = (props) => {
    const user = useSelector((store) => store.user);
    const [spinner, showSpinner] = useState(false);

    const sendToSlack = () => {
        showSpinner(true);
        axios.post(`/webhook/slack`, {
            text: `${user.name} (${user.email}) is interested in Deposit My Shares`,
            username: 'EquiDeFi Bot',
            channel: 'dev-notifications'
        }).then(response => {
            toast.success('Thank you, someone from EquiDeFi team will contact you shortly');
            showSpinner(false);
        })
    }

    return (
        <>
            <AppHeader name='Deposit My Shares'>
                <Spinner show={spinner}>
                    <button className='btn btn-eq-primary' onClick={() => sendToSlack()}>
                        I'm Interested
                    </button>
                </Spinner>
            </AppHeader>

            <div className='container-fluid'>
                <div className='row'>
                    <div className=' col-lg-8 col-xl-6'>
                        <img alt='deposit' src='/img/deposit.png' className='img-fluid' />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Deposit;