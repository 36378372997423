import { Formik } from "formik";
import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import * as Yup from "yup";

import { useLogin } from "../../hooks/useAuthentication";
import { toast } from "react-toastify";
import MFAModal from "../../components/MFAModal";
import { useInvestmentCreate } from "../../hooks/useInvestments";
import InvestmentClient from "../../clients/InvestmentClient";

const userSchema = Yup.object().shape({
  password: Yup.string().required("The password field is required"),
  email: Yup.string()
    .email("Must be a valid email")
    .required("The email field is required"),
});

const OpenOfferingUserLogin = ({ isLoading = false, error = null }) => {
  const { offeringId } = useParams();
  const [email, setEmail] = useState();
  const [displayMFA, setDisplayMFA] = useState(false);
  const loginUser = useLogin();
  const createInvestment = useInvestmentCreate();

  const infoUrl = `/offering/${offeringId}/info`;

  const onLogin = async (data) => {
    setEmail(data.email);
    try {
      await loginUser.mutateAsync({
        email: data.email,
        password: data.password,
      });
      setDisplayMFA(true);
    } catch (error) {
      if (error.response?.status === 401) {
        toast.error("Login failed, try again or reset your password");
      } else {
        toast.error(error.response.data.errors.join(". "));
      }
    }
  };

  const onComplete = async () => {
    let investment = await InvestmentClient.currentInvestment();

    if (!investment) {
      investment = await createInvestment.mutateAsync(offeringId);
    }

    return investment;
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <h2 className="text-md-start mb-0 mt-4">
            Let's Login to your Account
          </h2>
          <p>Enter your email and password to login</p>
          <Formik
            initialValues={{ password: null, email: null }}
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={userSchema}
            onSubmit={onLogin}
          >
            {({ errors, handleBlur, handleChange, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label htmlFor="email">
                        Email <span className="text-danger">*</span>
                      </label>
                      <input
                        id="email"
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="email"
                        className="form-control form-control-sm"
                      />
                      {errors?.email && (
                        <span className="text-danger">{errors?.email}</span>
                      )}
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-group">
                      <label htmlFor="password">
                        Password <span className="text-danger">*</span>
                      </label>
                      <input
                        id="password"
                        name="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="password"
                        className="form-control form-control-sm"
                      />
                      {errors?.password && (
                        <span className="text-danger">{errors?.password}</span>
                      )}
                      <Link to="/forgot">Forgot Password?</Link>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-start align-items-center align-content-center gap-3">
                  <button
                    className="btn btn-sm btn-eq-primary"
                    disabled={isLoading || error}
                    type="submit"
                  >
                    Submit
                  </button>
                  {isLoading && (
                    <div
                      className="spinner-grow text-secondary spinner-grow-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                </div>
                <br />
                <div className="form-group login">
                  <Link to={infoUrl}>
                    Don't have an account, click here to create one
                  </Link>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
      <MFAModal
        display={displayMFA}
        email={email}
        onToggle={() => setDisplayMFA(false)}
        onComplete={onComplete}
      />
    </div>
  );
};

export default OpenOfferingUserLogin;
