import React, { useMemo } from "react";
import Select from "react-select";
import { useOfferingBrokers } from "../../hooks/queries/offerings";

const OfferingBrokerSelect = ({ offeringId, value, onChange }) => {
  const { data: brokers, isLoading } = useOfferingBrokers(offeringId, {
    enabled: !!offeringId,
  });

  const brokerOptions = useMemo(() => {
    return (
      brokers?.map((value) => {
        const label = `${value.first_name} ${value.last_name}${
          value.company ? `, ${value.company} ` : ""
        } (${value.crd_number})`;

        return {
          value,
          label,
        };
      }) ?? []
    );
  }, [brokers]);

  return (
    <Select
      placeholder="Not directed to subscription by broker"
      noOptionsMessage={() => "No brokers assigned to this offering"}
      value={value}
      defaultValue={{
        value: null,
        label: "Not directed to subscription by broker",
      }}
      onChange={onChange}
      isClearable
      isLoading={isLoading}
      options={brokerOptions}
      components={{
        IndicatorSeparator: null,
      }}
    />
  );
};

export default OfferingBrokerSelect;
