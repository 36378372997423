import { Formik } from "formik";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";

import Spinner from "../../components/spinner";
import { useLogin } from "../../hooks/useAuthentication";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";

const Login = (props) => {
  useDocumentTitle("Investor Sign In");

  const dispatch = useDispatch();

  const formData = {
    email: "",
    password: "",
  };

  const formSchema = Yup.object().shape({
    email: Yup.string()
      .email("Please enter a valid email")
      .required("Required."),
    password: Yup.string().required("Required"),
  });

  const [spinner, showSpinner] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const { mutateAsync: triggerLogin } = useLogin({
    onSettled: () => showSpinner(false),
    onSuccess: () => {
      props.history.push("/mfa");
    },
    onError: () => {
      toast.error("Incorrect email or password, please try again");
    },
  });

  const login = async (form) => {
    showSpinner(true);

    const email = form.email.toLowerCase().trim();

    await triggerLogin({
      email,
      password: form.password,
    });

    dispatch({ type: "email", payload: email });
  };

  return (
    <div className="container-fluid">
      <div className="row vh-100 justify-content-center">
        <div className="col-12 col-md-5 col-lg-6  col-xl-4 px-lg-6 my-5 align-self-center">
          <img
            src="/img/logo.svg"
            width="150px"
            alt="logo"
            className="d-block m-auto mb-5"
          />
          <h1 className="text-center mb-3">Sign in</h1>
          <p className="text-muted text-center mb-5">Investor Portal</p>

          <Formik
            initialValues={formData}
            validationSchema={formSchema}
            onSubmit={login}
          >
            {({
              handleChange,
              handleSubmit,
              values,
              errors,
              touched,
              handleBlur,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                <div className="form-group">
                  <label className="form-label">
                    Email Address <span className="text-danger">*</span>
                  </label>
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    placeholder="name@address.com"
                    value={values.email}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {errors.email && touched.email ? (
                    <div className="text-danger mt-2 ms-1 h5">
                      {errors.email}
                    </div>
                  ) : null}
                </div>
                <div className="form-group">
                  <div className="row ">
                    <div className="col d-flex justify-content-between">
                      <label className="form-label">
                        Password <span className="text-danger">*</span>
                      </label>
                      <Link to={"/forgot"} className="text-muted">
                        Forgot password?
                      </Link>
                    </div>
                  </div>
                  <div className="input-group input-group-merge">
                    <input
                      className="form-control"
                      id="password"
                      name="password"
                      type={showPassword ? "text" : "password"}
                      placeholder="Enter your password"
                      onBlur={handleBlur}
                      value={values.password}
                      onChange={handleChange}
                    />
                    <span className="input-group-text">
                      <i
                        className={showPassword ? "fe fe-eye" : "fe fe-eye-off"}
                        onClick={() => setShowPassword((prev) => !prev)}
                      ></i>
                    </span>
                  </div>
                  {errors.password && touched.password ? (
                    <div className="text-danger mt-2 ms-1 h5">
                      {errors.password}
                    </div>
                  ) : null}
                </div>

                <Spinner show={spinner}>
                  <button
                    className="btn w-100 btn-eq-primary mb-3"
                    type="submit"
                  >
                    Sign in
                  </button>
                </Spinner>
              </form>
            )}
          </Formik>
        </div>
        <div className="col-12 col-md-7 col-lg-6 col-xl-8 d-none d-lg-block">
          <div
            className="bg-cover h-100 min-vh-100 mt-n1 me-n3"
            style={{ backgroundImage: "url(img/login.jpg)" }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default Login;
