import React, { useEffect } from "react";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";

const LinkExpired = () => {
  useDocumentTitle("Link has expired");

  useEffect(() => {
    window.onpopstate = () => {
      window.history.go(1);
    };
  });
  return (
    <>
      <div className="container">
        <div className="row align-items-center vh-100">
          <div className="col-12 col-md-7 col-lg-6 order-md-2">
            <h1 className="display-4 text-center text-md-start">
              The link you followed has expired.
            </h1>
            <p className="lead text-muted">Please contact admin.</p>
          </div>
          <div className="col-12 col-md-5 col-lg-6 order-md-2 text-center">
            <img
              src="/img/offering-closed.svg"
              className="img-fluid mb-6 mb-md-0 aos-init aos-animate"
              alt="..."
              data-aos="fade-up"
              data-aos-delay="100"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default LinkExpired;
