import React, { useState } from "react";
import NumberFormat from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import Spinner from "../../components/spinner";
import { useMFACode } from "../../hooks/useAuthentication";
import UserTokenService from "../../services/UserTokenService";

const MFA = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const email = useSelector((state) => state.email);
  const [mfaCode, setMfaCode] = useState("");
  const [spinner, showSpinner] = useState(false);

  const confirmMFA = useMFACode({
    onSettled: () => showSpinner(false),
    onSuccess: (data) => {
      dispatch({ type: "token", payload: data.token });
      dispatch({ type: "user", payload: data.user });

      UserTokenService.setToken(data.token);

      history.push("/app/vault");
    },
    onError: () => {
      toast.error("Incorrect code, please try again");
    },
  });

  const handleLogin = async () => {
    showSpinner(true);

    await confirmMFA.mutateAsync({
      email: email,
      mfa_code: mfaCode,
    });
  };

  return (
    <div className="vh-100 d-flex align-items-center bg-auth border-top border-top-2 border-primary">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-5 col-xl-6 my-5 text-center">
            <h1 className="display-4 text-center mb-3">
              Two Factor Authentication
            </h1>
            <p>
              Your account is protected with two-factor authentication. We've
              sent you an email. Please enter the code below.
            </p>

            <Spinner show={spinner}>
              <NumberFormat
                name="mfa_code"
                className="form-control form-control-lg text-center"
                format="######"
                mask="_"
                placeholder="0 0 0 0 0 0"
                value={mfaCode}
                onValueChange={({ value }) => setMfaCode(value)}
              />

              <button
                className="btn btn-lg btn-eq-primary mt-3"
                disabled={mfaCode.length !== 6}
                onClick={() => handleLogin()}
              >
                Continue
              </button>
            </Spinner>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MFA;
