export const InvestmentStatus = [
  { id: "01", name: "Invite Sent" },
  { id: "02", name: "Investor Review" },
  { id: "03", name: "Investor Approved" },
  { id: "04", name: "Investor Rejected" },
  { id: "05", name: "Agreement Signed" },
  { id: "06", name: "Payment Pending" },
  { id: "07", name: "Payment Failed" },
  { id: "08", name: "Payment Successful" },
  { id: "09", name: "Agreement Fully Signed" },
];

export const newDeals = [
  {
    id: 1,
    benefits: [
      "Lorem, ipsum dolor sit amet conse adipi elit",
      "50% ipsum dolor sit amet conse adipi",
      "An unknown dolor sit amet conse adipi",
    ],
    industry: "Technology and Support",
    heading: "New Deal 1",
    new: true,
  },
];

export const services = [
  {
    name: "EquiDeFi Prime",
    price: "$499",
    frequency: "/ year",
    benefits: [
      "Lorem, ipsum dolor sit amet conse adipi elit",
      "50% ipsum dolor sit amet conse adipi",
      "An unknown dolor sit amet conse adipi",
    ],
    new: true,
  },
  {
    name: "PrivCo Subscription",
    price: "$1,499",
    frequency: "/ year",
    benefits: [
      "Lorem, ipsum dolor sit amet conse adipi elit",
      "50% ipsum dolor sit amet conse adipi",
      "An unknown dolor sit amet conse adipi",
    ],
    new: true,
  },
  {
    name: "Investor Newsletter",
    price: "$49",
    frequency: "/ month",
    benefits: [
      "Lorem, ipsum dolor sit amet conse adipi elit",
      "50% ipsum dolor sit amet conse adipi",
      "An unknown dolor sit amet conse adipi",
    ],
    new: false,
  },
  {
    name: "Looking for a Broker",
    price: "$149",
    frequency: "/ hour",
    benefits: [
      "Lorem, ipsum dolor sit amet conse adipi elit",
      "50% ipsum dolor sit amet conse adipi",
      "An unknown dolor sit amet conse adipi",
    ],
    new: false,
  },
  {
    name: "Looking for a Lawyer",
    price: "$199",
    frequency: "/ hour",
    benefits: [
      "Lorem, ipsum dolor sit amet conse adipi elit",
      "50% ipsum dolor sit amet conse adipi",
      "An unknown dolor sit amet conse adipi",
    ],
    new: false,
  },
];

export const favoured_type = [
  { id: "1", label: "Textile", value: "textile" },
  { id: "2", label: "Telecommunication", value: "telecommunication" },
  { id: "3", label: "Media", value: "'media" },
  { id: "4", label: "Housing", value: "housing" },
  { id: "5", label: "IT", value: "IT" },
  { id: "6", label: "Hardware", value: "hardware" },
];

export const favoured_size = [
  { id: "1", label: "Large", value: "large" },
  { id: "2", label: "Medium", value: "Medium" },
  { id: "3", label: "Small", value: "small" },
];

export const questions = [
  {
    title: "A. ACCREDITED INVESTOR AND QUALIFIED CLIENT STATUS ",
    questions: [
      {
        id: "q_1",
        name: "I. Qualification as an Accredited Investor.",
        type: "checkbox",
        options: [
          {
            id: "q_1_o1",
            name: "INDIVIDUAL WITH NET WORTH IN EXCESS OF $1.0 MILLION.",
            value: "",
            description:
              "A natural person (not an entity) whose net worth, or joint net worth with his or her spouse, at the time of purchase exceeds $1,000,000. (Explanation: In calculating net worth, you may not include your equity in personal property and real estate, including your principal residence, you may however include cash, short- term investments, stock and securities.) Following the four-year period after the date of enactment of the Dodd Frank Act, and every four years thereafter, the SEC will be required to review the definition of the term “accredited investor,” as applied to natural persons, and may modify the definition as appropriate for the protection of investors, in the public interest and in light of the economy",
          },
          {
            id: "q_1_o2",
            name: "INDIVIDUAL WITH A $200,000 INDIVIDUAL ANNUAL INCOME.",
            value: "",
            description:
              "A natural person (not an entity) who had an individual income of more than $200,000 in each of the preceding two calendar years, and has a reasonable expectation of reaching the same income level in the current year.",
          },
          {
            id: "q_1_o3",
            name: "INDIVIDUAL WITH A $300,000 JOINT ANNUAL INCOME",
            value: "",
            description:
              "A natural person (not an entity) who had joint income with his or her spouse in excess of $300,000 in each of the preceding two calendar years, and has a reasonable expectation of reaching the same income level in the current year. ",
          },
        ],
        required: true,
        description:
          " Please check the categories applicable to you indicating the basis upon which you qualify as an Accredited Investor for purposes of the Securities Act and Regulation D thereunder.",
      },
      {
        id: "q_2",
        name: "II. Qualification as a Sophisticated Person",
        type: "checkbox",
        options: [
          {
            id: "q_2_o1",
            name: "KNOWLEDGE AND EXPERIENCE",
            value: "",
            description:
              " The Subscriber is a person with knowledge and experience in financial and business matters so as to be capable of evaluating the relative merits and risks of an investment in the Partnership. The Subscriber is not utilizing any other person to be its purchaser representative in connection with evaluating such merits and risks. The Subscriber offers as evidence of knowledge and experience in these matters the information requested hereinafter on this Investor Questionnaire and the representations set forth in the Subscription Agreement. If the Subscriber requires the use of the services of a Purchaser Representative, as defined in Regulation D, a separate questionnaire will be provided. ",
          },
        ],
        required: true,
        description:
          "Please check below, if applicable, indicating that you are a Sophisticated Person for purposes of the Securities Act and Regulation D thereunder. ",
      },
    ],
    description:
      "Unless otherwise determined by the General Partner in its sole discretion, the General Partner will accept subscription offers only from persons who are “Accredited Investors,” and “Sophisticated Investors” as those terms are defined in Regulation D under the Securities Act. The Partnership may allow up to 35 non-accredited investors PLEASE CHECK THE APPROPRIATE SPACE(S) IN THIS SECTION INDICATING THE BASIS ON WHICH YOU QUALIFY AS AN INVESTOR.",
  },
  {
    title: "B. ERISA PLANS",
    questions: [
      {
        id: "q_3",
        name: "I. TITLE TO UNITS TO BE REGISTERED AS FOLLOWS:",
        type: "radio",
        options: [
          {
            id: "q_3_o1",
            name: "CORPORATE PLAN",
            value: "",
          },
          {
            id: "q_3_o2",
            name: "IRA",
            value: "",
          },
          {
            id: "q_3_o3",
            name: "KEOGH (H.R.-10) PLAN",
            value: "",
          },
          {
            id: "q_3_o4",
            name: "OTHER",
            value: "",
          },
        ],
        required: true,
        description: "Type of Plan (check one)",
      },
      {
        id: "q_4",
        name: "II. Investment Discretion with respect to Invested Assets Exercised by (check one)",
        type: "radio",
        options: [
          {
            id: "q_4_o1",
            name: "INDIVIDUAL PLAN",
            value: "",
          },
          {
            id: "q_4_o2",
            name: "INDIVIDUAL FIDUCIARY",
            value: "",
          },
          {
            id: "q_4_o3",
            name: "EMPLOYER",
            value: "",
          },
          {
            id: "q_4_o4",
            name: "CORPORATE FIDUCIARY (bank, insurance company, investment broker, etc.)",
            value: "",
          },
        ],
        required: true,
        description: "",
      },
    ],
    description:
      "The Investor is is not [check one] a “benefit plan investor” (as defined in DOL Regulation Section 2510.2-101 (the “DOL Regulation”). The DOL Regulation defines a “benefit plan investor” as (i) an employee benefit plan, whether or not subject to Title I of ERISA, (ii) any plan described in Section 4975(e)(1) of the Internal Revenue Code (such as an individual retirement account or Keogh plan), or (iii) any entity whose underlying assets include plan assets by reason of a plan’s investment in the entity.",
  },
  {
    title:
      "C.REPRESENTATIONS AND WARRANTIES BY CORPORATIONS, PARTNERSHIPS, LIMITED LIABILITY COMPANIES, TRUSTS AND ESTATES",
    questions: [
      {
        id: "q_5",
        name: "1.Was the undersigned organized or reorganized for the specific purpose, or for the purpose among other purposes, of acquiring interests in the Partnership?",
        type: "radio",
        options: [
          {
            id: "q_5_o1",
            name: "Yes",
            value: "",
          },
          {
            id: "q_5_o2",
            name: "No",
            value: "",
          },
        ],
        required: false,
        description: "",
      },
      {
        id: "q_6",
        name: "2.Will the Subscriber, at any time, invest more than 40% of its assets in the Partnership?",
        type: "radio",
        options: [
          {
            id: "q_6_o1",
            name: "Yes",
            value: "",
          },
          {
            id: "q_6_o2",
            name: "No",
            value: "",
          },
        ],
        required: false,
        description: "",
      },
      {
        id: "q_7",
        name: "3.Under the Subscribing entity’s governing documents and in practice, are the Subscribing entity’s investment decisions based on the investment objectives of the Subscribing entity and its owners generally and not on the particular investment objectives of any one or more of its individual owners?",
        type: "radio",
        options: [
          {
            id: "q_7_o1",
            name: "Yes",
            value: "",
          },
          {
            id: "q_7_o2",
            name: "No",
            value: "",
          },
        ],
        required: false,
        description: "",
      },
      {
        id: "q_8",
        name: "4.Does any individual shareholder, partner or member or group of shareholders, partners or members of the undersigned have the right to elect whether or not to participate in the investment of the Subscribing entity in the Partnership or to determine the level of participation of such partner or group therein?",
        type: "radio",
        options: [
          {
            id: "q_8_o1",
            name: "Yes",
            value: "",
          },
          {
            id: "q_8_o2",
            name: "No",
            value: "",
          },
        ],
        required: false,
        description: "",
      },
    ],
    description:
      "If the Subscriber is a corporation, partnership, limited liability company or trust, the Subscriber and each person signing on behalf of Subscriber represents and warrants that:",
  },
  {
    title: "I.Payment Information.",
    questions: [
      {
        id: "q_9",
        name: "1.Name of the bank from which your payment to the Partnership is being wired (the “Wiring Bank”):",
        type: "text",
        required: true,
        description: "",
        text_response: "",
      },
      {
        id: "q_10",
        name: "2.Is the Wiring Bank located in the United States or another “FATF Country”5?",
        type: "radio",
        options: [
          {
            id: "q_10_o1",
            name: "Yes",
            value: "",
          },
          {
            id: "q_10_o2",
            name: "No",
            value: "",
          },
        ],
        required: false,
        description:
          "If yes, please answer question (3) below and If no, please provide the information described in Item II (Additional Information) below.",
      },
      {
        id: "q_11",
        name: "3.Are you a customer of the Wiring Bank?",
        type: "radio",
        options: [
          {
            id: "q_11_o1",
            name: "Yes",
            value: "",
          },
          {
            id: "q_11_o2",
            name: "No",
            value: "",
          },
        ],
        required: false,
        description:
          "If yes, you may skip Item II below, as well as forms F1 through F5 (attached hereto). or If no, please provide the information described in Item II below.",
      },
    ],
    description: "",
  },
  {
    title: "F.ADDITIONAL SUBSCRIBER INFORMATION",
    questions: [
      {
        id: "q_13",
        name: "Name of Subscriber:",
        type: "text",
        required: false,
        description: "",
        text_response: "",
      },
      {
        id: "q_14",
        name: "Date of Birth",
        type: "text",
        required: false,
        description: "",
        text_response: "",
      },
      {
        id: "q_15",
        name: "Name of Joint Subscriber, if any:",
        type: "text",
        required: false,
        description: "",
        text_response: "",
      },
      {
        id: "q_15",
        name: "Date of Birth:",
        type: "text",
        required: false,
        description: "",
        text_response: "",
      },
      {
        id: "q_16",
        name: "Amount of Initial Capital Contribution:",
        type: "text",
        required: false,
        description: "",
        text_response: "",
      },
      {
        id: "q_17",
        name: "Type of owner or form of ownership",
        type: "checkbox",
        options: [
          {
            id: "q_17_o1",
            name: "Individual that is a United States person6 (or a trust of such person)",
            value: "",
            description: "",
          },
          {
            id: "q_17_o2",
            name: "Joint Tenants With Right of Survivorship",
            value: "",
            description: "",
          },
          {
            id: "q_17_o3",
            name: "IRA",
            value: "",
            description: "",
          },
          {
            id: "q_17_o4",
            name: "Partnership",
            value: "",
            description: "",
          },
          {
            id: "q_17_o5",
            name: "Tenants in Common",
            value: "",
            description: "",
          },
          {
            id: "q_17_o6",
            name: "Keogh Plan",
            value: "",
            description: "",
          },
          {
            id: "q_17_o7",
            name: "Corporation",
            value: "",
            description: "",
          },
          {
            id: "q_17_o8",
            name: "Employee Benefit Plan",
            value: "",
            description: "",
          },
          {
            id: "q_17_o9",
            name: "Other",
            value: "",
            description: "",
          },
          {
            id: "q_17_10",
            name: "Trust",
            value: "",
            description: "",
          },
          {
            id: "q_17_11",
            name: "Limited Liability Company",
            value: "",
            description: "",
          },
          {
            id: "q_17_12",
            name: "Individual that is not a United States person (or a trust of such person)",
            value: "",
            description: "",
          },
        ],
        required: true,
        description: "",
      },
      {
        id: "q_18",
        name: "Address (Principal State of Residence):",
        type: "text_area",
        required: false,
        description: "",
        text_response: "",
      },
      {
        id: "q_19",
        name: "Name of judiciary",
        type: "text_area",
        required: false,
        description: "",
        text_response: "",
      },
      {
        id: "q_20",
        data: [
          {
            id: "q_20_row_1",
          },
          {
            id: "q_20_row_2",
          },
        ],
        name: "Business or professional education and the degree(s) received are as follows (Applicable to individual subscribers):",
        type: "table",
        required: false,
        columnNames: ["School", "Degree", "Year Received"],
        description: "",
      },
    ],
    description:
      "The Subscriber further represents and warrants that the following information is true and complete:",
  },
];

export const disableContinueStatuses = [
  "Payment Pending",
  "Payment Successful",
  "Accepted",
  "Completed",
  "Closed",
];
