import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { Formik } from "formik";
import NumberFormat from "react-number-format";
import * as Yup from "yup";

import axios from "axios";
import Spinner from "../../components/spinner";

const profileSchema = Yup.object().shape({
  first_name: Yup.string().required("Please enter your first name"),
  middle_name: Yup.string().nullable(),
  last_name: Yup.string().required("Please enter your last name"),
  company: Yup.string().nullable(),
  title: Yup.string().nullable(),
  email: Yup.string().email("Invalid email").required("Required"),
  phone: Yup.string()
    .required("Please enter your phone number")
    .matches(
      /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
      "Invalid phone number"
    ),
  password: Yup.string()
    .required("Please enter a password")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "Must contain 8 characters, one uppercase, one lowercase, one number and one special character"
    ),
  confirmPassword: Yup.string()
    .required("Please confirm your password")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});

const CompleteProfile = (props) => {
  const dispatch = useDispatch();

  const user = useSelector((store) => store.user);
  const [spinner, setSpinner] = useState(false);

  useEffect(() => {
    axios.get("me").then((response) => {
      dispatch({ type: "user", payload: response.data });
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (form) => {
    setSpinner(true);

    const request = {
      first_name: form.first_name,
      middle_name: form.middle_name,
      last_name: form.last_name,
      email: form.email.toLowerCase().trim(),
      phone: form.phone,
      company: form.company,
      title: form.title,
      active: true,
    };

    Object.keys(request).forEach((value) => {
      if (typeof request[value] === "string" && request[value].trim() === "") {
        request[value] = null;
      }
    });

    axios.post("me", request).then((response) => {
      axios
        .post("me/password", {
          password: form.password,
        })
        .then((response) => {
          toast.success(`Thank you for completing your profile!`);
          setSpinner(false);
          axios.get("me").then((response) => {
            dispatch({ type: "user", payload: response.data });
            props.history.push("/app/vault");
          });
        });
    });
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 col-md-5 col-lg-6  col-xl-4 px-lg-6 my-5 align-self-center">
          <h1>Complete your profile</h1>
          <hr />

          <Formik
            initialValues={user}
            validationSchema={profileSchema}
            enableReinitialize
            onSubmit={handleSubmit}
          >
            {({
              handleChange,
              handleSubmit,
              values,
              errors,
              touched,
              handleBlur,
              setFieldValue,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label className="mb-2">
                        First Name<span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        name="name"
                        placeholder="John"
                        value={values.first_name}
                        onChange={handleChange}
                      />
                      {errors.first_name && touched.first_name && (
                        <div className="text-danger mt-2 ms-1 h5">
                          {errors.first_name}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-group">
                      <label className="mb-2">Middle Name</label>
                      <input
                        className="form-control"
                        type="text"
                        name="name"
                        placeholder=""
                        value={values.middle_name}
                        onChange={handleChange}
                      />
                      {errors.middle_name && touched.middle_name && (
                        <div className="text-danger mt-2 ms-1 h5">
                          {errors.middle_name}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label className="mb-2">
                    Last Name<span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    name="name"
                    placeholder="John"
                    value={values.last_name}
                    onChange={handleChange}
                  />
                  {errors.last_name && touched.last_name && (
                    <div className="text-danger mt-2 ms-1 h5">
                      {errors.last_name}
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <label className="mb-2">Title</label>
                  <input
                    className="form-control"
                    type="text"
                    name="title"
                    placeholder="e.g. Investor"
                    value={values.title}
                    onChange={handleChange}
                  />
                  {errors.title && touched.title && (
                    <div className="text-danger mt-2 ms-1 h5">
                      {errors.title}
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <label className="mb-2">Company</label>
                  <input
                    className="form-control"
                    type="text"
                    name="company"
                    placeholder="e.g. Equidefi"
                    value={values.company}
                    onChange={handleChange}
                  />
                  {errors.company && touched.company && (
                    <div className="text-danger mt-2 ms-1 h5">
                      {errors.company}
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <label className="mb-2">
                    Email Address <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    name="email"
                    placeholder="e.g. address@example.com"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.email && touched.email && (
                    <div className="text-danger mt-2 ms-1 h5">
                      {errors.email}
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <label className="mb-2">
                    Mobile Phone <span className="text-danger">*</span>
                  </label>
                  <NumberFormat
                    placeholder="eg: +1 (444) 444-4444"
                    className="form-control"
                    format="+1 (###) ###-####"
                    mask="_"
                    value={values.phone}
                    onValueChange={({ value }) => {
                      setFieldValue("phone", value);
                    }}
                  />
                  {errors.phone && touched.phone && (
                    <div className="text-danger mt-2 ms-1 h5">
                      {errors.phone}
                    </div>
                  )}
                </div>

                <div className="form-group">
                  <label className="mb-2">
                    Password <span className="text-danger">*</span>
                  </label>
                  <div className="input-group input-group-merge">
                    <input
                      type="password"
                      name="password"
                      placeholder="Password"
                      className="form-control"
                      value={values.password || ""}
                      onChange={handleChange}
                    />
                  </div>
                  {errors.password && (
                    <div className="text-danger mt-2 ms-1 h5">
                      {errors.password}
                    </div>
                  )}
                </div>

                <div className="form-group">
                  <label className="mb-2">
                    Confirm Password <span className="text-danger">*</span>
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    name="confirmPassword"
                    placeholder="Confirm password"
                    value={values.confirmPassword || ""}
                    onChange={handleChange}
                  />
                  {errors.confirmPassword && (
                    <div className="text-danger mt-2 ms-1 h5">
                      {errors.confirmPassword}
                    </div>
                  )}
                </div>

                <Spinner show={spinner}>
                  <button
                    type="submit"
                    form="profile-form"
                    className="btn btn-eq-primary w-100"
                    onClick={handleSubmit}
                  >
                    Continue
                  </button>
                </Spinner>
              </form>
            )}
          </Formik>
        </div>
        <div className="col-12 col-md-7 col-lg-6 col-xl-8 d-none d-lg-block">
          <div
            className="bg-cover h-100 min-vh-100 mt-n1 me-n3"
            style={{ backgroundImage: "url(img/login.jpg)" }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default CompleteProfile;
