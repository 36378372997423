import { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import axios from "axios";
import { Formik } from "formik";
import * as Yup from "yup";

import Spinner from "../../components/spinner";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";

const ResetPassword = (props) => {
  useDocumentTitle("Reset Password");

  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${props.match.params.token}`;

  const initialValues = {
    password: "",
    confirmPassword: "",
  };

  const forgotPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .required("Password is required")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Must contain 8 characters, one uppercase, one lowercase, one number and one special character"
      ),
    confirmPassword: Yup.string()
      .required("Please enter the password again")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
  });

  const [spinner, showSpinner] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = async ({ password }) => {
    showSpinner(true);

    axios
      .post(`user/${props.match.params.id}/password`, {
        password: password,
      })
      .then((response) => {
        toast.info(`Your password has been updated, please login to continue`);
        props.history.push("/");
      })
      .catch((error) => {
        toast.info(`An error occured while trying to process your request.`);
        showSpinner(false);
      });
  };

  return (
    <div className="container-fluid">
      <div className="row vh-100 justify-content-center">
        <div className="col-12 col-md-5 col-lg-6 col-xl-4 px-lg-6 my-5 align-self-center">
          <img
            src="/img/logo.svg"
            width="150px"
            alt="logo"
            className="d-block m-auto mb-5"
          />
          <h1 className="text-center mb-3">Reset Password</h1>
          <p className="text-muted text-center mb-5">
            Enter a secure password for your account
          </p>
          <Formik
            initialValues={initialValues}
            validationSchema={forgotPasswordSchema}
            onSubmit={onSubmit}
          >
            {({
              handleChange,
              handleSubmit,
              values,
              errors,
              touched,
              handleBlur,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                <div className="form-group">
                  <div className="input-group input-group-merge">
                    <input
                      type={showPassword ? "text" : "password"}
                      name="password"
                      placeholder="Enter Password"
                      className="form-control"
                      value={values.password}
                      onChange={handleChange}
                    />
                    <span className="input-group-text" onClick={togglePassword}>
                      <i
                        className={showPassword ? "fe fe-eye" : "fe fe-eye-off"}
                      ></i>
                    </span>
                  </div>
                  {errors.password && (
                    <div className="text-danger mt-2 ms-1 h5">
                      {errors.password}
                    </div>
                  )}
                </div>

                <div className="form-group">
                  <input
                    type="password"
                    className="form-control"
                    name="confirmPassword"
                    placeholder="Confirm password"
                    value={values.confirmPassword}
                    onChange={handleChange}
                  />
                  {errors.confirmPassword && (
                    <div className="text-danger mt-2 ms-1 h5">
                      {errors.confirmPassword}
                    </div>
                  )}
                </div>
                <Spinner show={spinner}>
                  <button type="submit" className="btn w-100 btn-eq-primary">
                    Reset Password
                  </button>
                </Spinner>
                <div className="text-center text-muted small mt-3">
                  Remember your password? <Link to="/">Sign In</Link>
                </div>
              </form>
            )}
          </Formik>
        </div>
        <div className="col-12 col-md-7 col-lg-6 col-xl-8 d-none d-lg-block">
          <div
            className="bg-cover h-100 min-vh-100 mt-n1 me-n3"
            style={{ backgroundImage: "url(/img/login.jpg)" }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
