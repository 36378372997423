import axios from "axios";

import { CONFIG } from "../constants/config";
import UserTokenService from "../services/UserTokenService";

const APIClient = () => {
  const t = UserTokenService.token();

  const headers = {};
  if (t) {
    headers["Authorization"] = `Bearer ${t}`;
  }

  return axios.create({
    baseURL: CONFIG.API_URL,
    headers,
  });
};

export default APIClient;
