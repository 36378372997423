import React, { useState } from "react";
import { Modal } from "react-bootstrap";

const OpenOfferingNotAvailable = ({ offering, onAccept = () => {} }) => {
  const [disclosureModal, showDisclosure] = useState(() => true);

  return (
    <Modal
      show={disclosureModal}
      scrollable
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="xl"
    >
      <Modal.Body>
        <h2>Thanks for visiting our page !</h2>
        <h3>
          This Offering is currently not available and should be available to
          you shortly. You'll be notified by the issuer.
        </h3>

        <br />

        <button className="btn btn-eq-primary shadow lift" onClick={onAccept}>
          Close
        </button>
      </Modal.Body>
    </Modal>
  );
};

export default OpenOfferingNotAvailable;
