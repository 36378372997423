import { useQuery, useMutation } from "@tanstack/react-query";
import InquiryClient from "../../clients/InquiryClient";

/**
 * @param {string} inquiryId
 * @param {import("@tanstack/react-query").QueryObserverOptions} options
 */
export const useInquiry = (inquiryId, options = {}) => {
  return useQuery(["inquiry", inquiryId], () => InquiryClient.get(inquiryId), {
    enabled: !!inquiryId,
    ...options,
  });
};
