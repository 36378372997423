import APIClient from "./api";

class GoogleClient {
  static async autocomplete(query) {
    const response = await APIClient().get(`/v2/google/autocomplete`, {
      params: { search: query },
    });
    return response.data;
  }

  static async place(id) {
    const response = await APIClient().get(`/v2/google/place/${id}`);
    return response.data;
  }
}

export default GoogleClient;
