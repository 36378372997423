import {
  ACCREDITATION_PENDING,
  AGREEMENT_PENDING,
  AGREEMENT_SIGNED,
  COMPLETE_PROFILE,
  DRAFT,
  ENTER_PASSWORD,
  INVESTOR_APPROVED,
  INVESTOR_NAME_MISMATCH,
  INVESTOR_PENDING,
  INVESTOR_REJECTED,
  INVESTOR_REVIEW,
  INVITE_SENT,
  PAYMENT_FAILED,
  PAYMENT_PENDING,
  PAYMENT_PROCESSING,
  PAYMENT_SUCCESSFUL,
  QUESTIONNAIRE_APPROVED,
  QUESTIONNAIRE_PENDING,
  QUESTIONNAIRE_REJECTED,
} from "@equidefi/shared/constants/investments";

export const investmentContinueUrlOpenOffering = (
  investmentId,
  status,
  offeringId
) => {
  if (!investmentId && !offeringId) return null;

  // If no investmentId but offeringId is set, navigate to start screen
  if (!investmentId && offeringId) return `/offering/${offeringId}/start`;
  switch (status) {
    case INVITE_SENT:
      return `/offering/${offeringId}/info/${investmentId}`;
    case DRAFT:
    case QUESTIONNAIRE_PENDING:
    case QUESTIONNAIRE_REJECTED:
      return `/offering/${offeringId}/questionnaire/${investmentId}`;
    case QUESTIONNAIRE_APPROVED:
      return `/offering/${offeringId}/verify/start/${investmentId}`;
    case INVESTOR_REVIEW:
    case INVESTOR_PENDING:
    case INVESTOR_REJECTED:
    case INVESTOR_NAME_MISMATCH:
      return `/offering/${offeringId}/verify/result/${investmentId}`;
    case INVESTOR_APPROVED:
      return `/offering/${offeringId}/subscription/${investmentId}`;
    case AGREEMENT_PENDING:
      return `/offering/${offeringId}/agreements/${investmentId}`;
    case AGREEMENT_SIGNED:
    case PAYMENT_FAILED:
      return `/offering/${offeringId}/payment/${investmentId}`;
    case PAYMENT_SUCCESSFUL:
    case PAYMENT_PENDING:
    case PAYMENT_PROCESSING:
    case ENTER_PASSWORD:
      return `/offering/${offeringId}/password/${investmentId}`;
    case ACCREDITATION_PENDING:
      return `/offering/${offeringId}/accreditation/${investmentId}`;
    case COMPLETE_PROFILE:
      return `/offering/${offeringId}/complete/${investmentId}`;
    default:
      return null;
  }
};

/**
 *
 * @param {string} status
 * @param {string} redirectUrl
 * @returns {{ url: string, warning?: string }}
 */
export const investmentContinueStepRegD = (status, redirectUrl) => {
  switch (status) {
    case "Investor Review":
      return { warning: "Investor in review.", url: `/investor/review` };
    case "Investor Approved":
      return { url: `/offering` };
    case "Agreement Pending":
      return { url: `/agreement` };
    case "Agreement Signed":
    case "Payment Pending":
      return { url: `/payment` };
    case "Payment Successful":
    case "Accepted":
    case "Completed":
      return {
        warning: "Successful, completion of the payment.",
        url: `/app/vault`,
      };
    default:
      return { url: `/${redirectUrl}` };
  }
};

export const investmentAuthRegDOffering = (investmentId, status, token) => {
  if (!status) return null;

  switch (status) {
    case "Invite Sent":
      return `/auth/welcome/${investmentId}/${token}`;
    case "Investor Approved":
      return `/auth/offering/${investmentId}/${token}`;
    case "Agreement Pending":
      return `/auth/agreement/${investmentId}/${token}`;
    case "Agreement Signed":
    case "Payment Pending":
    case "Payment Failed":
      return `/auth/payment/${investmentId}/${token}`;
    default:
      return null;
  }

  return null;
};
