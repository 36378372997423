import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useEffect, useState } from "react";
import { CONFIG } from "../constants/config";

const baseUrl = new URL(CONFIG.URL);

const PaymentForm = ({ returnPath = "/payment/pending" }) => {
  const returnUrl = new URL(returnPath, baseUrl);

  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoaded, setLoad] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoad(true);
    }, 1500);
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) return;

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: { return_url: returnUrl.toString() },
    });

    if (error) setErrorMessage(error.message);
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />

      {isLoaded && (
        <button disabled={!stripe} className="btn btn-sm btn-eq-primary">
          Continue <i className="fe fe-arrow-right ms-1"></i>
        </button>
      )}

      {errorMessage && <div>{errorMessage}</div>}
    </form>
  );
};

export default PaymentForm;
