import { Formik } from "formik";
import React from "react";
import { Collapse, Form } from "react-bootstrap";
import * as Icon from "react-feather";
import NumberFormat from "react-number-format";
import { useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";

import { toast } from "react-toastify";
import { YesNoToggle } from "../../../components/offerings/YesNoToggle";
import {
  useInvestment,
  useInvestmentUpdateStage,
} from "../../../hooks/useInvestments";

const toggledCheckSchema = (msg) =>
  Yup.boolean().test(
    "must-be-checked",
    msg,
    (value) => value === true || value === false
  );

const AccreditationSchema = Yup.object().shape({
  accredited: toggledCheckSchema(
    "You must indicate if you are an accredited investor"
  ),
  bad_actor: toggledCheckSchema("You must indicate if you are a bad actor"),
  annual_income: Yup.string().when("accredited", (data) => {
    if (data === false) {
      return Yup.number()
        .round()
        .test("too-large-integer", "Annual income is too large", (value) =>
          // eslint-disable-next-line no-undef
          !!value ? BigInt(value) < Number.MAX_SAFE_INTEGER : true
        )
        .required(
          "You must submit your annual income if you are not accredited"
        );
    }
  }),
});

const QuestionareSelfReported = ({ offering }) => {
  const { offeringId, investmentId } = useParams();
  const history = useHistory();

  const investmentQuery = useInvestment(investmentId);
  const updateInvestment = useInvestmentUpdateStage(
    investmentId,
    "accreditation"
  );

  const onSubmit = async (data) => {
    try {
      await updateInvestment.mutateAsync({ data });

      const { data: updated } = await investmentQuery.refetch();
      if (updated.status !== "Accreditation Rejected") {
        history.push(`/offering/${offeringId}/verify/start/${investmentId}`);
      }
    } catch (error) {
      toast.error("Sorry, something went wrong");
    }
  };

  return (
    <section className="py-2">
      <div className="container">
        <h2 className="text-md-start mt-4 mb-6">Accreditation</h2>
        <Formik
          validationSchema={AccreditationSchema}
          initialValues={{
            accredited: false,
            bad_actor: false,
            annual_income: undefined,
          }}
          enableReinitialize
          onSubmit={onSubmit}
          validateOnChange
          validateOnBlur={false}
        >
          {({
            handleChange,
            handleSubmit,
            values,
            errors,
            touched,
            setFieldValue,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Form.Group
                className="mb-3 d-flex flex-row flex-nowrap justify-content-between w-100"
                controlId="exampleForm.ControlInput1"
              >
                <div>
                  <Form.Label className="fs-3 mb-0">
                    Are you an accredited as an investor?
                  </Form.Label>
                  {errors.accredited && touched.accredited && (
                    <div className="text-danger mt-0 h5">
                      {errors.accredited}
                    </div>
                  )}
                </div>
                <YesNoToggle
                  name="accredited"
                  value={values.accredited}
                  onChange={handleChange}
                  size={40}
                />
              </Form.Group>

              <Collapse in={values.accredited === false}>
                <div id="accredited-value-input">
                  <h3>Annual Income</h3>

                  <NumberFormat
                    allowNegative={false}
                    allowLeadingZeros
                    thousandSeparator
                    fixedDecimalScale={0}
                    decimalScale={0}
                    prefix="$"
                    name="annual_income"
                    className="form-control"
                    aria-label="Amount (to the nearest dollar)"
                    value={values.annual_income}
                    onValueChange={(...args) => {
                      const [{ value }] = args;
                      handleChange({
                        target: { name: "annual_income", value: value },
                      });
                    }}
                  />
                  <Form.Text className="text-muted">
                    If you are not accredited, you must provide your annual
                    income.
                  </Form.Text>

                  {errors.annual_income && touched.annual_income && (
                    <div className="text-danger mt-2 ms-1 h5">
                      {errors.annual_income}
                    </div>
                  )}
                </div>
              </Collapse>

              <Form.Group
                className="mb-3 d-flex flex-row flex-nowrap justify-content-between w-100"
                controlId="exampleForm.ControlInput2"
              >
                <div>
                  <div>
                    <div className="d-flex align-content-start w-100 justify-content-between">
                      <div>
                        <Form.Label className="fs-3 w-100 d-block mb-0">
                          Are you a bad actor?
                        </Form.Label>
                        {errors.bad_actor && touched.bad_actor && (
                          <div className="text-danger mt-0 h5">
                            {errors.bad_actor}. Read the details below to answer
                          </div>
                        )}
                      </div>

                      <YesNoToggle
                        name="bad_actor"
                        value={values.bad_actor}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div id="bad-actor-details">
                    <p>Under the final rule, disqualifying events include:</p>
                    <ul>
                      <li>Certain criminal convictions</li>
                      <li>Certain court injunctions and restraining orders</li>
                      <li>
                        Certain final orders of certain state and federal
                        regulators
                      </li>
                      <li>Certain SEC disciplinary orders</li>
                      <li>Certain SEC cease-and-desist orders</li>
                      <li>
                        Suspension or expulsion from membership in a
                        self-regulatory organization (SRO), such as FINRA, or
                        from association with an SRO member
                      </li>
                      <li>
                        SEC stop orders and orders suspending the Regulation A
                        exemption
                      </li>
                      <li>U.S. Postal Service false representation orders</li>
                    </ul>
                  </div>
                </div>
              </Form.Group>

              <button
                type="submit"
                className="mt-3 btn btn-sm btn-eq-primary d-inline-flex align-items-center gap-2"
              >
                <span>Continue</span>
                <Icon.ArrowRight size={18} color="white" aria-hidden="true" />
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </section>
  );
};

export default QuestionareSelfReported;
