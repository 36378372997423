import React from "react";
import { Route } from "react-router-dom";
import { useRequireUser } from "../hooks/useRequireUser";

export const LayoutRoute = ({
  component: Component,
  layout: Layout,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => (
      <Layout>
        <Component {...props} />
      </Layout>
    )}
  ></Route>
);

export const PrivateLayoutRoute = ({
  component: Component,
  layout: Layout,
  ...rest
}) => {
  useRequireUser();

  return <LayoutRoute component={Component} layout={Layout} {...rest} />;
};

export const PrivateRoute = ({ ...rest }) => {
  useRequireUser();

  return <Route {...rest} />;
};
