import clsx from "clsx";
import { useMemo } from "react";

const AgreementRow = ({
  agreement,
  investment,
  isDisabled = false,
  onDownload = () => {},
  onSign = () => {},
}) => {
  const isAgreementSigned = useMemo(() => {
    const iAgreement = investment?.agreements?.find(
      (x) => x.template_id === agreement.template_id
    );

    return iAgreement?.investor_status === "complete";
  }, [investment, agreement]);

  return (
    <div className="list-group-item">
      <div className="row">
        <div className="col-12 col-lg-8 text-center document-name">
          <i
            className={clsx("me-3", {
              "fe fe-check-circle": isAgreementSigned,
              "fe fe-alert-triangle": !isAgreementSigned,
            })}
          />
          {agreement.name}
        </div>
        <div className="col-12 col-lg-4 mx-auto text-center m-0 px-1">
          <button
            target="_blank"
            rel="noreferrer"
            className="btn btn-xs btn-info-soft me-1 button-space"
            disabled={isDisabled}
            onClick={() => onDownload(agreement.template_id)}
          >
            Download <i className="fe fe-download ms-1" />
          </button>
          {!isAgreementSigned ? (
            <button
              target="_blank"
              rel="noreferrer"
              className="btn btn-xs btn-info-soft ms-sm-1 button-space"
              disabled={isDisabled}
              onClick={() => onSign(agreement.template_id)}
            >
              Continue to Sign <i className="fe fe-arrow-right-circle ms-1" />
            </button>
          ) : (
            <button
              target="_blank"
              rel="noreferrer"
              style={{ pointerEvents: "none" }}
              className="btn btn-xs btn-success-soft ms-sm-1 button-space"
            >
              Signed!
              <i className="fe fe-check-circle ms-1" />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default AgreementRow;
