import Spinner from "../../../../components/spinner";

const OfferingAgreementsList = ({
  isLoading: spinner = false,
  offering,
  investment,
  onPrintNda = () => {},
  onDownloadQuestionnaire = () => {},
  onDownloadAgreement = () => {},
  onDownloadPaymentConfirmation = () => {},
}) => {
  return (
    <div className="card">
      <div className="card-header">
        <h4 className="card-header-title">Agreements</h4>
      </div>
      <div className="card-body p-0 px-4">
        <Spinner show={spinner}>
          <div className="list-group list-group-flush">
            {offering?.agreements?.map((agreement, index) => {
              const myAgreement =
                investment?.agreements?.filter(
                  (x) => x.template_id === agreement.template_id
                ) || [];
              const isAgreementSigned =
                myAgreement.length > 0 &&
                myAgreement[0].investor_status === "complete";
              const iconClass = isAgreementSigned
                ? "check-circle"
                : "alert-triangle";

              return (
                <div className="list-group-item" key={index}>
                  <div className="row g-2">
                    <div className="col-auto">
                      <i className={`fe fe-${iconClass}`} />
                    </div>
                    <div className="col">{agreement.name}</div>
                    <div className="col-auto">
                      {isAgreementSigned && (
                        <button
                          className="btn btn-sm btn-eq-primary"
                          onClick={() => onDownloadAgreement(myAgreement[0])}
                        >
                          Download
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}

            <div className="list-group-item">
              <div className="row g-2">
                <div className="col-auto">
                  <i
                    className={`fe fe-${
                      investment?.accept_tc === true
                        ? "check-circle"
                        : "alert-triangle"
                    }`}
                  />
                </div>
                <div className="col">Non Disclosure Agreement</div>
                <div className="col-auto">
                  {investment?.accept_tc === true && (
                    <button
                      className="btn btn-sm btn-eq-primary"
                      onClick={onPrintNda}
                    >
                      Download
                    </button>
                  )}
                </div>
              </div>
            </div>

            <div className="list-group-item">
              <div className="row g-2">
                <div className="col-auto">
                  <i
                    className={`fe fe-${
                      investment?.question_response
                        ? "check-circle"
                        : "alert-triangle"
                    }`}
                  />
                </div>
                <div className="col">Investor Questionnaire</div>
                <div className="col-auto">
                  {investment?.question_response && (
                    <button
                      className="btn btn-sm btn-eq-primary"
                      onClick={onDownloadQuestionnaire}
                    >
                      Download
                    </button>
                  )}
                </div>
              </div>
            </div>

            <div className="list-group-item">
              <div className="row g-2">
                <div className="col-auto">
                  <i
                    className={`fe fe-${
                      investment?.payments &&
                      investment?.status !== "Payment Successful" &&
                      investment?.status !== "Payment Pending"
                        ? "check-circle"
                        : "alert-triangle"
                    }`}
                  />
                </div>
                <div className="col">Payment Confirmation</div>
                <div className="col-auto">
                  {investment?.payments &&
                    investment?.status !== "Payment Successful" &&
                    investment?.status !== "Payment Pending" && (
                      <button
                        className="btn btn-sm btn-eq-primary"
                        onClick={onDownloadPaymentConfirmation}
                      >
                        Download
                      </button>
                    )}
                </div>
              </div>
            </div>
          </div>
        </Spinner>
      </div>
    </div>
  );
};

export default OfferingAgreementsList;
