const REGULATION_A = "Regulation A";
const REGULATION_A_PLUS = "Regulation A+";
const REGULATION_D = "Regulation D";
const REGULATION_S = "Regulation S";
const REGULATION_CF = "Regulation CF";
const SECTION_4A5 = "Section 4(a)(5)";
const SELF_UNDERWRITTEN = "Self-Underwritten";
const RULE_504B = "Rule 504(b)";
const RULE_506B = "Rule 506(b)";
const RULE_506C = "Rule 506(c)";
const TIER_1 = "Tier 1";
const TIER_2 = "Tier 2";

export const TIERS = {
  TIER_1: TIER_1,
  TIER_2: TIER_2,
};

export const RULES = {
  RULE_504B: RULE_504B,
  RULE_506B: RULE_506B,
  RULE_506C: RULE_506C,
};

export const REGULATIONS = {
  REGULATION_A: REGULATION_A,
  REGULATION_A_PLUS: REGULATION_A_PLUS,
  REGULATION_D: REGULATION_D,
  REGULATION_S: REGULATION_S,
  REGULATION_CF: REGULATION_CF,
};

export const CROWDFUNDED_REGS = [
  REGULATION_A,
  REGULATION_A_PLUS,
  SELF_UNDERWRITTEN,
];
