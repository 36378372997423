import { Formik } from "formik";
import { Form } from "react-bootstrap";
import * as Icon from "react-feather";
import * as Yup from "yup";

const SigningPartyValidation = Yup.object().shape({
  signing_party_title: Yup.string()
    .required("This is a required field.")
    .max(50, "Signing Party Title is too long.")
    .nullable(),
});

const SigningPartyForm = ({ investment, isLoading, onSubmit }) => {
  return (
    <Formik
      initialValues={{
        signing_party_title: investment?.signing_party_title,
      }}
      validationSchema={SigningPartyValidation}
      onSubmit={onSubmit}
      enableReinitialize
      validateOnChange
      validateOnBlur
    >
      {({ handleSubmit, handleChange, values, errors, touched }) => (
        <form onSubmit={handleSubmit}>
          <div className="border bg-white pt-4 pb-5 px-5 rounded-2">
            <div className="d-flex flex-row flex-nowrap gap-4 w-100 mb-2">
              <Form.Group className="flex-grow-1">
                <Form.Label>Signing Party Title</Form.Label>
                <Form.Control
                  name="signing_party_title"
                  value={values.signing_party_title}
                  onChange={handleChange}
                  placeholder="e.g. CEO"
                  type="text"
                />
                {errors.signing_party_title && touched.signing_party_title && (
                  <div className="text-danger mt-2 ms-1 h5">
                    {errors.signing_party_title}
                  </div>
                )}
              </Form.Group>
            </div>
            <button
              disabled={isLoading}
              className="btn btn-sm btn-eq-primary d-inline-flex align-content-center align-items-center gap-2"
            >
              <span>Save</span>
              <Icon.ArrowRightCircle size={20} color="white" />
            </button>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default SigningPartyForm;
