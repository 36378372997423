import axios from "axios";
import FileSaver from "file-saver";
import { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";

import InfoCard from "../../../../components/infoCard";
import NavHeader from "../../../../layouts/appHeader";

import { useIssuer } from "../../../../hooks/queries/issuers";
import { useOffering } from "../../../../hooks/queries/offerings";
import { useInvestment } from "../../../../hooks/useInvestments";

import Continue from "../../../../components/investment/continue";
import { disableContinueStatuses } from "../../../../constants";
import { mergeName } from "../../../../helpers/string";
import InvestmentNdaCopy from "./InvestmentNdaCopy";
import OfferingAgreementsList from "./OfferingAgreementsList";
import OfferingInfoCard from "./OfferingInfoCard";
import OfferingInvestorPackageList from "./OfferingInvestorPackageList";
import PaymentsCard from "./PaymentsCard";
import RecentActivityCard from "./RecentActivityCard";

const InvestmentDisplayItem = ({ label, value }) => {
  return (
    <div className="list-group-item" key={1}>
      <div className="row align-items-center">
        <div className="col col-12">{label}</div>
        <div className="col col-12 text-right col-sm-auto text-muted">
          {value ?? "N/A"}
        </div>
      </div>
    </div>
  );
};

const InvestmentOverview = (props) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const ndaRef = useRef(null);

  const { data: investment } = useInvestment(id);
  const { data: offering } = useOffering(investment?.offering_id);
  const { data: issuer } = useIssuer(investment?.issuer_id);

  const handlePrintNda = useReactToPrint({
    content: () => ndaRef.current,
    documentTitle: `${investment?.investor.name} - NDA.pdf`,
  });

  const [spinner, showSpinner] = useState(false);
  const [modalInvestorReview, showInvestorReviewModal] = useState(false);

  useEffect(() => {
    dispatch({ type: "offering", payload: offering });
  }, [dispatch, investment, offering]);

  const downloadAgreement = (agreement) => {
    showSpinner(true);
    axios
      .get(`investment/${investment?.id}/agreement/${agreement.envelope_id}`)
      .then((response) => {
        let toBytes = new Uint8Array(response.data.data),
          toBinary = ``;
        toBytes.forEach((res) => (toBinary += String.fromCharCode(res)));

        const file = window.btoa(toBinary);
        FileSaver.saveAs(
          `data:application/pdf;base64,${file}`,
          `${agreement.name.replace(/[^a-zA-Z ]/g, "")}}`
        );

        showSpinner(false);
      });
  };

  const downloadQuestionnaire = () => {
    showSpinner(true);
    axios.get(`investment/${id}/questionnaire`).then((response) => {
      let toBytes = new Uint8Array(response.data.data),
        toBinary = ``;
      toBytes.forEach((res) => (toBinary += String.fromCharCode(res)));
      const file = window.btoa(toBinary);
      FileSaver.saveAs(
        `data:application/pdf;base64,${file}`,
        "investor_questionnaire.pdf"
      );
      showSpinner(false);
    });
  };

  const downloadPaymentConfirmation = () => {
    showSpinner(true);
    axios.get(`investment/${id}/payment-reciept`).then((response) => {
      let toBytes = new Uint8Array(response.data.data),
        toBinary = ``;
      toBytes.forEach((res) => (toBinary += String.fromCharCode(res)));
      const file = window.btoa(toBinary);
      FileSaver.saveAs(
        `data:application/pdf;base64,${file}`,
        "investor_payment_receipt.pdf"
      );
      showSpinner(false);
    });
  };

  const reverse = (item) => {
    return [...item].reverse();
  };

  return (
    <>
      <NavHeader
        name={investment?.offering?.name}
        title={investment?.issuer?.name}
        status={investment?.status}
      >
        {!disableContinueStatuses.includes(investment?.status) && (
          <Continue investment={investment} offering={offering} />
        )}
      </NavHeader>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-8">
            <div className="row">
              <div className="col">
                <InfoCard title="Investment Amount">
                  {investment?.amount}
                </InfoCard>
              </div>
              <div className="col">
                <InfoCard title="Price per Share">{investment?.price}</InfoCard>
              </div>
              <div className="col">
                <InfoCard title="Number of Shares">
                  {investment?.quantity}
                </InfoCard>
              </div>
            </div>

            <div className="card">
              <div className="card-body p-0 px-4">
                <div className="list-group list-group-flush">
                  <InvestmentDisplayItem
                    label="Broker Name"
                    value={mergeName(investment?.broker)}
                  />
                  <InvestmentDisplayItem
                    label="Company Business Description"
                    value={offering?.description}
                  />
                  <InvestmentDisplayItem
                    label="Issuer CIK"
                    value={issuer?.cik}
                  />
                  <InvestmentDisplayItem
                    label="Issuer EIN"
                    value={
                      issuer?.ein
                        ? `${issuer?.ein.substring(
                            0,
                            2
                          )}-${issuer?.ein.substring(3)}`
                        : "N/A"
                    }
                  />
                  <InvestmentDisplayItem
                    label="Issuer SIC"
                    value={issuer?.sic}
                  />
                  <InvestmentDisplayItem
                    label="Doing Business As"
                    value={issuer?.doing_business_as}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              {issuer?.address && (
                <div className="col">
                  <InfoCard title="Mailing Address">
                    {issuer?.address.line_1}
                    <br />
                    {issuer?.address.line_2 ? (
                      <>
                        {issuer?.address.line_2}
                        <br />
                      </>
                    ) : null}
                    {issuer?.address.city}, {issuer?.address.state}{" "}
                    {issuer?.address.postal_code}
                  </InfoCard>
                </div>
              )}

              {issuer?.business_address && (
                <div className="col">
                  <InfoCard title="Business Address">
                    {issuer?.business_address.line_1}
                    <br />
                    {issuer?.business_address.line_2 ? (
                      <>
                        {issuer?.business_address.line_2}
                        <br />
                      </>
                    ) : null}
                    {issuer?.business_address.city},{" "}
                    {issuer?.business_address.state}{" "}
                    {issuer?.business_address.postal_code}
                  </InfoCard>
                </div>
              )}
            </div>

            <OfferingAgreementsList
              offering={offering}
              investment={investment}
              isLoading={spinner}
              onPrintNda={handlePrintNda}
              onDownloadQuestionnaire={downloadQuestionnaire}
              onDownloadPaymentConfirmation={downloadPaymentConfirmation}
              onDownloadAgreement={(agreement) => downloadAgreement(agreement)}
            />

            <OfferingInvestorPackageList
              documents={offering?.documents ?? []}
            />

            <PaymentsCard
              payments={reverse(investment?.payments ?? [])}
              stripePayments={reverse(investment?.stripe_payments ?? [])}
            />

            {investment?.question_response && (
              <div className="card">
                <div className="card-header">
                  <h4 className="card-header-title">Investor Questionnaire</h4>
                  <button
                    className="btn btn-sm btn-light"
                    onClick={() => showInvestorReviewModal(true)}
                  >
                    View
                  </button>
                </div>
              </div>
            )}
          </div>

          <div className="col-md-4">
            <OfferingInfoCard offering={offering} />
            <RecentActivityCard
              statusHistory={reverse(investment?.status_history ?? [])}
            />
          </div>
        </div>
      </div>
      <div hidden className="small p-3">
        {/* TODO: Use to trigger NDA printing instead of API call */}
        <div ref={ndaRef} className="p-6">
          <h2 className="fs-1">Non-Disclosure Agreement</h2>

          <InvestmentNdaCopy
            name={offering?.issuer?.name}
            state={issuer?.address?.state}
          />

          <hr />

          <div className="fs-3">Name: {investment?.investor?.name}</div>
          <div className="fs-3">
            User IP: {investment?.accept_tos?.["x-appengine-user-ip"]}
          </div>
          <div className="fs-3">
            User City: {investment?.accept_tos?.["x-appengine-city"]}
          </div>
          <div className="fs-3">
            User Country: {investment?.accept_tos?.["x-appengine-country"]}
          </div>

          {/* {
         const userAgent = new UAParser(investment?.accept_tos['user-agent'])
        } */}
        </div>
      </div>
      <Modal
        centered
        size="xl"
        show={modalInvestorReview}
        onHide={() => showInvestorReviewModal(false)}
      >
        <Modal.Header closeButton>Review Investor Questionnaire</Modal.Header>
        <Modal.Body>
          {investment?.question_response &&
            offering?.questionnaire?.map((section, index) => {
              return (
                <div key={index}>
                  <h2 className="mb-0">{section.title}</h2>
                  <h3 className="mb-0">{section.subtitle}</h3>
                  <div dangerouslySetInnerHTML={{ __html: section.text }} />
                  {section.questions.map((question, index) => {
                    return (
                      <div key={index}>
                        <label>{question.text}</label>
                        {question.type === "radio" && (
                          <>
                            {question.options?.map((option, index) => {
                              return (
                                <div key={index}>
                                  <div className="form-check mb-2">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      defaultChecked={
                                        investment?.question_response[
                                          question.id
                                        ] === option.value
                                      }
                                      disabled
                                    />
                                    <label className="form-check-label ms-2">
                                      {option.text}
                                    </label>
                                  </div>
                                </div>
                              );
                            })}
                          </>
                        )}

                        {question.type === "radio" &&
                          question.id !== "entity_individual" &&
                          investment?.question_response[question.id] ===
                            "yes" && (
                            <>
                              <div>
                                <input
                                  type="text"
                                  name={question?.id}
                                  id={question?.id}
                                  className="form-control"
                                  placeholder={
                                    investment?.question_response[
                                      question.id.concat("_reason")
                                    ]
                                  }
                                  disabled
                                />
                              </div>
                            </>
                          )}

                        {question.type === "checkbox" && (
                          <>
                            {question.options?.map((option, index) => {
                              return (
                                <div key={index}>
                                  <div className="form-check mb-2">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      defaultChecked={
                                        investment?.question_response[
                                          option.id
                                        ] === true
                                      }
                                      disabled
                                    />
                                    <label className="form-check-label ms-2">
                                      {option.text}
                                    </label>
                                  </div>
                                </div>
                              );
                            })}
                          </>
                        )}

                        {[
                          "text",
                          "ssn",
                          "ein",
                          "email",
                          "phone",
                          "zip",
                        ].includes(question.type) && (
                          <>
                            <p className="fw-bold">
                              {investment?.question_response[question.id]}
                            </p>
                          </>
                        )}
                      </div>
                    );
                  })}
                  <hr />
                </div>
              );
            })}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default InvestmentOverview;
