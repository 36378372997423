import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { services } from '../../constants';
import AppHeader from '../../layouts/appHeaderNav';

import axios from 'axios';

const Services = (props) => {

    const user = useSelector((store) => store.user);
    const [spinner, showSpinner] = useState(false);

    const sendToSlack = (message) => {
        showSpinner(true);
        axios.post(`/webhook/slack`, {
            text: message,
            username: 'EquiDeFi Bot',
            channel: 'dev-notifications'
        }).then(response => {
            toast.success('Thank you, someone from EquiDeFi team will contact you shortly');
            showSpinner(false);
        })
    }

    return (
        <>
            <AppHeader name='Services'>
                <div className='row align-items-center'>
                    <div className='col'>
                        <ul className='nav nav-tabs nav-overflow header-tabs'>
                            <li className='nav-item'>
                                <Link to='/app/services' className='nav-link text-nowrap active'>Marketplace</Link>
                            </li>
                            <li className='nav-item'>
                                <Link to='/app/deals' className='nav-link text-nowrap'>New Deals</Link>
                            </li>
                            <li className='nav-item'>
                                <Link to='/app/events' className='nav-link text-nowrap'>Events</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </AppHeader>

            <div className='container-fluid'>
                <div className='row'>
                    {
                        services.map((item, index) => {
                            return (
                                <div className='col col-lg-4 col-md-6 col-12' key={index}>
                                    <div className='card'>
                                        <div className='card-body'>
                                            <div className='row d-flex align-items-center justify-content-between'>
                                                <div className='col'>
                                                    <h3 className='mb-0'>{item.name}</h3>
                                                </div>
                                                {
                                                    item.new && <div className='col-auto'>
                                                        <span className='badge bg-eq-primary'>New</span>
                                                    </div>
                                                }
                                            </div>

                                            <h1 className='my-3'>{item.price} <span className='small text-muted'>{item.frequency}</span></h1>
                                            <hr className='mt-3' />

                                            {/* <ul className='ps-4'>
                                                {
                                                    item.benefits?.map((benefit, index) => {
                                                        return (
                                                            <li key={index}>{benefit}</li>
                                                        )
                                                    })
                                                }
                                            </ul> */}
                                            <div className="d-flex justify-content-center align-items-center">
                                                <img src="/img/coming_soon.png" alt="comming-soon" height={150} className="pb-4" />
                                            </div>

                                            <button disabled={spinner} className='w-100 btn btn-eq-primary' onClick={() => sendToSlack(`${user.name} (${user.email}) is interested in ${item.name}`)}>I'm Interested</button>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </>
    )
}

export default Services;