import { useMutation, useQuery } from "@tanstack/react-query";
import AccreditationClient from "../clients/AccreditationClient";

export const useOfferingVault = (offeringId, options = {}) => {
  return useQuery(
    ["offering-vault", offeringId],
    () => AccreditationClient.getOfferingVault(offeringId),
    {
      ...options,
    }
  );
};

export const useUpdateOfferingVault = (options = {}) => {
  return useMutation(
    async (data) => AccreditationClient.updateOfferingVault(data),
    options
  );
};
