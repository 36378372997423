import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import { offeringIsRegD, offeringIsRule506c } from "@equidefi/shared";
import { identify as identifyIntercom } from "../../helpers/intercom";
import { InvestmentStatusView } from "../../components/offerings/StatusView";
import { useCurrentInvestor } from "../../hooks/use-current-investor";
import { useInvestment } from "../../hooks/useInvestments";
import AccreditationSelfReported from "./questionare/QuestionareSelfReported";
import Questionnaire506c from "./questionare/Questionnaire506c";
import { QUESTIONNAIRE_REJECTED } from "@equidefi/shared/constants/investments";
import { useSelector } from "react-redux";

const RejectedQuestionareArea = () => {
  return (
    <section className="py-4">
      <div className="container">
        <h1 className="fs-2 text-md-start mt-4 mb-6">Accreditation Results</h1>
        <div className="row">
          <div className="d-none d-md-block col-md-6">
            <img
              src="/img/investor-review.svg"
              className="img-fluid d-block w-100 mx-auto mb-6 mb-md-0 aos-init aos-animate"
              alt="Accredited Investor Questionnaire"
              data-aos="fade-up"
              data-aos-delay="100"
            />
          </div>
          <div className="col-12 col-md-6">
            <InvestmentStatusView title="Sorry!" iconClass="fe-shield-off">
              It looks like your accreditation status is rejected. If you
              believe this to be in error, please contact the issuer of the
              offering.
            </InvestmentStatusView>
          </div>
        </div>
      </div>
    </section>
  );
};

const OpenOfferingQuestionnaire = ({ offering }) => {
  const user = useSelector((store) => store.user);
  const { investmentId } = useParams();

  useCurrentInvestor({ investmentId });

  const { data: investment } = useInvestment(investmentId);

  useEffect(() => {
    if (!user) return;
    identifyIntercom(user);
  }, [user]);

  if (investment?.status === QUESTIONNAIRE_REJECTED)
    return <RejectedQuestionareArea />;

  if (offeringIsRegD(offering) && offeringIsRule506c(offering))
    return <Questionnaire506c offering={offering} />;

  return <AccreditationSelfReported offering={offering} />;
};

export default OpenOfferingQuestionnaire;
