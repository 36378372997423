import axios from "axios";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";

import Spinner from "../../../components/spinner";
import UserTokenService from "../../../services/UserTokenService";

const formData = {
  email: "",
  user_name: "",
};

const formSchema = Yup.object().shape({
  email: Yup.string().email("Please enter a valid email").required("Required."),
  user_name: Yup.string().required("Required"),
});

const InvestorRegister = () => {
  const dispatch = useDispatch();

  const [spinner, showSpinner] = useState(false);

  const [alreadyVerified, setAlreadyVerified] = useState(false);
  const [createdSuccess, setCreatedSuccess] = useState(false);

  useEffect(() => {
    UserTokenService.setToken();
    dispatch({ type: "offering", payload: {} });
    dispatch({ type: "investment", payload: {} });
    dispatch({ type: "token", payload: "" });
    dispatch({ type: "email", payload: "" });
    dispatch({ type: "user", payload: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createInvestor = async (user) => {
    try {
      const response = await axios.post(
        "/investor",
        { user_id: user.id },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      const { investor } = response?.data ?? {};

      if (!investor) {
        toast.error("Something went wrong.");
        return;
      }

      dispatch({ type: "user", payload: user });
      dispatch({ type: "investor", payload: investor });

      await axios.post(`/investor/${investor.id}/status/pending`);

      setCreatedSuccess(true);
    } catch (error) {
      setCreatedSuccess(false);
      setAlreadyVerified(false);
    }
  };

  const signUp = async (form) => {
    const user = {
      name: form.user_name,
      email: form.email.toLowerCase().trim(),
    };

    showSpinner(true);

    try {
      const response = await axios.post("auth/user/register", user);
      dispatch({ type: "user", payload: response.data.user });
      dispatch({ type: "token", payload: response.data.user.token });
      UserTokenService.setToken(response.data.user.token);
      createInvestor(response.data.user);
    } catch (err) {
      console.log(err);
    }

    showSpinner(false);
  };

  return (
    <div className="container-fluid">
      <div className="row vh-100 justify-content-center">
        <div className="col-12 col-md-5 col-lg-6  col-xl-4 px-lg-6 my-5 align-self-center">
          <img
            src="/img/logo.svg"
            width="150px"
            alt="logo"
            className="d-block m-auto mb-5"
          />
          <h1 className="text-center mb-3">Welcome</h1>
          <p className="text-muted text-center mb-5">Investor Portal</p>

          {alreadyVerified && (
            <Alert variant="warning">
              This investor account already exists. <Link to="/">Login</Link> to
              your vault.
            </Alert>
          )}

          {createdSuccess && (
            <Alert variant="success">
              Thank you for registering as an investor. Please check your email
              to continue and complete the questionnaire.
            </Alert>
          )}

          <Formik
            initialValues={formData}
            validationSchema={formSchema}
            onSubmit={signUp}
          >
            {({
              handleChange,
              handleSubmit,
              values,
              errors,
              touched,
              handleBlur,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                <div className="form-group">
                  <label className="form-label">
                    Email Address <span className="text-danger">*</span>
                  </label>
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    placeholder="name@address.com"
                    value={values.email}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {errors.email && touched.email ? (
                    <div className="text-danger mt-2 ms-1 h5">
                      {errors.email}
                    </div>
                  ) : null}
                </div>
                <div className="form-group">
                  <div className="row ">
                    <div className="col d-flex justify-content-between">
                      <label className="form-label">
                        Name <span className="text-danger">*</span>
                      </label>
                    </div>
                  </div>
                  <div className="input-group input-group-merge">
                    <input
                      className="form-control"
                      id="user_name"
                      name="user_name"
                      type={"text"}
                      placeholder="Enter your user Name"
                      onBlur={handleBlur}
                      value={values.user_name}
                      onChange={handleChange}
                    />
                  </div>
                  {errors.user_name && touched.user_name ? (
                    <div className="text-danger mt-2 ms-1 h5">
                      {errors.user_name}
                    </div>
                  ) : null}
                </div>
                <Spinner show={spinner}>
                  <button
                    className="btn w-100 btn-eq-primary mb-3"
                    type="submit"
                  >
                    Continue
                  </button>
                </Spinner>
              </form>
            )}
          </Formik>
        </div>
        <div className="col-12 col-md-7 col-lg-6 col-xl-8 d-none d-lg-block">
          <div
            className="bg-cover h-100 min-vh-100 mt-n1 me-n3"
            style={{ backgroundImage: "url(/img/login.jpg)" }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default InvestorRegister;
