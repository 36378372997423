import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  AGREEMENT_PENDING,
  AGREEMENT_SIGNED,
  PAYMENT_PENDING,
} from "@equidefi/shared/constants/investments";

import { isCommonStockType, isConvertibleOffering } from "../../helpers";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import Countdown from "./../../components/Countdown";
import ProgressBar from "./../../components/ProgressBar";
import Spinner from "./../../components/spinner";

const Offering = (props) => {
  const dispatch = useDispatch();
  const investment = useSelector((store) => store.investment);
  const offering = useSelector((store) => store.offering);
  const history = useHistory();

  useEffect(() => {
    if (investment?.status === (AGREEMENT_SIGNED || AGREEMENT_PENDING)) {
      history.push("/agreement");
    } else if (investment?.status === PAYMENT_PENDING) {
      history.push("/payment");
    }
    // eslint-disable-next-line
  }, []);

  useDocumentTitle([investment?.offering?.name, investment?.issuer?.name]);

  const [spinner, showSpinner] = useState(false);

  const doAcceptTC = async (e) => {
    showSpinner(true);
    axios
      .post(`investment/${investment.id}/tc/${e.target.checked}`)
      .then((response) => {
        axios.get(`investment/${investment.id}`).then((response) => {
          dispatch({ type: "investment", payload: response.data });
          showSpinner(false);
        });
      });
  };

  return (
    <>
      <Countdown from={offering.termination_date} />
      <ProgressBar index={2} />
      <section className="py-4">
        <div className="container">
          <h2 className="text-md-start mb-0 mt-4">{offering.name}</h2>
          <div className="d-flex">
            <p className="text-muted">{`${investment?.issuer?.name},`}</p>
            <a
              className="ps-3"
              href={`https://finance.yahoo.com/quote/${offering?.stock_ticker}/`}
              target="_blank"
              rel="noreferrer"
            >
              {offering?.stock_ticker}{" "}
            </a>
          </div>
          <p>{offering.description}</p>

          <div className="row mt-4">
            {isCommonStockType(offering.stock_type) && (
              <div className="col-md-4">
                <div className="card">
                  <div className="card-body p-4">
                    <h6 className="text-uppercase mb-1 text-muted">
                      Price per Share
                    </h6>
                    <h3 className="mb-0">
                      <NumberFormat
                        displayType="text"
                        thousandSeparator={true}
                        value={offering.unit_price}
                        prefix="$"
                      />
                    </h3>
                  </div>
                </div>
              </div>
            )}
            {isCommonStockType(offering.stock_type) && (
              <div className="col-md-4">
                <div className="card">
                  <div className="card-body p-4">
                    <h6 className="text-uppercase mb-1 text-muted">
                      Available Shares
                    </h6>
                    <h3 className="mb-0">
                      <NumberFormat
                        displayType="text"
                        thousandSeparator={true}
                        value={offering.total_units}
                      />
                    </h3>
                  </div>
                </div>
              </div>
            )}
            <div className="col-md-4">
              <div className="card">
                <div className="card-body p-4">
                  <h6 className="text-uppercase mb-1 text-muted">
                    Total Amount
                  </h6>
                  <h3 className="mb-0">
                    <NumberFormat
                      displayType="text"
                      thousandSeparator={true}
                      value={offering.total_amount}
                      prefix="$"
                      decimalScale={0}
                    />
                  </h3>
                </div>
              </div>
            </div>
            {isConvertibleOffering(offering.stock_type) &&
              (offering?.additional_info?.enter_maturity_date ? (
                <div className="col-md-4">
                  <div className="card">
                    <div className="card-body p-4">
                      <h6 className="text-uppercase mb-1 text-muted">
                        Maturity Date
                      </h6>
                      <h3 className="mb-0">
                        {moment(
                          offering?.additional_info?.maturity_date
                        ).format("LL")}
                      </h3>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="col-md-4">
                  <div className="card">
                    <div className="card-body p-4">
                      <h6 className="text-uppercase mb-1 text-muted">
                        Term (in months)
                      </h6>
                      <h3 className="mb-0">
                        {offering?.additional_info?.term}
                      </h3>
                    </div>
                  </div>
                </div>
              ))}
            {isConvertibleOffering(offering.stock_type) && (
              <div className="col-md-4">
                <div className="card">
                  <div className="card-body p-4">
                    <h6 className="text-uppercase mb-1 text-muted">
                      Conversion Price
                    </h6>
                    <h3 className="mb-0">
                      {offering?.unit_price === "$0.00"
                        ? `As per Note`
                        : `${offering?.unit_price}`}
                    </h3>
                  </div>
                </div>
              </div>
            )}
            {/* {
              offering.stock_type === 'Convertible Notes' &&
              allSettings.map((setting) => {
                return (
                  <div className='col-md-4'>
                    <div className='card'>
                      <div className='card-body p-4'>
                        <h6 className='text-uppercase mb-1 text-muted'>{setting.name}</h6>
                        <h3 className='mb-0'>{setting.id === 'maturity_date' ? setting.value === '0' ? '' : moment.utc(setting.value).local().format('MMMM Do YYYY') : <NumberFormat value={setting?.value} displayType='text' thousandSeparator={true} prefix={amountPrefixItems.includes(setting.id) ? '$' : ''} suffix={percentageSuffixItems.includes(setting.id) ? '%' : ''} />}</h3>
                      </div>
                    </div>
                  </div>
                );
              })
            } */}
          </div>
          <div className="row mt-4">
            {isConvertibleOffering(offering.stock_type) && (
              <div className="col-md-4">
                <div className="card">
                  <div className="card-body p-4">
                    <h6 className="text-uppercase mb-1 text-muted">
                      Original Issuance Discount ($)
                    </h6>
                    <h3 className="mb-0">
                      ${offering?.additional_info?.oic_amount}
                    </h3>
                  </div>
                </div>
              </div>
            )}
            {isConvertibleOffering(offering.stock_type) && (
              <div className="col-md-4">
                <div className="card">
                  <div className="card-body p-4">
                    <h6 className="text-uppercase mb-1 text-muted">
                      Original Issuance Discount (%)
                    </h6>
                    <h3 className="mb-0">
                      {offering?.additional_info?.oic_percentage}%
                    </h3>
                  </div>
                </div>
              </div>
            )}
            {isConvertibleOffering(offering.stock_type) && (
              <div className="col-md-4">
                <div className="card">
                  <div className="card-body p-4">
                    <h6 className="text-uppercase mb-1 text-muted">
                      Original Principal Amount
                    </h6>
                    <h3 className="mb-0">
                      {offering?.additional_info?.original_principle_amount && (
                        <NumberFormat
                          prefix="$"
                          decimalScale={0}
                          value={
                            offering?.additional_info?.original_principle_amount
                          }
                          displayType="text"
                          thousandSeparator={true}
                        />
                      )}
                    </h3>
                  </div>
                </div>
              </div>
            )}
          </div>

          {offering.documents?.length > 0 && (
            <div className="card mt-4">
              <div className="card-header">
                <h3 className="mb-0">Data Room</h3>
              </div>
              <div className="eq-badge px-6">
                <span className="h6 text-uppercase fw-bold">
                  Please read through all the documents carefully before you
                  continue.
                </span>
              </div>
              <div className="list-group">
                {offering.documents.map((document, index) => {
                  return (
                    <div className="list-group-item" key={index}>
                      <div className="row">
                        <div className="col">{document.type}</div>
                        <div className="col-auto">
                          <a
                            href={document.document_url}
                            target="_blank"
                            rel="noreferrer"
                            className="btn btn-xs btn-info-soft"
                          >
                            Download <i className="fe fe-download ms-1" />
                          </a>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          <Spinner show={spinner}>
            <div className="mt-4">
              <div className="form-check">
                <input
                  className="form-check-input"
                  name="tc"
                  type="checkbox"
                  checked={investment.accept_tc}
                  onChange={doAcceptTC}
                />
                <label className="form-check-label">
                  I acknowledge that I have read through all the documents.
                </label>
              </div>
            </div>
            <div className="my-4">
              <button
                onClick={() => props.history.push(`/subscription`)}
                className="btn btn-eq-primary shadow lift me-1"
                disabled={!investment.accept_tc}
              >
                Continue <i className="fe fe-arrow-right ms-1"></i>
              </button>
            </div>
          </Spinner>
        </div>
      </section>
    </>
  );
};

export default Offering;
