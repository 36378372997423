import APIClient from "./api";

class UserClient {
  static async me() {
    const response = await APIClient().get("/me");
    return response.data;
  }

  static async legacyRegister(data) {
    const response = await APIClient().post("/auth/user/register", data);
    return response.data;
  }

  static async register(data) {
    const response = await APIClient().post("/v2/users/register", data);
    return response.data;
  }

  static async changePassword(payload) {
    const response = await APIClient().post(
      "/v2/users/changePassword",
      payload
    );
    return response.data;
  }

  static async mfa(data) {
    const response = await APIClient().post("/v2/users/mfa", data);
    return response.data;
  }

  static async login(data) {
    const response = await APIClient().post("/v2/users/auth", data);
    return response.data;
  }

  static async update(data) {
    const response = await APIClient().post("/v2/user/me", data);
    return response.data;
  }
}

export default UserClient;
