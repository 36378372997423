import { useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";

import OfferingClient from "../../clients/OfferingClient";

/**
 * @param {string} offeringId
 * @param {import("@tanstack/react-query").QueryObserverOptions} options
 */
export const useOffering = (offeringId, options = {}) => {
  return useQuery(
    ["offering", offeringId],
    () => OfferingClient.find(offeringId),
    {
      enabled: !!offeringId,
      ...options,
    }
  );
};

/**
 * @param {string} offeringId
 * @param {import("@tanstack/react-query").QueryObserverOptions} options
 */
export const useOpenOffering = (offeringId, options = {}) => {
  return useQuery(
    ["offering", offeringId, "v2"],
    () => OfferingClient.findOpen(offeringId),
    {
      enabled: !!offeringId,
      ...options,
    }
  );
};

export const useOfferingSettings = (offeringId) => {
  return useQuery(
    ["offering", offeringId, "settings"],
    () => OfferingClient.getLegacySettings(offeringId),
    {
      onError: (err) => {
        toast.error("Sorry! Offering could not be found");
      },
    }
  );
};

/**
 * @param {string} id
 * @param {import("@tanstack/react-query").QueryObserverOptions} options
 */
export const useOfferingBrokers = (id, options = {}) => {
  return useQuery(
    ["offering", id, "brokers"],
    () => OfferingClient.getBrokers(id),
    options
  );
};
