import { useMutation, useQuery } from "@tanstack/react-query";

import InvestmentClient from "../clients/InvestmentClient";
import StripeClient from "../clients/StripeClient";

/**
 * @param {string} id
 * @param {import("@tanstack/react-query").QueryObserverOptions} options
 */
export const useInvestment = (id, options = {}) => {
  return useQuery(["investment", id], () => InvestmentClient.get(id), {
    enabled: !!id,
    ...options,
  });
};

/**
 * @param {import("@tanstack/react-query").QueryObserverOptions} options
 */
export const useCurrentInvestment = (options = {}) => {
  return useQuery(
    ["currentInvestment"],
    () => InvestmentClient.currentInvestment(),
    {
      ...options,
    }
  );
};

export const useUserInvestments = (params, options = {}) => {
  return useQuery(
    ["userInvestments", params],
    () => InvestmentClient.all(params),
    {
      ...options,
    }
  );
};

/**
 * @param {string} id
 * @param {"accreditation" | "investor-info"} stage
 * @param {import("@tanstack/react-query").UseMutationOptions} options
 */
export const useInvestmentUpdateStage = (id, stage, options = {}) => {
  return useMutation(
    ({ data } = {}) => InvestmentClient.updateStage(id, stage, data),
    options
  );
};

/**
 * @param {string} id
 * @param {import("@tanstack/react-query").UseMutationOptions} options
 */
export const useUpdateInvestment = (id, options = {}) => {
  return useMutation((data) => InvestmentClient.update(id, data), options);
};

/**
 * @param {string} investmentId
 * @param {import("@tanstack/react-query").UseMutationOptions} options
 */
export const useInitiatePaymentMutation = (investmentId, options = {}) => {
  return useMutation(
    () => StripeClient.createPaymentIntent(investmentId),
    options
  );
};

export const useResetInquiry = () => {
  return useMutation(({ investmentId, inquiryId }) =>
    InvestmentClient.resetInquiry(investmentId, inquiryId)
  );
};

export const useInvestmentCreate = () => {
  return useMutation(async (offeringId) =>
    InvestmentClient.createInvestment(offeringId)
  );
};
