const TOKEN_KEY = "user:token";

class UserTokenService {
  static token() {
    return localStorage.getItem(TOKEN_KEY);
  }

  static setToken(value) {
    localStorage.setItem(TOKEN_KEY, value);
  }
}

export default UserTokenService;
