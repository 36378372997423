import moment from "moment";
import React, { useCallback, useMemo, useState } from "react";
import NumberFormat from "react-number-format";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import InvestmentClient from "../../clients/InvestmentClient";
import DocumentReviewDownloadCard from "../../components/offerings/DocumentReviewDownloadCard";
import OfferingBrokerSelect from "../../components/offerings/OfferingBrokerSelect";
import Spinner from "../../components/spinner";
import { CurrencyFormatter, NumberFormatter } from "../../constants/format";
import {
  addInvestmentBroker,
  isCommonStockType,
  isConvertibleOffering,
  removeInvestmentBroker,
} from "../../helpers";
import { useOffering } from "../../hooks/queries/offerings";
import { useCurrentInvestor } from "../../hooks/use-current-investor";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import { useInvestment } from "../../hooks/useInvestments";

const OpenOfferingSubscription = ({ offering }) => {
  const { offeringId, investmentId } = useParams();
  const history = useHistory();

  useCurrentInvestor({ investmentId });

  const { data: legacyOffering } = useOffering(offeringId);
  const { data: investment, refetch: refetchInvestment } = useInvestment(
    investmentId,
    {
      onSuccess: (data) => {
        setQuantity(data.quantity);
        setAmount(data.amount);
      },
    }
  );

  useDocumentTitle([
    "Subscription",
    legacyOffering?.name,
    investment?.issuer?.name,
  ]);

  const totalUnits = Number(
    legacyOffering?.remaining_units.replace(/[^0-9.-]+/g, "")
  );
  const totalAmount = Number(
    legacyOffering?.remaining_amount.replace(/[^0-9.-]+/g, "")
  );
  const unitPrice = Number(
    legacyOffering?.unit_price.replace(/[^0-9.-]+/g, "")
  );

  const [selectedBroker, setSelectedBroker] = useState();
  const [quantity, setQuantity] = useState(() => investment?.quantity || 0);
  const [amount, setAmount] = useState(() => investment?.quantity * unitPrice);

  const [spinner, showSpinner] = useState(false);

  const quantityError = useMemo(() => {
    const q = Math.round(quantity);
    const minUnits = legacyOffering?.investor_minimum_units;

    if (Number(quantity) !== q) {
      return `Quantity must not be a decimal`;
    } else if (q > 0 && q < minUnits) {
      return `Minimum ${NumberFormatter.format(minUnits)} shares required`;
    }

    return null;
  }, [investment, legacyOffering?.investor_minimum_units, amount, quantity]);

  const amountError = useMemo(() => {
    // Cannot detect an error without an investment or amount
    if (!investment || !amount) {
      return;
    }

    const q = Math.round(quantity);
    const minUnits = legacyOffering?.investor_minimum_units;

    if (!investment.accredited) {
      const maxAmount = Math.round(investment.annual_income / 10);

      if (parseFloat(amount) > maxAmount) {
        return `Based on your stated income, the invested amount must be less than ${CurrencyFormatter.format(
          maxAmount
        )}`;
      }
    }

    if (quantity > totalUnits || amount > totalAmount) {
      return `The invested amount must be less than ${totalAmount}`;
    }

    if (q > 0 && q < minUnits) {
      return `Minimum ${CurrencyFormatter.format(
        minUnits * unitPrice
      )} investment required`;
    }

    return null;
  }, [
    investment,
    amount,
    quantity,
    legacyOffering?.investor_minimum_units,
    unitPrice,
  ]);

  const handleQuantityChange = useCallback(
    ({ value }) => {
      setQuantity(value);
      setAmount(value * unitPrice);
    },
    [unitPrice]
  );

  const handleAmountChange = ({ value }) => {
    setAmount(value);
    setQuantity(unitPrice > 0 ? value / unitPrice : 0);
  };

  const submitForm = async () => {
    showSpinner(true);

    try {
      await InvestmentClient.update(investment.id, {
        amount,
        quantity,
      });

      await InvestmentClient.legacyUpdateStatus(
        investment.id,
        "agreement_pending"
      );

      if (selectedBroker) {
        await addInvestmentBroker(investment.id, selectedBroker.value.id);
      } else {
        await removeInvestmentBroker(investment.id);
      }

      await refetchInvestment();

      history.push(`/offering/${offeringId}/agreements/${investment.id}`);
    } catch (err) {
      toast.error("Sorry! Something went wrong updating your subscription");
      console.error(err);
    } finally {
      showSpinner(false);
    }
  };

  if (!legacyOffering || !investment) {
    return <Spinner show />;
  }

  return (
    <section className="mt-4">
      <div className="container">
        <h2 className="text-md-start mb-0 mt-4">Subscription</h2>
        <p className="text-muted">
          Please enter your investment amount either in the dollar value or
          number of shares
        </p>
        {investment && (
          <>
            <div className="row">
              {isCommonStockType(legacyOffering.stock_type) && (
                <>
                  <div className="col-md-4">
                    <div className="card">
                      <div className="card-body p-4">
                        <h6 className="text-uppercase mb-1 text-muted">
                          Price per Share
                        </h6>
                        <h3 className="mb-0">
                          <NumberFormat
                            displayType="text"
                            thousandSeparator={true}
                            fixedDecimalScale={2}
                            decimalScale={2}
                            value={legacyOffering.unit_price}
                            prefix="$"
                          />
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="card">
                      <div className="card-body p-4">
                        <h6 className="text-uppercase mb-1 text-muted">
                          Available Shares
                        </h6>
                        <h3 className="mb-0">
                          <NumberFormat
                            displayType="text"
                            thousandSeparator={true}
                            value={legacyOffering.total_units}
                          />
                        </h3>
                      </div>
                    </div>
                  </div>
                </>
              )}
              <div className="col-md-4">
                <div className="card">
                  <div className="card-body p-4">
                    <h6 className="text-uppercase mb-1 text-muted">
                      Total Amount
                    </h6>
                    <h3 className="mb-0">
                      <NumberFormat
                        displayType="text"
                        thousandSeparator={true}
                        fixedDecimalScale={2}
                        decimalScale={2}
                        value={legacyOffering.total_amount}
                        prefix="$"
                      />
                    </h3>
                  </div>
                </div>
              </div>
              {isConvertibleOffering(legacyOffering.stock_type) &&
                (legacyOffering?.additional_info?.enter_maturity_date ? (
                  <div className="col-md-4">
                    <div className="card">
                      <div className="card-body p-4">
                        <h6 className="text-uppercase mb-1 text-muted">
                          Maturity Date
                        </h6>
                        <h3 className="mb-0">
                          {moment(
                            legacyOffering?.additional_info?.maturity_date
                          ).format("LL")}
                        </h3>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="col-md-4">
                    <div className="card">
                      <div className="card-body p-4">
                        <h6 className="text-uppercase mb-1 text-muted">
                          Term (in months)
                        </h6>
                        <h3 className="mb-0">
                          {legacyOffering?.additional_info?.term}
                        </h3>
                      </div>
                    </div>
                  </div>
                ))}
              {isConvertibleOffering(legacyOffering.stock_type) && (
                <div className="col-md-4">
                  <div className="card">
                    <div className="card-body p-4">
                      <h6 className="text-uppercase mb-1 text-muted">
                        Conversion Price
                      </h6>
                      <h3 className="mb-0">
                        {legacyOffering?.unit_price === "$0.00"
                          ? `As per Note`
                          : `${legacyOffering?.unit_price}`}
                      </h3>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="row mt-4">
              {isConvertibleOffering(legacyOffering.stock_type) && (
                <>
                  <div className="col-md-4">
                    <div className="card">
                      <div className="card-body p-4">
                        <h6 className="text-uppercase mb-1 text-muted">
                          Original Issuance Discount ($)
                        </h6>
                        <h3 className="mb-0">
                          ${legacyOffering?.additional_info?.oic_amount}
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="card">
                      <div className="card-body p-4">
                        <h6 className="text-uppercase mb-1 text-muted">
                          Original Issuance Discount (%)
                        </h6>
                        <h3 className="mb-0">
                          {legacyOffering?.additional_info?.oic_percentage}%
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="card">
                      <div className="card-body p-4">
                        <h6 className="text-uppercase mb-1 text-muted">
                          Original Principal Amount
                        </h6>
                        <h3 className="mb-0">
                          {legacyOffering?.additional_info
                            ?.original_principle_amount && (
                            <NumberFormat
                              prefix="$"
                              decimalScale={0}
                              value={
                                legacyOffering?.additional_info
                                  ?.original_principle_amount
                              }
                              displayType="text"
                              thousandSeparator={true}
                            />
                          )}
                        </h3>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>

            <div className="row mt-4">
              {isCommonStockType(legacyOffering.stock_type) && (
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <label>Number of shares</label>
                    <NumberFormat
                      className="form-control form-control-lg"
                      allowNegative={false}
                      thousandSeparator={true}
                      value={quantity}
                      defaultValue={quantity || ""}
                      isAllowed={({ formattedValue, floatValue }) => {
                        return (
                          formattedValue === "" || floatValue <= totalUnits
                        );
                      }}
                      onValueChange={handleQuantityChange}
                      decimalScale={2}
                    />
                    {quantityError && (
                      <div className="text-danger">{quantityError}</div>
                    )}
                  </div>
                </div>
              )}

              <div className="col-12 col-md-6">
                <div className="form-group">
                  <label>Investment amount</label>
                  <NumberFormat
                    className="form-control form-control-lg"
                    prefix="$"
                    allowNegative={false}
                    thousandSeparator={true}
                    value={amount}
                    defaultValue={amount || ""}
                    isAllowed={({ formattedValue, floatValue }) => {
                      return formattedValue === "" || floatValue <= totalAmount;
                    }}
                    onValueChange={handleAmountChange}
                    fixedDecimalScale={2}
                    decimalScale={2}
                  />
                  {amountError && (
                    <div className="text-danger">{amountError}</div>
                  )}
                </div>
              </div>
            </div>

            <p className="text-muted">
              Where you directed to this subscription by your broker?
            </p>
            <div className="row mt-4">
              <div className="col-12 col-md-6">
                <div className="form-group">
                  <OfferingBrokerSelect
                    offeringId={legacyOffering?.id}
                    value={selectedBroker}
                    onChange={setSelectedBroker}
                  />
                </div>
              </div>
            </div>

            <DocumentReviewDownloadCard documents={offering.documents} />

            <Spinner show={spinner}>
              <button
                disabled={amount <= 0 || quantityError || amountError}
                className="btn btn-eq-primary shadow lift me-1"
                onClick={submitForm}
              >
                Continue <i className="fe fe-arrow-right ms-1"></i>
              </button>
            </Spinner>
          </>
        )}
      </div>
    </section>
  );
};

export default OpenOfferingSubscription;
