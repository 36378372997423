import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { toast } from "react-toastify";
import { useInvestment } from "./useInvestments";
import { useCurrentUser } from "./useUsers";
import UserTokenService from "../services/UserTokenService";

const FIVE_MINUTES = 1_000 * 60 * 5;

export const useCurrentInvestor = ({ investmentId }) => {
  const history = useHistory();

  const { data: me } = useCurrentUser({
    refetchInterval: FIVE_MINUTES,
    refetchIntervalInBackground: FIVE_MINUTES,
  });
  const { data: investment } = useInvestment(investmentId, {
    enabled: !!investmentId && !me?.id,
  });

  useEffect(() => {
    const token = UserTokenService.token();

    if (!investment || !me) return;

    const dataLoaded = investment?.investor_id && me?.id;
    const isCorrectInvestor = investment?.investor_id === me?.id;

    if (!token || (dataLoaded && !isCorrectInvestor)) {
      toast.error("Please login to continue your investment.");
      history.replace(`/`);
    }
  }, [me, investment]);
};
