import { useEffect } from "react";
import NumberFormat from "react-number-format";
import { useDispatch, useSelector } from "react-redux";

import InvestmentStatus from "../../../components/investmentStatus";
import Spinner from "../../../components/spinner";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";
import AppHeader from "../../../layouts/appHeader";
import Continue from "../../../components/investment/continue";
import { useUserInvestments } from "../../../hooks/useInvestments";
import { disableContinueStatuses } from "../../../constants";

const Vault = (props) => {
  useDocumentTitle("My Vault");

  const dispatch = useDispatch();
  const user = useSelector((store) => store.user);
  const { data: investments, isLoading } = useUserInvestments();

  useEffect(() => {
    dispatch({ type: "investment", payload: {} });
    dispatch({ type: "offering", payload: {} });
    dispatch({ type: "investor", payload: {} });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <AppHeader
        name={`${user.first_name} ${user.last_name}'s Vault`}
      ></AppHeader>
      <div className="container-fluid">
        <div className="card">
          <Spinner show={isLoading}>
            <table className="table card-table">
              <thead>
                <tr>
                  <th>Offering</th>
                  <th className="text-end">Amount</th>
                  <th className="text-center">Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {investments?.map((investment, index) => {
                  return (
                    <tr key={index} style={{ cursor: "pointer" }}>
                      <td
                        onClick={() =>
                          props.history.push(`/app/investment/${investment.id}`)
                        }
                      >
                        {investment.offering?.name}
                        <div className="small text-muted">
                          {investment.issuer.name},{" "}
                          {investment?.offering?.stock_type}
                        </div>
                      </td>
                      <td
                        className="text-end"
                        onClick={() =>
                          props.history.push(`/app/investment/${investment.id}`)
                        }
                      >
                        <NumberFormat
                          value={investment?.amount}
                          displayType="text"
                          thousandSeparator={true}
                          decimalScale={0}
                          prefix="$"
                        />
                      </td>
                      <td
                        className="text-center"
                        onClick={() =>
                          props.history.push(`/app/investment/${investment.id}`)
                        }
                      >
                        <InvestmentStatus status={investment?.status} />
                      </td>
                      <td className="text-center">
                        {!disableContinueStatuses.includes(
                          investment?.status
                        ) && (
                          <Continue
                            investment={investment}
                            offering={investment.offering_real}
                          />
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Spinner>
        </div>
      </div>
    </>
  );
};

export default Vault;
