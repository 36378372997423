import { useEffect } from "react";
import Confetti from "react-confetti";
import { Link } from "react-router-dom";

import { createPortal } from "react-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { stripePromise } from "../../constants/stripe";
import { useCurrentUser } from "../../hooks/useUsers";
import { useCurrentInvestor } from "../../hooks/use-current-investor";

const OpenOfferingCompleted = () => {
  const { investmentId } = useParams();
  const { data: user } = useCurrentUser();

  useCurrentInvestor({ investmentId });

  useEffect(() => {
    const effect = async () => {
      const stripe = await stripePromise;

      const clientSecret = new URLSearchParams(window.location.search).get(
        "payment_intent_client_secret"
      );

      if (!clientSecret) return;

      const res = await stripe.retrievePaymentIntent(clientSecret);
    };

    effect();
  }, []);

  return (
    <>
      <div className="container mt-5">
        <div className="row vh-100 justify-content-center">
          <div className="col-12">
            <h1 className="text-center text-md-start">Thank You!</h1>
            <h3>
              Your fund transfer request has been initiated. It may take 3-5
              business days to process the payment.
            </h3>
            <hr />
            <p>
              Your EquiDeFi Investor Account has been created. With this unique
              account you can access all of your private placement
              participations in one convenient and secure location. Whether you
              plan to deposit your shares into your brokerage account or convert
              them into shares of a public company, we have you covered. Through
              a system of smart notifications and important anniversaries and
              events, we will assist you with the necessary legal requirements
              and will always be available to answer questions in connection
              with your investment.
            </p>

            <h2 className="mb-0">Subscription Plan</h2>
            <p>
              Free subscription for 6 months, post that $299/ year will be
              automatically debited from your ACH account.
            </p>
            <p>
              Continue to finish creating your account and starting to simplify
              your decision to participate in early stage, venture capital,
              pre-IPO and PIPE investments.
            </p>
            <p>You can unsubscribe from this plan at anytime.</p>

            {user?.active ? (
              <Link to="/app/vault" className="btn btn-eq-primary">
                Go to My Vault <i className="fe fe-arrow-right ms-1"></i>
              </Link>
            ) : (
              <Link to="/complete-profile" className="btn btn-eq-primary">
                Complete your Profile <i className="fe fe-arrow-right ms-1"></i>
              </Link>
            )}
          </div>
          <div className="col-12 col-md-7 col-lg-6 col-xl-8 d-none d-lg-block">
            <div
              className="bg-cover h-100 min-vh-100 mt-n1 me-n3"
              style={{ backgroundImage: "url(img/login.jpg)" }}
            ></div>
          </div>
        </div>
      </div>
      <div className="d-print-none">
        {createPortal(<Confetti />, document.body)}
      </div>
    </>
  );
};

export default OpenOfferingCompleted;
