const OfferingInvestorPackageList = ({ documents = [] }) => {
  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-header-title">Investor Package</h3>
      </div>
      <div className="card-body p-0">
        <div className="list-group list-group-flush">
          {documents?.map((document, index) => (
            <div
              className="list-group-item p-4 align-content-center align-items-center"
              key={index}
            >
              <div className="row">
                <div className="col">{document.type}</div>
                <div className="col-auto">
                  <a
                    href={document.document_url}
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-sm btn-eq-primary"
                  >
                    Download <i className="fe fe-download ms-1" />
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OfferingInvestorPackageList;
