import axios from "axios";
import React from "react";
import { useSelector } from "react-redux";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter5Adapter } from "use-query-params/adapters/react-router-5";

import "bootstrap-daterangepicker/daterangepicker.css";
import "react-toastify/dist/ReactToastify.css";

import UserTokenService from "./services/UserTokenService";

import AdminLayout from "./layouts/adminLayout";
import { LayoutRoute, PrivateLayoutRoute } from "./layouts/appRoute";
import publicLayout from "./layouts/publicLayout";

// Wizard Components
import auth from "./pages/wizard/auth";
import welcome from "./pages/wizard/welcome";

import inReview from "./pages/wizard/inReview";
import LinkExpired from "./pages/wizard/LinkExpired";
import questionnaire from "./pages/wizard/questionnaire";
import questionnaireStart from "./pages/wizard/questionnaireStart";

import offering from "./pages/wizard/offering";
import offeringClosed from "./pages/wizard/offeringClosed";

import agreement from "./pages/wizard/agreement";
import payment from "./pages/wizard/payment";
import paymentManual from "./pages/wizard/paymentManual";
import paymentPending from "./pages/wizard/paymentPending";
import subscription from "./pages/wizard/subscription";

// Login
import completeProfile from "./pages/login/completeProfile";
import forgot from "./pages/login/forgot";
import login from "./pages/login/login";
import mfa from "./pages/login/mfa";
import reset from "./pages/login/reset";
import VerifyInvestor from "./pages/login/VerifyInvestor";

// import Profile from './pages/profile';

import deals from "./pages/app/deals";
import deposit from "./pages/app/deposit";
import events from "./pages/app/events";
import profile from "./pages/app/profile";
import services from "./pages/app/services";
import InvestmentOverview from "./pages/app/vault/InvestmentOverview";
import vault from "./pages/app/vault/vault";
import OfferingJoin from "./pages/offering";
import investorQuestionnaire from "./pages/wizard/investor/questionnaire";
import investorRegister from "./pages/wizard/investor/register";
import OpenOfferingPaymentProcessing from "./pages/offering/OpenOfferingPaymentProcessing";

const App = (props) => {
  const token = useSelector((state) => state.token);
  if (UserTokenService.token()) {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${UserTokenService.token()}`;
  } else {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }

  return (
    <div>
      <Router>
        <QueryParamProvider adapter={ReactRouter5Adapter}>
          <Switch>
            <LayoutRoute
              exact
              path="/auth/:redirectUrl/:investmentId/:token"
              layout={publicLayout}
              component={auth}
            />
            <LayoutRoute
              exact
              path="/welcome"
              layout={publicLayout}
              component={welcome}
            />
            <LayoutRoute
              path="/offering/:offeringId"
              layout={publicLayout}
              component={OfferingJoin}
            />
            <LayoutRoute
              exact
              path="/investor/register"
              layout={publicLayout}
              component={investorRegister}
            />
            <PrivateLayoutRoute
              exact
              path="/investor/questionnaire"
              layout={publicLayout}
              component={questionnaire}
            />
            <PrivateLayoutRoute
              exact
              path="/investor/questionnaire/start"
              layout={publicLayout}
              component={questionnaireStart}
            />
            <PrivateLayoutRoute
              exact
              path="/investor/register/questionnaire"
              layout={publicLayout}
              component={investorQuestionnaire}
            />
            <PrivateLayoutRoute
              exact
              path="/investor/review"
              layout={publicLayout}
              component={inReview}
            />
            <LayoutRoute
              exact
              path="/investor/link-expired"
              layout={publicLayout}
              component={LinkExpired}
            />
            <LayoutRoute
              exact
              path="/offering"
              layout={publicLayout}
              component={offering}
            />
            <PrivateLayoutRoute
              exact
              path="/offering/closed"
              layout={publicLayout}
              component={offeringClosed}
            />
            <PrivateLayoutRoute
              exact
              path="/subscription"
              layout={publicLayout}
              component={subscription}
            />
            <PrivateLayoutRoute
              exact
              path="/agreement"
              layout={publicLayout}
              component={agreement}
            />
            <PrivateLayoutRoute
              exact
              path="/payment"
              layout={publicLayout}
              component={payment}
            />
            <PrivateLayoutRoute
              exact
              path="/payment/pending"
              layout={publicLayout}
              component={paymentPending}
            />
            <PrivateLayoutRoute
              exact
              path="/payment/:offeringId/:investmentId/processing"
              layout={publicLayout}
              component={OpenOfferingPaymentProcessing}
            />
            <PrivateLayoutRoute
              exact
              path="/payment/manual"
              layout={publicLayout}
              component={paymentManual}
            />
            <LayoutRoute
              exact
              path="/"
              layout={publicLayout}
              component={login}
            />
            <LayoutRoute
              exact
              path="/mfa"
              layout={publicLayout}
              component={mfa}
            />
            <LayoutRoute
              exact
              path="/forgot"
              layout={publicLayout}
              component={forgot}
            />
            <PrivateLayoutRoute
              exact
              path="/complete-profile"
              layout={publicLayout}
              component={completeProfile}
            />
            <LayoutRoute
              exact
              path="/reset/:id/:token"
              layout={publicLayout}
              component={reset}
            />
            <LayoutRoute
              exact
              path="/user/verify/:token"
              layout={publicLayout}
              component={VerifyInvestor}
            />
            <PrivateLayoutRoute
              exact
              path="/app/vault"
              layout={AdminLayout}
              component={vault}
            />
            <PrivateLayoutRoute
              exact
              path="/app/investment/:id"
              layout={AdminLayout}
              component={InvestmentOverview}
            />
            <PrivateLayoutRoute
              exact
              path="/app/deposit"
              layout={AdminLayout}
              component={deposit}
            />
            <PrivateLayoutRoute
              exact
              path="/app/services"
              layout={AdminLayout}
              component={services}
            />
            <PrivateLayoutRoute
              exact
              path="/app/deals"
              layout={AdminLayout}
              component={deals}
            />
            <PrivateLayoutRoute
              exact
              path="/app/events"
              layout={AdminLayout}
              component={events}
            />
            <PrivateLayoutRoute
              exact
              path="/app/profile"
              layout={AdminLayout}
              component={profile}
            />
          </Switch>
        </QueryParamProvider>
      </Router>

      <ToastContainer />
    </div>
  );
};

export default App;
