import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import axios from "axios";
import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";

import App from "./app";
import { CONFIG } from "./constants/config";
import { initialize as initializeFullStory } from "./helpers/fullstory";
import { initialize as initializeIntercom } from "./helpers/intercom";
import { sentryStart } from "./helpers/sentry";
import Store from "./store";

axios.defaults.baseURL = CONFIG.API_URL;

sentryStart();
initializeFullStory();
initializeIntercom();

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  },
});

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <Provider store={Store}>
    <QueryClientProvider client={queryClient}>
      <App />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </Provider>
);
