import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import NumberFormat from "react-number-format";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { investmentContinueUrlOpenOffering } from "../helpers/investment";
import { useMFACode } from "../hooks/useAuthentication";
import UserTokenService from "../services/UserTokenService";
import Spinner from "./spinner";

const MFAModal = ({ display, email, onToggle, onComplete }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { offeringId } = useParams();
  const [code, setCode] = useState("");
  const [spinner, showSpinner] = useState(false);
  const confirmMFA = useMFACode();

  if (!display) {
    return null;
  }

  const onConfirm = async () => {
    showSpinner(true);
    try {
      const response = await confirmMFA.mutateAsync({
        mfa_code: code,
        email: email,
      });
      dispatch({ type: "token", payload: response.token });
      dispatch({ type: "user", payload: response.user });
      UserTokenService.setToken(response.token);

      // Now that user is logged in, created the investment for this offering
      const investment = await onComplete();
      // const investment = await createInvestment.mutateAsync(offeringId);
      const continueUrl = investmentContinueUrlOpenOffering(
        investment?.id,
        investment?.status,
        offeringId
      );

      history.push(continueUrl);
      showSpinner(false);
    } catch (error) {
      console.log(error);
      if (error.response?.status === 401) {
        toast.error("Incorrect code, please try again");
      } else {
        toast.error("An unknown error occurred");
      }
      showSpinner(false);
    }
  };

  return (
    <Modal
      show={display}
      onHide={() => {
        setCode("");
        onToggle(false);
      }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      dialogClassName="stopModal"
    >
      <Modal.Header closeButton className="p-0"></Modal.Header>
      <Modal.Body>
        <h1 className="display-4 text-center mb-3">
          Two Factor Authentication
        </h1>
        <p>
          Your account is protected with two-factor authentication. We've sent
          you an email. Please enter the code below.
        </p>

        <Spinner show={spinner}>
          <NumberFormat
            name="mfa_code"
            className="form-control form-control-lg text-center"
            format="######"
            mask="_"
            placeholder="0 0 0 0 0 0"
            value={code}
            onValueChange={({ value }) => setCode(value)}
          />

          <button
            className="btn btn-lg btn-eq-primary mt-3"
            disabled={code.length !== 6}
            onClick={() => onConfirm()}
          >
            Continue
          </button>
        </Spinner>
      </Modal.Body>
    </Modal>
  );
};

export default MFAModal;
