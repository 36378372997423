import { useMutation } from "@tanstack/react-query";
import UserClient from "../clients/UserClient";
import UserTokenService from "../services/UserTokenService";

/**
 * @param {import("@tanstack/react-query").UseMutationOptions} options
 */
export const useLogin = (options = {}) => {
  return useMutation(async (data) => UserClient.login(data), options);
};

/**
 * @param {import("@tanstack/react-query").UseMutationOptions} options
 */
export const useMFACode = (options = {}) => {
  return useMutation(async (data) => UserClient.mfa(data), options);
};

export const useRegister = () => {
  return useMutation(async (data) => {
    const user = await UserClient.register(data);

    UserTokenService.setToken(user.token);

    return user;
  });
};
