import { Link } from 'react-router-dom';

import AppHeader from '../../layouts/appHeaderNav';
import ComingSoon from '../../components/comingSoon';

const Deals = (props) => {
    return (
        <>
            <AppHeader name='Services'>
                <div className='row align-items-center'>
                    <div className='col'>
                        <ul className='nav nav-tabs nav-overflow header-tabs'>
                            <li className='nav-item'>
                                <Link to='/app/services' className='nav-link text-nowrap'>Marketplace</Link>
                            </li>
                            <li className='nav-item'>
                                <Link to='/app/deals' className='nav-link text-nowrap active'>New Deals</Link>
                            </li>
                            <li className='nav-item'>
                                <Link to='/app/events' className='nav-link text-nowrap'>Events</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </AppHeader>

            <div className='container-fluid'>
                <div className='row'>
                    <div className=' col-lg-8 col-xl-6'>
                        <ComingSoon />
                    </div>
                </div>
            </div>
        </>)
}

export default Deals;