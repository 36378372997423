import { Formik } from "formik";
import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  Env,
  FileInputElement,
  LogLevel,
  SkyflowElements,
  useCollectContainer,
  useMakeSkyflowStyles,
} from "skyflow-react-js";
import {
  ASSET_OPTIONS,
  ASSET_VERIFICATION,
  INCOME_OPTIONS,
  INCOME_VERIFICATION,
  PRO_LETTER,
} from "../../../../../packages/shared/constants/accreditation";
import { COMPLETE_PROFILE } from "../../../../../packages/shared/constants/investments";
import AccreditationClient from "../../clients/AccreditationClient";
import Spinner from "../../components/spinner";
import { CONFIG } from "../../constants/config";
import {
  useOfferingVault,
  useUpdateOfferingVault,
} from "../../hooks/useAccreditation";
import { useUpdateInvestment } from "../../hooks/useInvestments";
import "./style.css";
import { Tooltip } from "react-tooltip";
import fileComplete from "../../images/icons8-protect.gif";

const UploadComponent = ({ fileNum, skyflowId, method, handleChange }) => {
  const container = useCollectContainer();
  const [isLoading, setLoading] = useState(false);
  const [isUploaded, setUploaded] = useState(false);
  const useStyles = useMakeSkyflowStyles({
    inputStyles: {
      base: {
        border: "2px dashed #7845c4",
        backgroundColor: "#dfd9ea",
        borderRadius: "4px",
        color: "#12263f",
        fontFamily: '"Roboto", sans-serif',
        padding: "13px 16px",
      },
      complete: {
        color: "#12263f",
      },
      empty: {},
      focus: {},
      invalid: {
        color: "#f44336",
      },
      cardIcon: {
        position: "absolute",
        left: "8px",
        bottom: "calc(50% - 12px)",
      },
      copyIcon: {
        position: "absolute",
        right: "8px",
      },
      global: {
        "@import":
          'url("https://fonts.googleapis.com/css2?family=Roboto&display=swap")',
      },
    },
    labelStyles: {
      base: {
        fontFamily: '"Roboto", sans-serif',
        color: "#0D4370",
        // ...otherStyles
      },
    },
    errorTextStyles: {
      base: {
        color: "#f44336",
        // ...otherStyles
      },
    },
  });

  const classes = useStyles();

  const handleUpload = () => {
    setLoading(true);
    const response = container.uploadFiles({});
    response
      .then((res) => {
        handleChange({
          target: {
            name: `doc_${fileNum}_file`,
            value: res.fileUploadResponse[0].skyflow_id,
          },
        });
        setUploaded(true);
        toast.success("Your file was uploaded successfully!");
      })
      .catch((e) => {
        console.log(e);
        toast.error("Your file failed to upload!");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleOnSelect = (selectedOption) => {
    handleChange({
      target: {
        name: `doc_${fileNum}_type`,
        value: selectedOption.value,
      },
    });
  };

  const OPTIONS = () => {
    if (method === INCOME_VERIFICATION) {
      return INCOME_OPTIONS;
    } else if (method === ASSET_VERIFICATION) {
      return ASSET_OPTIONS;
    }
  };

  const Action = () => {
    if (isLoading) return <Spinner show={true} />;
    if (isUploaded)
      return <img src={fileComplete} alt="File Uploaded Successfully" />;
    return (
      <button
        className="btn btn-sm btn-eq-primary"
        onClick={(e) => {
          e.preventDefault();
          handleUpload();
        }}
      >
        Upload File
      </button>
    );
  };

  const selectStyles = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    menu: (provided) => ({ ...provided, zIndex: "9999 !important" }),
  };

  return (
    <div className="list-group mt-4 upload-file" key={fileNum}>
      <div className="list-group-item">
        <div className="container-fluid">
          <div className="row">
            <div className="element">
              <FileInputElement
                container={container}
                table={"offering"}
                skyflowID={skyflowId}
                column={`doc_${fileNum}_file`}
                classes={classes}
                id={`document_upload_${fileNum}`}
              />
            </div>
            {method === ASSET_VERIFICATION || method === INCOME_VERIFICATION ? (
              <div className="element">
                <Select
                  autoFocus={false}
                  placeholder={"Select a document type"}
                  filterOption={null}
                  menuPortalTarget={document.querySelector("body")}
                  onChange={handleOnSelect}
                  styles={selectStyles}
                  isLoading={isLoading}
                  options={OPTIONS()}
                  components={{
                    IndicatorSeparator: null,
                  }}
                />
              </div>
            ) : null}
            <div className="element button">
              <Action />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Continue = ({ values }) => {
  if (!values.doc_1_file) {
    return (
      <button className="btn btn btn-eq-primary shadow lift disabled" disabled>
        Complete your Profile
      </button>
    );
  }

  return (
    <button className="btn btn-eq-primary" type="submit">
      Complete your Profile
    </button>
  );
};

const OpenOfferingAccreditation = () => {
  const { offeringId, investmentId } = useParams();
  const history = useHistory();
  const [fileCount, setFileCount] = useState(1);
  const updateInvestment = useUpdateInvestment(investmentId);
  const updateVault = useUpdateOfferingVault();
  const { data: vault, isLoading } = useOfferingVault(offeringId, {
    onSuccess: (data) => {
      toast.success(
        `Your encrypted vault has been loaded, please upload your documents`
      );
    },
    onError: (error) => {
      console.error(error);
      toast.error(error?.response.data.errors);
    },
  });

  const onSubmit = async (data) => {
    try {
      await updateVault.mutateAsync({
        offering_id: offeringId,
        ...data,
      });

      try {
        await updateInvestment.mutateAsync({
          status: COMPLETE_PROFILE,
        });
        history.push(`/offering/${offeringId}/complete/${investmentId}`);
      } catch (error) {
        console.log(error);
        toast.error("Failed to update your investment");
      }
    } catch (error) {
      console.log(error);
      toast.error("Failed to submit your files");
    }
  };

  const getBearerToken = () => {
    return new Promise(function (resolve, reject) {
      AccreditationClient.uploadToken(offeringId)
        .then((response) => {
          resolve(response.accessToken);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  };

  if (isLoading || !vault) {
    return (
      <section className="py-4 accreditation-loading">
        <div className="container">
          <h3>Registering encrypted vault for your investment...</h3>
          <Spinner show />
        </div>
      </section>
    );
  }

  return (
    <SkyflowElements
      config={{
        vaultID: CONFIG.SKYFLOW_VAULT_ID,
        vaultURL: CONFIG.SKYFLOW_VAULT_URL,
        getBearerToken: getBearerToken,
        options: {
          logLevel: LogLevel.WARN,
          env: Env.DEV,
        },
      }}
    >
      <section className="py-2 accreditation">
        <div className="container">
          <Formik
            initialValues={{
              accreditation_method: PRO_LETTER,
            }}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={onSubmit}
          >
            {({ values, errors, handleBlur, handleChange, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <h2 className="text-md-start mt-4 heading">
                  Verify your Accreditation
                </h2>
                <div className="copy mb-6 ">
                  You’re on the final step of the journey! Feel free to come
                  back later to upload your accreditation verification document.
                  You also just received an email with a link to come back to
                  this same page. We’ll be right here.
                </div>
                <h4 className="text-md-start step">
                  1. Select your verification method (*):
                </h4>
                <div className="form-group selection">
                  <div className="form-check mb-2 accreditation-option">
                    <input
                      className="form-check-input me-4 accreditation-radio"
                      name="accreditation_method"
                      type="radio"
                      value={PRO_LETTER}
                      defaultChecked
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <i
                      className="fe fe-help-circle ms-2 tooltip-icon"
                      data-tooltip-id="tooltip-1"
                      data-tooltip-html="Pro letter is a CPA, attorney, registered investment <br/>
                      advisor, or broker-dealer letter on their letterhead, <br/>
                      attesting to your status as an accredited investor based <br/>
                      upon their knowledge of your income or your net worth. <br/>
                      This letter must be dated within the prior 60 days."
                      data-tooltip-place="right"
                    />
                    <Tooltip
                      id="tooltip-1"
                      style={{ zIndex: 1000, maxWidth: "50vw" }}
                    />
                    <label
                      className="form-check-label me-2 accreditation-label"
                      htmlFor="accreditation_method"
                      onChange={handleChange}
                    >
                      (Recommended) Accredited Investor Verification Letter
                    </label>
                  </div>
                  <div className="form-check mb-2 accreditation-option">
                    <input
                      className="form-check-input me-4 accreditation-radio"
                      name="accreditation_method"
                      type="radio"
                      value={INCOME_VERIFICATION}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <i
                      className="fe fe-help-circle ms-2 tooltip-icon"
                      data-tooltip-id="tooltip-2"
                      data-tooltip-html="Income verification documents (such as IRS Forms 1040 or <br/>
                      W-2) for us to review that evidence of your income for the <br/>
                      prior two years."
                      data-tooltip-place="right"
                    />
                    <Tooltip
                      id="tooltip-2"
                      style={{ zIndex: 1000, maxWidth: "50vw" }}
                    />
                    <label
                      className="form-check-label me-2 accreditation-label"
                      htmlFor="accreditation_method"
                    >
                      Income Verification
                    </label>
                  </div>
                  <div className="form-check mb-2 accreditation-option">
                    <input
                      className="form-check-input me-4 accreditation-radio"
                      name="accreditation_method"
                      type="radio"
                      value={ASSET_VERIFICATION}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <i
                      className="fe fe-help-circle ms-2 tooltip-icon"
                      data-tooltip-id="tooltip-3"
                      data-tooltip-html="Asset verification documents (such as bank or brokerage <br/>
                      statements dated within the past 60 days), which we will <br/>
                      review. If you use this method we will request a credit <br/>
                      report from a national credit reporting agency to verify <br/>
                      your debt, obtain a letter from you verifying that no debt <br/>
                      exists other than what appears on the credit report, and <br/>
                      calculate your estimated net worth to assist us in making <br/>
                      a determination."
                      data-tooltip-place="right"
                    />
                    <Tooltip
                      id="tooltip-3"
                      style={{ zIndex: 1000, maxWidth: "50vw" }}
                    />
                    <label
                      className="form-check-label me-2 accreditation-label"
                      htmlFor="accreditation_method"
                    >
                      Asset Verification
                    </label>
                  </div>
                </div>
                <h4 className="text-md-start step">
                  2. Upload your documents:
                </h4>
                <div className="copy mb-6">
                  We keep your files safe and secure in an encrypted data
                  privacy vault that is fully complaint with PCI, GDPR, CCPA and
                  more. For more information on how your personal identifiable
                  information is stored, please visit:{" "}
                  <a
                    href="https://www.skyflow.com/post/what-is-a-data-privacy-vault"
                    target="_blank"
                  >
                    What Is a Data Privacy Vault?
                  </a>
                </div>
                {Array.from({ length: fileCount }).map((_, index) => (
                  <UploadComponent
                    skyflowId={vault.skyflow_id}
                    handleChange={handleChange}
                    fileNum={index + 1}
                    key={index}
                    method={values.accreditation_method}
                  />
                ))}
                <div className="ctas">
                  {fileCount < 5 ? (
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setFileCount(fileCount + 1);
                      }}
                      className="btn btn-eq-primary add-file"
                    >
                      + Add Additional File
                    </button>
                  ) : null}
                  <Continue values={values} />
                </div>
              </form>
            )}
          </Formik>
        </div>
      </section>
    </SkyflowElements>
  );
};

export default OpenOfferingAccreditation;
