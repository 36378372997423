import APIClient from "./api";

class StripeClient {
  /**
  /**
   * @param {string} offeringId
   * @return {Promise<object>}
   */
  static async createPaymentIntent(investmentId) {
    const response = await APIClient().post(
      `/v2/stripe/payment-intent/${investmentId}`
    );
    return response.data;
  }
}

export default StripeClient;
