import { Formik } from "formik";
import React from "react";
import { useParams } from "react-router-dom";
import * as Yup from "yup";

import { ACCREDITATION_PENDING } from "@equidefi/shared/constants/investments";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {
  useCurrentInvestment,
  useUpdateInvestment,
} from "../../hooks/useInvestments";
import { useChangePassword } from "../../hooks/useUsers";
import { CONFIG } from "../../constants/config";

const userSchema = Yup.object().shape({
  password: Yup.string()
    .required("Please enter a password")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%^*#?&])[A-Za-z\d@$!%*^#?&]{8,}$/,
      "Must contain 8 characters, one uppercase, one lowercase, one number and one special character"
    ),
  confirm_password: Yup.string()
    .required("Please confirm your password")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});

const OpenOfferingEnterPassword = () => {
  const { offeringId, investmentId } = useParams();
  const updateInvestment = useUpdateInvestment(investmentId);
  const { data: investment } = useCurrentInvestment();
  const history = useHistory();
  const { mutateAsync, isLoading, isError } = useChangePassword();

  const onSubmit = async (data) => {
    const url = `offering/${offeringId}/accreditation/${investmentId}`;
    try {
      await mutateAsync({
        password: data.password,
        redirect_to: `${CONFIG.URL}${url}`,
        source: "open_offering",
        options: {
          issuer_name: investment.issuer.name,
          investment_name: investment.offering.name,
        },
      });
      toast.success("Your password has been saved!");

      await updateInvestment.mutateAsync({
        status: ACCREDITATION_PENDING,
      });

      history.push(`/${url}`);
    } catch (error) {
      toast.error(error.response.data.errors.join(". "));
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <h2 className="text-md-start mb-0 mt-4">Create a Password</h2>
          <p>
            Let's create a password for your account to access your personal
            investment vault.
          </p>
          <br />
          <Formik
            initialValues={{ password: "", confirm_password: "" }}
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={userSchema}
            onSubmit={onSubmit}
          >
            {({ errors, handleBlur, handleChange, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-sm-12 col-md">
                    <div className="form-group">
                      <label htmlFor="password">
                        Password <span className="text-danger">*</span>
                      </label>
                      <input
                        id="password"
                        name="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="password"
                        className="form-control form-control-sm"
                      />
                      {errors?.password && (
                        <span className="text-danger">{errors?.password}</span>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md">
                    <div className="form-group">
                      <label htmlFor="confirm_password">
                        Confirm Password <span className="text-danger">*</span>
                      </label>
                      <input
                        id="confirm_password"
                        name="confirm_password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="password"
                        className="form-control form-control-sm"
                      />
                      {errors?.confirm_password && (
                        <span className="text-danger">
                          {errors?.confirm_password}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-start align-items-center align-content-center gap-3">
                  <button
                    className="btn btn-sm btn-eq-primary"
                    disabled={isLoading || isError}
                    type="submit"
                  >
                    Continue
                  </button>
                  {isLoading && (
                    <div
                      className="spinner-grow text-secondary spinner-grow-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default OpenOfferingEnterPassword;
