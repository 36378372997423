const AppHeaderNav = (props) => {
  document.title = `${props.name} | Powered By EquiDeFi`;

  return (
    <nav className='header'>
      <div className='container-fluid'>
        <div className='header-body'>
          <h6 className='header-pretitle'>EquiDeFi</h6>
          <h1 className='header-title'>{props.name}</h1>
          {props.children}
        </div>
      </div>
    </nav >
  );
};

export default AppHeaderNav;