import APIClient from "./api";

class AccreditationClient {
  static async uploadToken(offeringId) {
    const response = await APIClient().post("/v2/accreditation/upload-token", {
      offering_id: offeringId,
    });
    return response.data;
  }

  static async getOfferingVault(offeringId) {
    const response = await APIClient().get(
      `/v2/accreditation/vault/offering/${offeringId}`
    );
    return response.data;
  }

  static async updateOfferingVault(data) {
    const response = await APIClient().patch(
      "/v2/accreditation/vault/offering",
      data
    );
    return response.data;
  }
}

export default AccreditationClient;
