import { default as React, useState } from "react";
import { useSelector } from "react-redux";

import Spinner from "../../components/spinner";
import { getInvestReadyUrl, getSettingsByOfferingId } from "../../helpers";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import Countdown from "./../../components/Countdown";
import ProgressBar from "./../../components/ProgressBar";

const InvestorQuestionnaire = (props) => {
  const investment = useSelector((store) => store.investment);
  const offering = useSelector((store) => store.offering);

  const [spinner, showSpinner] = useState(false);

  useDocumentTitle([
    "Investor Questionnaire",
    investment?.offering?.name,
    investment?.issuer?.name,
  ]);

  const startQuestionnaire = () => {
    showSpinner(true);
    if (offering.id) {
      getSettingsByOfferingId(offering.id).then((data) => {
        const index = data.findIndex(
          (x) => x.id === "investor_questionnaire_source"
        );
        const setting = data[index];
        if (setting?.value === "InvestReady") {
          getInvestReadyUrl().then((url) => {
            const investreadyUrl = `${url.url}&state=${investment.id}`;
            window.location = investreadyUrl;
          });
        } else {
          props.history.push("/investor/questionnaire/start");
        }
      });
    } else {
      props.history.push("/investor/questionnaire/start");
    }
  };

  return (
    <>
      <Countdown from={offering.termination_date} />
      <ProgressBar index={1} />

      <section className="mt-4">
        <div className="container">
          <div className="row align-items-center mt-7">
            <div className="col-12 col-md-7 col-lg-6 order-md-2">
              <h2 className="display-4 text-center text-md-start">
                Questionnaire is provided to you by{" "}
                <strong>{investment.issuer && investment.issuer.name}</strong>
              </h2>
              <p>
                The purpose of this questionnaire is to solicit certain
                information regarding your financial status to determine whether
                you are an “accredited investor,” the suitability criteria
                established by the Company for purchasing its securities. This
                questionnaire is not an offer to sell securities.
              </p>
              <Spinner show={spinner}>
                <div className="text-center text-md-start">
                  <button
                    onClick={() => startQuestionnaire()}
                    className="btn btn-eq-primary shadow lift"
                  >
                    Continue <i className="fe fe-arrow-right ms-1"></i>
                  </button>
                </div>
              </Spinner>
            </div>
            <div className="col-12 col-md-5 col-lg-6 order-md-2">
              <div className="d-none d-md-block">
                <img
                  src="/img/questionnaire.svg"
                  className="img-fluid mb-6 mb-md-0 aos-init aos-animate"
                  alt="..."
                  data-aos="fade-up"
                  data-aos-delay="100"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default InvestorQuestionnaire;
