import { useDocumentTitle } from "../hooks/useDocumentTitle";

const AppHeader = (props) => {
  useDocumentTitle(props?.name)

  return (
    <nav className='header'>
      <div className='container-fluid'>
        <div className='header-body'>
          <div className='row align-items-end'>
            <div className='col'>
              <h6 className='header-pretitle'>EquiDeFi</h6>
              <h1 className='header-title'>{props.name}</h1>
            </div>
            <div className='col-auto'>
              {props.children}
            </div>
          </div>
        </div>
      </div>
    </nav >
  );
};

export default AppHeader;
