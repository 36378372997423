import { useQuery } from "@tanstack/react-query";

import GoogleClient from "../../../investor/src/clients/GoogleClient";
import SmartyClient from "../clients/SmartyClient";

export const useAddressSearch = (search, selected = null, options = {}) => {
  return useQuery(
    ["address-search", search, selected],
    async () => {
      const data = await SmartyClient.autocomplete(search, selected);
      return data?.result ?? [];
    },
    {
      refetchOnReconnect: false,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      ...options,
    }
  );
};

export const useGoogleAddressSearch = (search, options = {}) => {
  return useQuery(
    ["places-search", search],
    async () => {
      const data = await GoogleClient.autocomplete(search);
      return data ?? [];
    },
    {
      enabled: !!search,
      refetchOnReconnect: false,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      ...options,
    }
  );
};
