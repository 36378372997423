import React, { useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";

import {
  InvestmentStatusText,
  InvestmentStatusTitle,
  InvestmentStatusView,
} from "../../components/offerings/StatusView";
import Spinner from "../../components/spinner";
import { useInquiry } from "../../hooks/queries/inquiries";
import { useInvestment, useResetInquiry } from "../../hooks/useInvestments";
import { useCurrentInvestor } from "../../hooks/use-current-investor";

const InquiryStatusView = ({ inquiryStatus, investment, isLoading }) => {
  const { offeringId, investmentId } = useParams();
  const history = useHistory();
  const resetInquiry = useResetInquiry();
  if (!inquiryStatus || !investment) {
    return null;
  }

  const callResetInquiry = async () => {
    try {
      await resetInquiry.mutateAsync({
        investmentId,
        inquiryId: investment.persona_inquiry_id,
      });
      history.push(`/offering/${offeringId}/verify/start/${investmentId}`);
    } catch (error) {
      toast.error("Sorry, Something went wrong");
    }
  };

  const kyckyb = investment.entity_type === "ENTITY" ? "KYB" : "KYC";
  const lastStatus = investment.status_history.at(-1);

  if (investment.status === "Investor Name Mismatch") {
    return (
      <InvestmentStatusView
        title="Whoops!"
        iconClass="fe-activity"
        muted
        centered
      >
        {lastStatus.description}
        <br />
        <br />
        <button className="btn btn-eq-primary w-100" onClick={callResetInquiry}>
          Reconfirm Identity
        </button>
      </InvestmentStatusView>
    );
  }

  if (inquiryStatus === "completed") {
    return (
      <InvestmentStatusView
        title="Completed..."
        iconClass="fe-activity"
        muted
        centered
      >
        Your inquiry has been completed, but has not been approved or declined
        yet. Please standby.
      </InvestmentStatusView>
    );
  }

  if (inquiryStatus === "pending") {
    return (
      <>
        <InvestmentStatusTitle>Processing...</InvestmentStatusTitle>
        <InvestmentStatusText centered muted>
          Your inquiry is still processing. Please standby.
        </InvestmentStatusText>
        <Spinner show />
      </>
    );
  }

  if (inquiryStatus === "pending") {
    return (
      <InvestmentStatusView
        title="Completed..."
        iconClass="fe-activity"
        muted
        centered
      >
        Your inquiry has been completed, but has not been approved or declined
        yet. Please standby.
      </InvestmentStatusView>
    );
  }

  if (inquiryStatus === "approved") {
    return (
      <>
        <InvestmentStatusView title="Approved!" iconClass={"fe-check-circle"}>
          Well done! It looks like your {kyckyb} inquiry was approved and you
          can continue on to selecting your investment subscription amounts.
        </InvestmentStatusView>
        <span className="d-block w-100 text-center">
          <Spinner show={isLoading}>
            <Link
              to={`/offering/${offeringId}/subscription/${investmentId}`}
              className="btn btn-sm btn-eq-primary shadow lift me-1"
            >
              Continue <i className="fe fe-arrow-right ms-1"></i>
            </Link>
          </Spinner>
        </span>
      </>
    );
  }

  if (inquiryStatus === "declined") {
    return (
      <InvestmentStatusView title="Sorry!" iconClass="fe-shield-off">
        It looks like your {kyckyb} inquiry was declined. If you believe this to
        be in error, please contact the issuer of the offering.
      </InvestmentStatusView>
    );
  }

  if (inquiryStatus === "needs_review") {
    return (
      <InvestmentStatusView title="Under Review" iconClass="fe-activity">
        It looks like your {kyckyb} verification was automatically marked for
        review. The issuer will review your information. You will receive an
        email when the issuer has accepted or declined your information.
      </InvestmentStatusView>
    );
  }
  return null;
};

const OpenOfferingPersonaInquiryResult = ({ offering }) => {
  const { investmentId } = useParams();

  useCurrentInvestor({ investmentId });

  const { data: investment } = useInvestment(investmentId);
  const { data: inquiry, isLoading } = useInquiry(
    investment?.persona_inquiry_id,
    {
      refetchInterval: 15_000,
      refetchIntervalInBackground: 30_000,
    }
  );

  const inquiryStatus = useMemo(
    () => inquiry?.status ?? "unknown",
    [inquiry?.status]
  );
  const kyckyb = investment?.entity_type === "ENTITY" ? "KYB" : "KYC";

  return (
    <section className="py-4">
      <div className="container">
        <h1 className="fs-2 text-md-start mt-4 mb-6">Your {kyckyb} Results</h1>
        <div className="row">
          <div className="d-none d-md-block col-md-6">
            <img
              src="/img/investor-review.svg"
              className="img-fluid d-block w-100 mx-auto mb-6 mb-md-0 aos-init aos-animate"
              alt="Investor KYC/KYB Review"
              data-aos="fade-up"
              data-aos-delay="100"
            />
          </div>
          <div className="col-12 col-md-6">
            <InquiryStatusView
              inquiryStatus={inquiryStatus}
              investment={investment}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default OpenOfferingPersonaInquiryResult;
