import { useEffect, useState } from "react";

const SECOND = 1000;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;

const Countdown = ({ from }) => {
  const [timer, setTimer] = useState("-");

  useEffect(() => {
    const countDownDate = new Date(from).getTime();
    const i = setInterval(function () {
      const now = new Date().getTime();
      const distance = countDownDate - now;
      const days = Math.floor(distance / DAY);
      const hours = Math.floor((distance % DAY) / HOUR);
      const minutes = Math.floor((distance % HOUR) / MINUTE);
      const seconds = Math.floor((distance % MINUTE) / SECOND);

      setTimer(`${days}d ${hours}h ${minutes}m ${seconds}s`);
    }, 1000);

    return () => clearInterval(i);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container mt-4">
      {timer.length > 0 && (
        <div className="d-flex justify-content-center">
          <div className="alert alert-eq-primary text-center mb-0 p-1 px-6">
            <h6 className="text-uppercase mb-1">Offering terminates in</h6>
            <h3 className="mb-0">{timer}</h3>
          </div>
        </div>
      )}
    </div>
  );
};

export default Countdown;
