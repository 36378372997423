import APIClient from "./api";

class IssuerClient {
  /**
   * @param {string} issuerId
   * @return {Promise<object>}
   */
  static async legacyGet(issuerId) {
    const response = await APIClient().get(`/issuer/${issuerId}`);
    return response.data;
  }
}

export default IssuerClient;
