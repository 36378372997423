import axios from 'axios';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const Welcome = () => {
  const investment = useSelector((store) => store.investment);
  const offering = useSelector((store) => store.offering);

  const [showTos, showTosModal] = useState(investment?.accept_tos ? false : true);

  const updateTos = () => {
    if (!isEmpty(offering)) {
      axios.post(`investment/${investment.id}/tos`).then(() => {
        showTosModal(false);
      });
    } else {
      showTosModal(false)
    }
  }

  return (
    <>
      <div className='container pt-4'>
        <div className='row align-items-center'>
          <div className='col-12 col-md-7 col-lg-6 order-md-2'>
            <h1 className='display-4 text-center text-md-start'>Welcome to EquiDeFi!</h1>
            <p>You’re receiving this information because you indicated an interest in investing in a private placement offering made by <strong>{investment.issuer && investment.issuer.name}</strong></p>
            <p >We’re going to greatly simply your investment experience! Please follow our easy instructions and you’ll be on your way.</p>

            <div className='d-flex'>
              <div className='icon text-primary'>
                <img src='/img/landing/identity.svg' height='24' width='24' alt='identity' />
              </div>
              <div className='ms-5'>
                <p>Identity verification</p>
              </div>
            </div>

            <div className='d-flex'>
              <div className='icon text-primary'>
                <img src='/img/landing/questionnaire.svg' height='24' width='24' alt='questionnaire' />
              </div>
              <div className='ms-5'>
                <p>Accredited investor questionnaire</p>
              </div>
            </div>

            <div className='d-flex'>
              <div className='icon text-primary'>
                <img src='/img/landing/approval.svg' height='24' width='24' alt='approval' />
              </div>
              <div className='ms-5'>
                <p>Company approval</p>
              </div>
            </div>

            <div className='d-flex'>
              <div className='icon text-primary'>
                <img src='/img/landing/documents.svg' height='24' width='24' alt='documents' />
              </div>
              <div className='ms-5'>
                <p>Investment documents</p>
              </div>
            </div>

            <div className='d-flex'>
              <div className='icon text-primary'>
                <img src='/img/landing/bank.svg' height='24' width='24' alt='bank' />
              </div>
              <div className='ms-5'>
                <p>Bank account verification</p>
              </div>
            </div>

            <div className='d-flex'>
              <div className='icon text-primary'>
                <img src='/img/landing/fund.svg' height='24' width='24' alt='funding' />
              </div>
              <div className='ms-5'>
                <p>Funding</p>
              </div>
            </div>
          </div>
          <div className='col-12 col-md-5 col-lg-6 order-md-2'>
            <div className='d-none d-md-block'>
              <img src='/img/landing/welcome_image.svg' className='img-fluid mb-6 mb-md-0 aos-init aos-animate' alt='...' data-aos='fade-up' data-aos-delay='100' />
            </div>
          </div>
        </div>
        <p>After you’ve made your investment, you’ll be enrolled into the EquiDeFi Network™ where you will have unlimited confidential access to all your investment documents and receive guided support to simplify investment in private placements. We strive to make your recordkeeping, payment obligations, and deposits to your brokerage as easy as pie!</p>

        <div className='text-center text-md-start'>
          <Link to='/investor/questionnaire' className='btn btn-eq-primary shadow lift me-1'>
            Continue <i className='fe fe-arrow-right ms-1'></i>
          </Link>
        </div>
      </div>
      <Modal show={showTos} scrollable aria-labelledby="contained-modal-title-vcenter" centered size='xl' >
        <Modal.Body>
          <h2>Non-Disclosure Agreement</h2>
          <ol className='small'>
            <li>I have requested and agree to receive certain information regarding {offering?.issuer?.name} a <span className='text-capitalize'>{investment?.issuer?.address?.state}</span> corporation (the “Company”). Information I receive whether written or oral or whether prepared by the Company or otherwise (the “Information”),may be deemed to be material non-public information. As a condition to my being furnished the Information, I agree to treat the Information as confidential and to take necessary precautions to safeguard and protect the confidentiality of the Information.</li>
            <li>I hereby agree that the Information will not be used for any purpose other than in connection with my evaluating the Company, and that I will not disclose in any manner whatsoever such Information, the fact that I have received such Information or that discussions or negotiations are taking place concerning my interest in the Company or any transaction it may propose</li>
            <li>I am aware that the United States securities laws prohibit any person who has received material, nonpublic information concerning a company from purchasing or selling securities of such company, or from communicating such information to any other person under circumstances in which it is reasonably foreseeable that such person is likely to purchase or sell such securities.  I represent and warrant that I am an “accredited investor” as defined in Rule 501(a) under the Securities Act of 1933, as amended. </li>
            <li>Upon the Company’s request, I shall promptly redeliver to the Company or destroy all written Information (whether prepared by the Company or otherwise), and any oral information provided to me will continue to be subject to the terms of this agreement.</li>
            <li>The Company shall be entitled to specific performance or other equitable relief, including injunction, in the event of any breach or threatened breach of the provisions of this agreement and that I shall not oppose the granting of such relief.  Such remedy shall not be deemed to be the exclusive remedy for a breach of this agreement but shall be in addition to all other remedies at law or in equity.</li>
            <li>Unless and until a definitive agreement with the Company has been executed and delivered, neither the Company nor I will be under any legal obligation of any kind whatsoever with respect to such a transaction by virtue of this agreement, except for the matters specifically agreed to herein, the Company a third-party beneficiary of this agreement.</li>
            <li>By executing this Agreement and accessing EquiDeFi.com (the “Website”) I acknowledge and agree that EquiDeFi, Ltd., a Wyoming corporation, is not offering or selling any securities and merely provides certain services as a software vendor to the Company and is not responsible for the accuracy, adequacy  or completeness of any Information. I agree to hold EquiDeFi, Ltd., and its officers, directors, consultants, owners, employees, and agents, harmless from and against any and all claims involving the Company or my use of the Website or any products or services offered therein that are subscribed or purchased by me or any of my affiliates</li>
          </ol>
          <button className='btn btn-eq-primary shadow lift' onClick={updateTos}>I AGREE</button>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Welcome;