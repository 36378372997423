import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { identify as identifyFullStory } from "../helpers/fullstory";
import { identify as identifyIntercom } from "../helpers/intercom";
import { useRequireUser } from "../hooks/useRequireUser";

const LeftMenu = (props) => {
  const user = useSelector((store) => store.user);

  const logout = useRequireUser({ onMount: false });

  useEffect(() => {
    if (user?.id) {
      identifyFullStory(user);
      identifyIntercom(user);
    }
  }, [user?.id]);

  const doLogout = () => {
    logout({
      type: "success",
      message: "Logged out. Login again to access your Vault.",
    });
  };

  const location = window.localStorage;

  return (
    <>
      <nav
        className="navbar navbar-vertical fixed-start navbar-expand-md navbar-light"
        id="sidebar"
      >
        <div className="container-fluid">
          <button
            className="navbar-toggler marginNav"
            type="button"
            data-toggle="collapse"
            data-target="#sidebarCollapse"
            aria-controls="sidebarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <Link to="/offering" className="navbar-brand">
            <div className="text-center">
              <img src="/img/logo.svg" alt="logo" className="img-fluid" />
            </div>
          </Link>

          <div className="collapse navbar-collapse" id="sidebarCollapse">
            <ul className="navbar-nav mt-2">
              <li className="nav-item">
                <Link
                  to="/app/vault"
                  className={`nav-link ${
                    location.pathname === "/app/vault" ? "active" : ""
                  }`}
                >
                  <i className="fe fe-home"></i> My Vault
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/app/deposit"
                  className={`nav-link ${
                    location.pathname === "/app/deposit" ? "active" : ""
                  }`}
                >
                  <i className="fe fe-archive"></i> Deposit My Shares
                </Link>
              </li>
            </ul>

            <hr className="navbar-divider my-3" />

            <ul className="navbar-nav">
              <li className="nav-item">
                <Link
                  to="/app/profile"
                  className={`nav-link ${
                    location.pathname === "/app/profile" ? "active" : ""
                  }`}
                >
                  <i className="fe fe fe-user"></i> My Profile
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/"
                  className={`nav-link ${
                    location.pathname === "/" ? "active" : ""
                  }`}
                  onClick={doLogout}
                >
                  <i className="fe fe-log-out"></i> Logout
                </Link>
              </li>
            </ul>

            <div className="navbar-user d-none">
              <div className="dropup">
                <a
                  href="#!"
                  className="avatar avatar-sm dropdown-toggle"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <img
                    src={"/img/default-user.png"}
                    alt="..."
                    className="avatar-img rounded-circle"
                  />
                </a>
                <div className="dropdown-menu dropdown-menu-right"></div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default LeftMenu;
