import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";

const InvestorInReview = (props) => {
  const investment = useSelector((store) => store.investment);

  useDocumentTitle([
    "Investor In Review",
    investment?.offering?.name,
    investment?.issuer?.name,
  ]);

  useEffect(() => {
    window.onpopstate = () => {
      window.history.go(1);
    };
  });

  return (
    <>
      <div className="container">
        <div className="row align-items-center vh-100">
          <div className="col-12 col-md-7 col-lg-6 order-md-2">
            <h1 className="display-4 text-center text-md-start">In Review</h1>
            <p className="lead text-muted">
              The Accredited Investor questionnaire has been submitted to{" "}
              <strong>{investment.issuer.name}</strong>. You will receive an
              email upon approval with a link to complete your investment.
            </p>
          </div>
          <div className="col-12 col-md-5 col-lg-6 order-md-2 text-center">
            <img
              src="/img/investor-review.svg"
              className="img-fluid mb-6 mb-md-0 aos-init aos-animate"
              alt="Investor Review Image"
              data-aos="fade-up"
              data-aos-delay="100"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default InvestorInReview;
