import React from 'react';

import items from "../data/status_investment.json";

const InvestmentStatus = ({ status, size }) => {
  let item = items.filter(x => x.name === status)[0];
  let color = item?.color || 'info';

  return (
    <div className={size || 'h3'}>
      <span className={`badge bg-${color}`}>{status}</span>
    </div>
  );
};

export default InvestmentStatus;
