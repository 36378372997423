import { loadStripe } from "@stripe/stripe-js";

import { CONFIG } from "./config";

export const stripePromise = loadStripe(CONFIG.STRIPE_EST_PUBLISHABLE_KEY);

export const stripeAccountPromise = async (stripeAccount) => {
  return loadStripe(CONFIG.STRIPE_EST_PUBLISHABLE_KEY, {
    stripeAccount,
  });
};
