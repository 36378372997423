import axios from "axios";
import FileSaver from "file-saver";
import { useMemo, useState } from "react";
import { Alert } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import InvestmentClient from "../../clients/InvestmentClient";
import AgreementRow from "../../components/offerings/AgreementRow";
import SigningPartyForm from "../../components/offerings/SigningPartyForm";
import { useInvestment, useUpdateInvestment } from "../../hooks/useInvestments";
import { useOpenOffering } from "../../hooks/queries/offerings";
import { useCurrentInvestor } from "../../hooks/use-current-investor";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import Spinner from "./../../components/spinner";

const OpenOfferingAgreements = (props) => {
  const history = useHistory();
  const { investmentId, offeringId } = useParams();

  useCurrentInvestor({ investmentId });

  const investmentQuery = useInvestment(investmentId);
  const investmentUpdate = useUpdateInvestment(investmentId);
  const offeringQuery = useOpenOffering(offeringId);

  const investment = investmentQuery.data;
  const offering = offeringQuery.data;

  const [spinner, showSpinner] = useState(false);

  useDocumentTitle([
    "Subscription Agreement",
    investment?.offering?.name,
    investment?.issuer?.name,
  ]);

  const investmentAgreementByTemplateId = (invst, templateId) => {
    return invst?.agreements?.find((x) => x.template_id === templateId);
  };

  const getOrSetupInvestmentAgreement = async (investment, templateId) => {
    let agreement = investmentAgreementByTemplateId(investment, templateId);
    if (!agreement) {
      await InvestmentClient.legacyCreateEnvelope(investment.id, templateId);
      const { data: invst } = await investmentQuery.refetch();
      agreement = investmentAgreementByTemplateId(invst, templateId);
    }
    return agreement;
  };

  const canContinue = useMemo(() => {
    const totalAgreements = offering?.agreements.length;
    const signedAgreements =
      offering?.agreements.filter((oAgreement) => {
        const iAgreement = investmentAgreementByTemplateId(
          investment,
          oAgreement.template_id
        );
        return iAgreement?.investor_status.toLowerCase() === "complete";
      }).length ?? 0;

    return totalAgreements === signedAgreements;
  }, [investment, offering]);

  const handleDownload = async (templateId) => {
    showSpinner(true);

    try {
      const agreement = await getOrSetupInvestmentAgreement(
        investment,
        templateId
      );

      let response = await axios.get(
        `investment/${investment.id}/agreement/${agreement.envelope_id}`
      );
      let toBytes = new Uint8Array(response.data.data),
        toBinary = ``;
      toBytes.forEach((res) => (toBinary += String.fromCharCode(res)));

      const file = window.btoa(toBinary);
      FileSaver.saveAs(
        `data:application/pdf;base64,${file}`,
        `${agreement.name.replace(/[^a-zA-Z ]/g, "")}`
      );
    } catch (e) {
      toast.error("Sorry, that document could not be downloaded.");
    } finally {
      showSpinner(false);
    }
  };

  const handleSigningPartyUpdate = async (data) => {
    try {
      await investmentUpdate.mutateAsync(data);
      toast.success("Successfully updated signing party information.");
    } catch (error) {
      toast.error("Sorry, we could not save the signing party information.");
    } finally {
      await investmentQuery.refetch();
    }
  };

  const handleSignature = async (templateId) => {
    showSpinner(true);

    const agreement = await getOrSetupInvestmentAgreement(
      investment,
      templateId
    );

    const response = await InvestmentClient.getAgreementDocusignUrl(
      investmentId,
      agreement.envelope_id,
      "investor"
    );

    window.location = response.url;
  };

  const goToPayment = async () => {
    showSpinner(true);
    await InvestmentClient.legacyUpdateStatus(
      investment.id,
      "agreement_signed"
    );
    history.push(`/offering/${offeringId}/payment/${investmentId}`);
  };

  const entityHasSigningParty = useMemo(() => {
    return investment?.entity_type?.toUpperCase() === "ENTITY"
      ? investment?.signing_party_title
      : true;
  }, [
    investment?.entity_type,
    investment?.signing_party_name,
    investment?.signing_party_title,
  ]);

  if (!investment && !offering) {
    return <Spinner show />;
  }

  return (
    <>
      <section className="py-4">
        <div className="container">
          <h2 className="text-md-start mb-0 mt-4">Subscription Agreement</h2>
          <p className="text-muted">
            Execution of the agreement does not indicate the transaction is
            closed. Your funds and transaction documents will be secured in
            escrow and you will be notified when the transaction closes. Please
            download the agreement for reference or continue to signing.
          </p>

          <Spinner show={spinner}>
            {!entityHasSigningParty && (
              <div className="my-4">
                <Alert variant="warning">
                  In order to sign the following agreements for the offering,
                  you must specify your Entity&apos;s signing party and title.
                </Alert>
                <SigningPartyForm
                  investment={investmentQuery.data}
                  isLoading={investmentQuery.isLoading}
                  onSubmit={handleSigningPartyUpdate}
                />
              </div>
            )}
            <div className="list-group mt-4">
              {offering?.agreements?.map((agreement) => (
                <AgreementRow
                  key={agreement.template_id}
                  investment={investment}
                  agreement={agreement}
                  isDisabled={!entityHasSigningParty}
                  onDownload={(templateId) => handleDownload(templateId)}
                  onSign={(templateId) => handleSignature(templateId)}
                />
              ))}
            </div>

            {canContinue && (
              <div className="d-flex justify-content-start text-center text-md-start mt-4">
                <button
                  onClick={() => goToPayment()}
                  className="btn btn-sm btn-eq-primary shadow lift"
                >
                  Continue <i className="fe fe-arrow-right-circle ms-1"></i>
                </button>
              </div>
            )}
          </Spinner>
        </div>
      </section>
    </>
  );
};

export default OpenOfferingAgreements;
