import axios from "axios";
import { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import { Formik } from "formik";
import * as Yup from "yup";

import Spinner from "../../components/spinner";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";

const Forgot = (props) => {
  useDocumentTitle("Forgot Password");

  const formData = {
    email: "",
  };

  const formSchema = Yup.object().shape({
    email: Yup.string()
      .email("Please enter a valid email")
      .required("Required."),
  });

  const [spinner, showSpinner] = useState(false);

  const onSubmit = ({ email }) => {
    showSpinner(true);
    axios
      .post("auth/reset", {
        email: email.toLowerCase().trim(),
        source: "investor",
      })
      .then((response) => {
        toast.info(
          `An email as been sent to ${email} with instructions to reset your password.`
        );
        props.history.push("/");
      })
      .catch((error) => {
        toast.info(`An error occured while trying to process your request.`);
        showSpinner(false);
      });
  };

  return (
    <div className="container-fluid">
      <div className="row vh-100 justify-content-center">
        <div className="col-12 col-md-5 col-lg-6 col-xl-4 px-lg-6 my-5 align-self-center">
          <img
            src="/img/logo.svg"
            width="150px"
            alt="logo"
            className="d-block m-auto mb-5"
          />
          <h1 className="text-center mb-3">Forgot Password?</h1>
          <p className="text-muted text-center mb-5">
            Enter your email to get a password reset link.
          </p>

          <Formik
            initialValues={formData}
            validationSchema={formSchema}
            onSubmit={onSubmit}
          >
            {({
              handleChange,
              handleSubmit,
              values,
              errors,
              touched,
              handleBlur,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                <div className="form-group">
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    placeholder="name@address.com"
                    value={values.email}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {errors.email && touched.email ? (
                    <div className="text-danger mt-2 ms-1 h5">
                      {errors.email}
                    </div>
                  ) : null}
                </div>
                <Spinner show={spinner}>
                  <button type="submit" className="btn w-100 btn-eq-primary">
                    Continue
                  </button>
                </Spinner>
                <div className="text-center mt-3">
                  <small className="text-muted text-center">
                    Remember your password? <Link to="/">Log in</Link>.
                  </small>
                </div>
              </form>
            )}
          </Formik>
        </div>
        <div className="col-12 col-md-7 col-lg-6 col-xl-8 d-none d-lg-block">
          <div
            className="bg-cover h-100 min-vh-100 mt-n1 me-n3"
            style={{ backgroundImage: "url(/img/login.jpg)" }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default Forgot;
