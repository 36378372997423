import clsx from "clsx";
import React from "react";

export const InvestmentStatusTitle = ({ children, iconClass = null }) => {
  const titleClasses = clsx({
    "d-inline-flex justify-content-center w-100 align-items-center align-content-center text-center gap-2":
      iconClass,
    "text-center": !iconClass,
  });

  return (
    <h2 className={titleClasses}>
      <i style={{ color: "var(--bs-indigo" }} className={`fe ${iconClass}`}></i>
      <span>{children}</span>
    </h2>
  );
};

export const InvestmentStatusText = ({
  children,
  muted = false,
  centered = false,
}) => {
  const classNames = clsx({
    "text-muted": muted,
    "text-center": centered,
  });

  return <p className={classNames}>{children}</p>;
};

export const InvestmentStatusView = ({
  title,
  iconClass = null,
  muted = false,
  centered = false,
  children,
}) => {
  return (
    <>
      <InvestmentStatusTitle iconClass={iconClass}>
        {title}
      </InvestmentStatusTitle>
      <InvestmentStatusText muted={muted} centered={centered}>
        {children}
      </InvestmentStatusText>
    </>
  );
};
