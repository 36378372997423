import React, { useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import InvestmentClient from "../../clients/InvestmentClient";

const OpenOfferingPaymentProcessing = ({ isLoading = false, error = null }) => {
  const { offeringId, investmentId } = useParams();
  const history = useHistory();
  useEffect(() => {
    (async () => {
      await InvestmentClient.legacyUpdateStatus(investmentId, "enter_password");
      history.push(`/offering/${offeringId}/accreditation/${investmentId}`);
    })();
  }, [history, investmentId, offeringId]);

  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <h2 className="text-md-start mb-0 mt-4">Payment Complete!</h2>
          <h5>Redirecting to next page...</h5>
        </div>
      </div>
    </div>
  );
};

export default OpenOfferingPaymentProcessing;
