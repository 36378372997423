import { useQuery } from "@tanstack/react-query";
import React, { useMemo, useState } from "react";
import Select from "react-select";

import SmartyClient from "../clients/SmartyClient";
import useDebounce from "../hooks/use-debounce";

const smartyAddressFormat = (option) => {
  const set = new Set();
  set.add(option.streetLine);
  if (option.secondary) set.add(option.secondary);
  if (option.entries) set.add(`(${option.entries})`);
  set.add(option.city);
  set.add(option.state);
  set.add(option.zipcode);

  return [...set].join(" ");
};

const addressFormat = (option) => {
  const set = new Set();
  set.add(option.streetLine);
  if (option.secondary) set.add(`${option.secondary},`);
  if (option.entries && option.entries > 1) set.add(`(${option.entries})`);
  set.add(`${option.city}, ${option.state}`);
  set.add(option.zipcode);

  return [...set].join(" ");
};

const useAddressSearch = (search, selected = null, options = {}) => {
  return useQuery(
    ["address-search", search, selected],
    async () => {
      const data = await SmartyClient.autocomplete(search, selected);
      return data?.result ?? [];
    },
    {
      refetchOnReconnect: false,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      ...options,
    }
  );
};

const AddressSearch = ({
  placeholder = "Search US Address...",
  autoFocus = false,
  onSelect = () => {},
}) => {
  const [inputSearchValue, setInputSearchValue] = useState("");
  const searchVal = useDebounce(inputSearchValue, 500);

  const handleOnSelect = (option) => {
    onSelect(option);
  };

  const {
    data: results,
    status,
    fetchStatus,
  } = useAddressSearch(searchVal, null, { enabled: Boolean(searchVal) });

  const isLoading = useMemo(
    () =>
      (status === "loading" || fetchStatus === "fetching") &&
      inputSearchValue.length > 0,
    [inputSearchValue, status, fetchStatus]
  );

  const options = useMemo(() => {
    return (
      results?.map((option) => ({
        ...option,
        label: addressFormat(option),
        value: smartyAddressFormat(option),
      })) ?? []
    );
  }, [results]);

  return (
    <>
      <Select
        autoFocus={autoFocus}
        placeholder={placeholder}
        noOptionsMessage={() => "No addresses found"}
        filterOption={null}
        value={inputSearchValue}
        onInputChange={(value) => {
          setInputSearchValue(value);
        }}
        onChange={handleOnSelect}
        isLoading={isLoading}
        options={options}
        components={{
          IndicatorSeparator: null,
        }}
      />
    </>
  );
};

export default AddressSearch;
