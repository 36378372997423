import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import NumberFormat from "react-number-format";
import { useSelector } from "react-redux";

import AddressSearch from "../../../components/address-search";
import Spinner from "../../../components/spinner";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";

const Questionnaire = (props) => {
  const investor = useSelector((store) => store.investor);

  useDocumentTitle([
    "Investor Questionnaire",
    investor?.user_details?.name,
  ]);

  const [questionnaire, setQuestionnaire] = useState();
  const [spinner, showSpinner] = useState(false);
  const [index, setIndex] = useState(0);
  const [question_response, setResponse] = useState();
  const [section, setSection] = useState(questionnaire && questionnaire[0]);

  let optionValue = question_response
    ? question_response[section?.questions[0]?.id]
    : "";

  useEffect(() => {
    axios.get(`/investor/${investor.id}/questionnaire`).then((response) => {
      setQuestionnaire(response.data);
      setSection(response.data[0]);

      setResponse({
        ...question_response,
        entity_individual: question_response?.entity_individual || "",
        investor_name: investor.user_details.name || "",
        signing_party_name: investor.user_details.name || "",
        signing_party_title: investor.user_details.title || "",
        email: investor.user_details.email || "",
        phone_number: investor.user_details.phone || "",
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let exclusions = [];
    if (question_response?.entity_individual === "individual") {
      exclusions = ["ein", "signing_party_name", "signing_party_title"];
    } else {
      exclusions = ["ssn"];
    }

    setSection({
      ...section,
      questions: section?.questions?.filter(
        (item) => !exclusions?.includes(item.id)
      ),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index, optionValue]);

  const handleCheckChange = ({ target: { value, checked } }) => {
    question_response[value] = checked;
    setResponse((prev) => ({ ...prev, [value]: checked }));
  };

  const handleAddressChange = (address) => {
    if (!address) {
      setResponse((prev) => ({
        ...prev,
        address_line_1: null,
        address_city: null,
        address_state: null,
        address_postal_code: null,
      }));
      return;
    }

    setResponse((prev) => ({
      ...prev,
      address_line_1: `${address.streetLine} ${address.secondary}`.trim(),
      address_city: address.city,
      address_state: address.state,
      address_postal_code: address.zipcode,
    }));
  };

  const formatSocialSecurity = (val) => {
    // SSN formatter
    val = val.replace(/\D/g, "");
    val = val.replace(/^(\d{3})/, "$1-");
    val = val.replace(/-(\d{2})/, "-$1-");
    val = val.replace(/(\d)-(\d{4}).*/, "$1-$2");
    return val;
  };

  const formatPhoneNumber = (str) => {
    //format phone number according US
    //Filter only numbers from the input
    let cleaned = ("+1" + str).replace(/\D/g, "");
    //Check if the input is of correct
    let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      //Remove the matched extension code
      //Change this to format for any country code.
      let intlCode = match[1] ? "+1 " : "";
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    return null;
  };

  const formatTaxId = (val) => {
    // format taxId
    val = val.replace(/\D/g, "");
    val = val.replace(/^(\d{2})/, "$1-");
    val = val.replace(/(\d)-(\d{8}).*/, "$1-$2");
    return val;
  };

  const handleInputChange = ({ target: { name, value } }) => {
    if (name === "ssn") {
      const ssn = formatSocialSecurity(value);
      setResponse({ ...question_response, [name]: ssn });
    } else if (name === "phone_number") {
      const phone = formatPhoneNumber(value);
      setResponse({ ...question_response, [name]: phone });
    } else if (name === "ein") {
      const taxid = formatTaxId(value);
      setResponse({ ...question_response, [name]: taxid });
    } else {
      question_response[name] = value;
      setResponse({ ...question_response, [name]: value });
    }
  };

  const handleYesChange = ({ target: { name, value } }) => {
    question_response[name.concat("_reason")] = value;
    setResponse({ ...question_response, [name.concat("_reason")]: value });
  };

  const handleSubmit = () => {
    showSpinner(true);
    if (question_response?.entity_individual === "entity") {
      question_response["individual_options"] = "";
      question_response["ssn"] = "";
    } else if (question_response?.entity_individual === "individual") {
      question_response["entity_options"] = "";
      question_response["ein"] = "";
      question_response["signing_party_name"] = "";
      question_response["signing_party_title"] = "";
    }

    axios
      .post(`investor/${investor.id}/question_response`, question_response)
      .then(() => {
        axios.post(`investor/${investor.id}/status/submitted`).then(() => {
          props.history.push("/complete-profile");
        });
      });
  };

  const nextSection = () => {
    let nextIndex = index;
    if (isSectionValid) {
      nextIndex = index < questionnaire.length - 1 ? index + 1 : index;

      if (
        questionnaire[nextIndex]?.id === "section_2" &&
        question_response?.entity_individual === "entity"
      ) {
        nextIndex = nextIndex + 1;
      }
      if (
        questionnaire[nextIndex]?.id === "section_3" &&
        question_response?.entity_individual === "individual"
      )
        nextIndex = nextIndex + 1;
      setIndex(nextIndex);
      setSection(questionnaire[nextIndex]);
    }
  };

  const prevSection = () => {
    let nextIndex = index;
    nextIndex = index > 0 ? index - 1 : index;

    if (
      questionnaire[nextIndex]?.id === "section_2" &&
      question_response?.entity_individual === "entity"
    )
      nextIndex = nextIndex - 1;
    if (
      questionnaire[nextIndex]?.id === "section_3" &&
      question_response?.entity_individual === "individual"
    )
      nextIndex = nextIndex - 1;
    setIndex(nextIndex);
    setSection(questionnaire[nextIndex]);
  };

  const isSectionValid = useMemo(() => {
    let isValid = true;
    section?.questions?.forEach((question) => {
      if (
        question?.required &&
        question_response &&
        !question_response[question?.id]
      ) {
        isValid = false;
      }
      if (
        optionValue === "yes" &&
        !question_response[question?.id.concat("_reason")]
      ) {
        isValid = false;
      }
    });
    return isValid;
  }, [optionValue, question_response, section?.questions]);

  return (
    <>
      {index === 5 ? (
        <>
          <div className="container-fluid vh-100 d-flex justify-content-center align-items-center">
            <div>
              <div className="">
                <div className="text-center">
                  <h2 className="mb-1">{section.title}</h2>
                </div>
                <div className="img-fluid d-flex justify-content-center">
                  <img
                    src="/img/almostFinished.png"
                    alt="Almost Done"
                    width="70%"
                  />
                </div>
              </div>
              <div className="text-center my-5">
                <Spinner show={spinner}>
                  {index !== 0 && (
                    <button
                      className="btn btn-sm btn-primary-soft mx-1"
                      onClick={prevSection}
                    >
                      Previous
                    </button>
                  )}
                  {index === questionnaire?.length - 1 ? (
                    <button
                      type="button"
                      className="btn btn-sm btn-eq-primary mx-1"
                      onClick={handleSubmit}
                      disabled={!isSectionValid}
                    >
                      Continue <i className="fe fe-arrow-right ms-1" />
                    </button>
                  ) : (
                    <button
                      className="btn btn-sm btn-primary-soft mx-1"
                      onClick={nextSection}
                      disabled={!isSectionValid}
                    >
                      Next
                    </button>
                  )}
                </Spinner>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <section className="background">
            <div className="container py-5 ">
              <div>
                <h2 className="mb-1">{section?.title} </h2>
                <h3 className="mb-1">{section?.subtitle}</h3>
                <div dangerouslySetInnerHTML={{ __html: section?.text }}></div>
                {section?.questions?.map((question) => {
                  return (
                    <div key={question.id}>
                      {question.type !== "hidden" && (
                        <div className="mt-5">
                          {question.type === "text" &&
                          question.id === "investor_name" &&
                          question_response?.entity_individual === "entity"
                            ? "Name of Investor"
                            : question.text}
                          {question.required && (
                            <span className="ms-1 text-danger">*</span>
                          )}
                        </div>
                      )}
                      {question.type === "hidden" && (
                        <input
                          type="hidden"
                          name={question.id}
                          id={question.id}
                          value={question_response[question.id]}
                        />
                      )}
                      {question.type === "radio" &&
                        question.options?.map((option) => {
                          return (
                            <div className="" key={option.id}>
                              <div className="form-check mb-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name={question.id}
                                  id={option.id}
                                  value={option.value}
                                  onChange={handleInputChange}
                                  checked={option.value === optionValue}
                                />
                                <label
                                  className="form-check-label ms-2"
                                  htmlFor={option.id}
                                >
                                  {option.text}
                                </label>
                              </div>
                            </div>
                          );
                        })}
                      {question.id.includes("bad_actor_") &&
                        optionValue &&
                        optionValue === "yes" && (
                          <input
                            type="text"
                            name={question?.id}
                            id={question?.id}
                            className="form-control"
                            onChange={handleYesChange}
                            placeholder="Reason"
                          />
                        )}
                      {question.type === "checkbox" &&
                        question.options?.map((option) => {
                          return (
                            <div className="" key={option.id}>
                              <div className="form-check mb-2">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name={question.id}
                                  id={option.id}
                                  value={option.value}
                                  onChange={handleCheckChange}
                                />
                                <label
                                  className="form-check-label ms-2"
                                  htmlFor={option.id}
                                >
                                  {option.text}
                                </label>
                              </div>
                            </div>
                          );
                        })}
                      {question.type === "text" && (
                        <input
                          type="text"
                          name={question.id}
                          id={question.id}
                          className="form-control"
                          defaultValue={question_response[question.id]}
                          onChange={handleInputChange}
                        />
                      )}
                      {question.type === "email" && (
                        <input
                          type="email"
                          disabled
                          name={question.id}
                          id={question.id}
                          className="form-control disabledInput"
                          defaultValue={question_response[question.id] || ""}
                          onChange={handleInputChange}
                        />
                      )}
                      {question.type === "address" && (
                        <>
                          <AddressSearch
                            placeholder="Search for address"
                            autoFocus
                            onSelect={handleAddressChange}
                          />
                          {question_response.address_line_1 && (
                            <div className="card shadow mt-4">
                              <div className="card-body p-5">
                                <h5 className="card-title fw-bolder mb-3">
                                  Selected Address
                                </h5>
                                <address className="mb-0">
                                  {question_response.address_line_1}
                                  <br />
                                  {question_response.address_city},{" "}
                                  {question_response.address_state}{" "}
                                  {question_response.address_postal_code}
                                </address>
                              </div>
                            </div>
                          )}
                        </>
                      )}
                      {question.type === "phone" && (
                        <NumberFormat
                          className="form-control"
                          placeholder="eg: +1 (444) 444-4444"
                          format="+1 (###) ###-####"
                          mask="_"
                          defaultValue={question_response.phone_number || ""}
                          onValueChange={({ value }) => {
                            handleInputChange({
                              target: { name: question.id, value: value },
                            });
                          }}
                        />
                      )}
                      {question.type === "ssn" && (
                        <NumberFormat
                          className="form-control"
                          format="###-##-####"
                          mask="_"
                          defaultValue={question_response[question.id] || ""}
                          onValueChange={({ value }) => {
                            handleInputChange({
                              target: { name: question.id, value: value },
                            });
                          }}
                        />
                      )}
                      {question.type === "ein" && (
                        <NumberFormat
                          className="form-control"
                          format="##-#######"
                          mask="_"
                          defaultValue={question_response[question.id] || ""}
                          onValueChange={({ value }) => {
                            handleInputChange({
                              target: { name: question.id, value: value },
                            });
                          }}
                        />
                      )}
                      {question.type === "zip" && (
                        <NumberFormat
                          className="form-control"
                          format="#####"
                          mask="_"
                          defaultValue={question_response[question.id] || ""}
                          onValueChange={({ value }) => {
                            handleInputChange({
                              target: { name: question.id, value: value },
                            });
                          }}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
              <div className="text-center my-5">
                <Spinner show={spinner}>
                  {index !== 0 && (
                    <button
                      className="btn btn-sm btn-primary-soft mx-1"
                      onClick={prevSection}
                    >
                      Previous
                    </button>
                  )}
                  {index === questionnaire?.length - 1 ? (
                    <button
                      type="button"
                      className="btn btn-sm btn-eq-primary mx-1"
                      onClick={handleSubmit}
                      disabled={!isSectionValid}
                    >
                      Continue <i className="fe fe-arrow-right ms-1" />
                    </button>
                  ) : (
                    <button
                      className="btn btn-sm btn-primary-soft mx-1"
                      onClick={nextSection}
                      disabled={!isSectionValid}
                    >
                      Next
                    </button>
                  )}
                </Spinner>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default Questionnaire;
