const DocumentReviewDownloadCard = ({ documents = [] }) => {
  return (
    <div className="card my-4 shadow-sm">
      <div className="card-header">
        <h3 className="mb-2">Documents</h3>
        <div className="eq-badge px-6">
          <span className="h6 text-uppercase fw-bold">
            Please read through all the documents carefully before you continue.
          </span>
        </div>
      </div>
      <div className="card-body">
        <div className="list-group">
          {documents?.map((document, index) => (
            <div className="list-group-item p-4" key={index}>
              <div className="row">
                <div className="col">{document.type}</div>
                <div className="col-auto">
                  <a
                    href={document.document_url}
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-xs btn-info-soft"
                  >
                    Download <i className="fe fe-download ms-1" />
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DocumentReviewDownloadCard;
