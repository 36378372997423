import { offeringIsCrowdfunded } from "@equidefi/shared";
import { Link } from "react-router-dom";
import {
  investmentContinueStepRegD,
  investmentContinueUrlOpenOffering,
} from "../../helpers/investment";

const Continue = ({ investment, offering }) => {
  let continueUrl;
  if (!investment || !offering || !investment.status) {
    return null;
  }

  if (offeringIsCrowdfunded(offering)) {
    continueUrl = investmentContinueUrlOpenOffering(
      investment.id,
      investment.status,
      offering.id
    );
  } else {
    continueUrl = investmentContinueStepRegD(investment.status, "/app/vault");
  }

  if (!continueUrl) {
    return null;
  }

  localStorage.removeItem("eq-investor");

  return (
    <Link to={continueUrl} className="btn btn-eq-primary">
      Continue
    </Link>
  );
};

export default Continue;
