import { produce } from "immer";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import DisqualifyingEventQuestionnaire from "../../../components/offerings/DisqualifyingEventQuestionnaire";
import EntityAccreditationCertification from "../../../components/offerings/EntityAccreditationCertification";
import IndividualAccreditationCertification from "../../../components/offerings/IndividualAccreditationCertification";
import Spinner from "../../../components/spinner";
import { useCurrentUser } from "../../../hooks/useUsers";
import { useCurrentInvestor } from "../../../hooks/use-current-investor";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";
import {
  useInvestment,
  useInvestmentUpdateStage,
} from "../../../hooks/useInvestments";

const Questionnaire506c = ({ offering }) => {
  const user = useSelector((store) => store.user);
  const history = useHistory();
  const { investmentId } = useParams();

  const investmentQuery = useInvestment(investmentId);

  const [stage, setStage] = useState(() => "accreditation");
  const [responses, setResponses] = useState({});

  const setResponseDraft = useCallback((cb) => {
    setResponses((prev) => produce(prev, cb));
  }, []);

  const investment = investmentQuery.data;

  useCurrentInvestor({ investmentId });
  useDocumentTitle([
    "Investor Questionnaire",
    investment?.offering?.name,
    investment?.issuer?.name,
  ]);

  const { data: me } = useCurrentUser();

  const [question_response, setResponse] = useState();

  const updateInvestment = useInvestmentUpdateStage(
    investmentId,
    "accreditation"
  );

  const onSubmit = useCallback(
    async (data) => {
      try {
        await updateInvestment.mutateAsync({
          data: {
            accredited: true,
            accreditation_type: responses.accreditation_type,
            bad_actor: responses.bad_actor,
          },
        });
        const { data: updated } = await investmentQuery.refetch();
        if (updated.status !== "Accreditation Rejected") {
          history.push(
            `/offering/${offering?.id}/verify/start/${investmentId}`
          );
        }
      } catch (error) {
        toast.error("Sorry, something went wrong");
      }
    },
    [responses, history, offering?.id, investmentId, updateInvestment]
  );

  useEffect(() => {
    setResponse({
      ...question_response,
      entity_individual: question_response?.entity_individual || "",
      investor_name: user.name || "",
      signing_party_name: user.name || "",
      signing_party_title: user.title || "",
      email: user.email || "",
      phone_number: user.phone || "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!offering || !investment || !me) {
    return <Spinner show />;
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col">
          <EntityAccreditationCertification
            user={me}
            stage={stage}
            onContinue={(values) => {
              setResponseDraft((draft) => {
                draft.accreditation_type = values.accreditation_type;
              });
              setStage("disqualifying");
            }}
          />

          <IndividualAccreditationCertification
            user={me}
            stage={stage}
            onContinue={(values) => {
              setResponseDraft((draft) => {
                draft.accreditation_type = values.accreditation_type;
              });
              setStage("disqualifying");
            }}
          />

          <DisqualifyingEventQuestionnaire
            user={me}
            stage={stage}
            issuerName={investment?.issuer?.name}
            onChange={(values) => {
              setResponseDraft((draft) => {
                draft.bad_actor = values.bad_actor;
              });
            }}
            onContinue={onSubmit}
          />
        </div>
      </div>
    </div>
  );
};

export default Questionnaire506c;
