import clsx from "clsx";
import moment from "moment";
import React from "react";

import NumberFormat from "react-number-format";

const PAYMENT_STATUS_DISPLAY = {
  created: "Payment Pending",
  processing: "Payment Processing",
  succeeded: "Payment Successful",
  manual_payment_succeeded: "Manual Payment",
  manual_refund_succeeded: "Manual Refund",
  payment_failed: "Payment Failed",
};

const PaymentStatus = ({ status, Tag = "span" }) => {
  const className = clsx("badge", {
    "bg-warning": ["created", "processing"].includes(status),
    "bg-success": [
      "succeeded",
      "manual_payment_succeeded",
      "manual_refund_succeeded",
    ].includes(status),
    "bg-danger": ["payment_failed"].includes(status),
  });

  const text = PAYMENT_STATUS_DISPLAY[status];

  if (!text) {
    return null;
  }

  return <Tag className={className}>{text}</Tag>;
};

const PaymentsCard = ({ payments, stripePayments }) => {
  return (
    <div className="card">
      <div className="card-header">
        <h4 className="card-header-title">Payments</h4>
      </div>
      <table className="table table-hover">
        <thead>
          <tr>
            <th>Date</th>
            <th className="text-end">Amount</th>
            <th className="text-center">Status</th>
          </tr>
        </thead>
        <tbody>
          {[...(payments ?? []), ...(stripePayments ?? [])]?.map(
            (payment, index) => {
              return (
                <tr key={index}>
                  <td>
                    {payment.create_date &&
                      moment(payment.create_date).format("lll Z")}
                  </td>
                  <td className="text-end">
                    <NumberFormat
                      value={payment.amount}
                      displayType="text"
                      thousandSeparator={true}
                      prefix="$"
                    />
                  </td>
                  <td className="text-center">
                    <PaymentStatus status={payment.status} />
                  </td>
                </tr>
              );
            }
          )}
        </tbody>
      </table>
    </div>
  );
};

export default PaymentsCard;
