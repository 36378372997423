import { once } from "lodash";

const APP_ID = "hyz6oa8h";

export const initialize = once(() => {
  window.Intercom("boot", {
    app_id: APP_ID,
  });
});

export const identify = once((user) => {
  window.Intercom("boot", {
    app_id: APP_ID,
    email: user.email,
    user_id: user.id,
    created_at: new Date().getTime(),
  });
});
