import SmartySDK from "smartystreets-javascript-sdk";
import { CONFIG } from "../constants/config";

const SmartyCore = SmartySDK.core;
const LookupAutocomplete = SmartySDK.usAutocompletePro.Lookup;

const credentials = new SmartyCore.SharedCredentials(
  CONFIG.SMARTY_EMBEDDED_KEY
);
const autocompleteClientBuilder = new SmartyCore.ClientBuilder(
  credentials
).withLicenses(["us-autocomplete-pro-cloud"]);

const autocompleteClient =
  autocompleteClientBuilder.buildUsAutocompleteProClient();

const autocomplete = async (value, selected = null) => {
  let lookup = new LookupAutocomplete(value);
  lookup.maxResults = 8;

  if (selected) {
    lookup.selected = selected;
  }

  const result = await autocompleteClient.send(lookup);
  return result;
};

const SmartyClient = {
  autocomplete,
};

export default SmartyClient;
