import React from 'react';

const PublicLayout = (props) => {
  const theme = document.getElementById('theme');
  theme.href = '/css/app.css';

  const body = document.getElementById('body');
  body.className = 'bg-light';
  
  return <div>{props.children}</div>;
};

export default PublicLayout;