import React, { useState } from "react";
import { useParams } from "react-router-dom";

import { Formik } from "formik";
import Select from "react-select";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useGoogleAddressSearch } from "../../../../issuer/src/hooks/useAddressSearch";
import GoogleClient from "../../clients/GoogleClient";
import InquiryClient from "../../clients/InquiryClient";
import { useUpdateCurrentUser } from "../../hooks/useUsers";
import useDebounce from "../../hooks/use-debounce";
import "./style.css";

const addressSchema = Yup.object().shape({
  address_street_1: Yup.string().required("Please enter your street address"),
  address_street_2: Yup.string().nullable(),
  address_city: Yup.string().required("Please enter your city"),
  address_state: Yup.string().required("Please enter your state"),
  address_postal_code: Yup.string().required("Please enter your postal code"),
  address_country: Yup.string().required("Please enter your country"),
});

const GooglePlaceSearch = ({ onSelected }) => {
  const [inputSearchValue, setInputSearchValue] = useState("");
  const searchVal = useDebounce(inputSearchValue, 500);
  const { data: options, isFetching } = useGoogleAddressSearch(searchVal);

  const handleOnSelect = async (option) => {
    const data = await GoogleClient.place(option.value);
    onSelected(data);
  };

  return (
    <div className="address border-bottom border-gray-300">
      <Select
        autoFocus={false}
        placeholder={"Search any address (including international)"}
        noOptionsMessage={() => "No addresses found"}
        filterOption={null}
        onInputChange={(value) => {
          setInputSearchValue(value);
          return value;
        }}
        onChange={handleOnSelect}
        isLoading={isFetching}
        options={options}
        components={{
          IndicatorSeparator: null,
        }}
      />
    </div>
  );
};

const AddressComponent = ({
  label,
  fieldName,
  value,
  handleChange,
  handleBlur,
  errors,
}) => {
  return (
    <div className="form-group">
      <label htmlFor={fieldName}>{label}</label>
      <input
        id={fieldName}
        name={fieldName}
        onChange={handleChange}
        onBlur={handleBlur}
        value={value}
        type="text"
        className="form-control form-control-sm"
      />
      {errors?.[fieldName] && (
        <span className="text-danger">{errors?.[fieldName]}</span>
      )}
    </div>
  );
};

const AddressForm = ({ selectedAddress }) => {
  const { offeringId, investmentId } = useParams();
  const updateUser = useUpdateCurrentUser();

  const onSubmit = async (address) => {
    try {
      await updateUser.mutateAsync(address);
    } catch (error) {
      toast.error(error.response.data.errors.join(". "));
      return;
    }

    const data = await InquiryClient.start({
      offeringId,
      investmentId,
    });

    window.location.href = data.data.link;
  };

  if (!selectedAddress) return null;

  return (
    <Formik
      enableReinitialize
      initialValues={{
        address_street_1: selectedAddress.street,
        address_city: selectedAddress.city,
        address_state: selectedAddress.state,
        address_postal_code: selectedAddress.zipcode,
        address_country: selectedAddress.country,
      }}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={addressSchema}
      onSubmit={onSubmit}
    >
      {({ values, errors, handleBlur, handleChange, handleSubmit }) => (
        <form className="mt-4" onSubmit={handleSubmit}>
          <div className="row md-4">
            <div className="col-12 col-lg">
              <AddressComponent
                label="Street Address"
                fieldName="address_street_1"
                value={values.address_street_1}
                errors={errors}
                handleBlur={handleBlur}
                handleChange={handleChange}
              />
            </div>
            <div className="col-12 col-lg">
              <AddressComponent
                label="Street Address 2 (Unit #)"
                fieldName="address_street_2"
                value={values.address_street_2}
                errors={errors}
                handleBlur={handleBlur}
                handleChange={handleChange}
              />
            </div>
          </div>
          <div className="row md-4">
            <div className="col-12 col-lg">
              <AddressComponent
                label="City"
                fieldName="address_city"
                value={values.address_city}
                errors={errors}
                handleBlur={handleBlur}
                handleChange={handleChange}
              />
            </div>
            <div className="col-12 col-lg">
              <AddressComponent
                label="State"
                fieldName="address_state"
                value={values.address_state}
                errors={errors}
                handleBlur={handleBlur}
                handleChange={handleChange}
              />
            </div>
          </div>
          <div className="row md-4">
            <div className="col-12 col-lg">
              <AddressComponent
                label="Postal Code"
                fieldName="address_postal_code"
                value={values.address_postal_code}
                errors={errors}
                handleBlur={handleBlur}
                handleChange={handleChange}
              />
            </div>
            <div className="col-12 col-lg">
              <AddressComponent
                label="Country"
                fieldName="address_country"
                value={values.address_country}
                errors={errors}
                handleBlur={handleBlur}
                handleChange={handleChange}
              />
            </div>
          </div>
          <div className="continue d-flex justify-content-start align-items-center align-content-center gap-3">
            <button
              className=" btn btn-sm btn-eq-primary"
              disabled={updateUser.isLoading}
              type="submit"
            >
              Continue
            </button>
            {updateUser.isLoading && (
              <div
                className="spinner-grow text-secondary spinner-grow-sm"
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            )}
          </div>
        </form>
      )}
    </Formik>
  );
};

const OpenOfferingPersonaInquiry = () => {
  const [selectedAddress, setSelectedAddress] = useState(false);

  return (
    <section className="py-4">
      <div className="container investor-info">
        <h1 className="fs-2 text-md-start mt-4 mb-6">Verify Investor Info</h1>
        <div className="row">
          <div className="d-none d-md-block col-md-5">
            <img
              src="/img/investor-review.svg"
              className="img-fluid d-block w-100 mx-auto mb-6 mb-md-0 aos-init aos-animate"
              alt="Investor KYC/KYB Review"
              data-aos="fade-up"
              data-aos-delay="100"
            />
          </div>
          <div className="col-12 col-md-7">
            <div className="w-100 mb-4 pb-4">
              <h3>Please enter your legal address:</h3>
              <GooglePlaceSearch
                onSelected={(value) => setSelectedAddress(value)}
              />
              <AddressForm selectedAddress={selectedAddress} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OpenOfferingPersonaInquiry;
