import moment from "moment";
import React, { useEffect, useMemo } from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
} from "react-router-dom";
import { toast } from "react-toastify";

import Countdown from "../../components/Countdown";
import { OpenOfferingProgressBar } from "../../components/ProgressBar";
import Spinner from "../../components/spinner";
import { investmentContinueUrlOpenOffering } from "../../helpers/investment";
import { useOpenOffering } from "../../hooks/queries/offerings";
import { useCurrentInvestment } from "../../hooks/useInvestments";
import { PrivateRoute } from "../../layouts/appRoute";
import OpenOfferingAccreditation from "./OpenOfferingAccreditation";
import OpenOfferingAgreements from "./OpenOfferingAgreements";
import OpenOfferingCompleted from "./OpenOfferingCompleted";
import OpenOfferingDisclosure from "./OpenOfferingDisclosure";
import OpenOfferingEnterPassword from "./OpenOfferingEnterPassword";
import OpenOfferingLogin from "./OpenOfferingLogin";
import OpenOfferingNotAvailable from "./OpenOfferingNotAvailable";
import OpenOfferingPayment from "./OpenOfferingPayment";
import OpenOfferingPaymentManual from "./OpenOfferingPaymentManual";
import OpenOfferingPaymentProcessing from "./OpenOfferingPaymentProcessing";
import OpenOfferingPersonaInquiry from "./OpenOfferingPersonaInquiry";
import OpenOfferingPersonaInquiryResult from "./OpenOfferingPersonaInquiryResult";
import OpenOfferingQuestionnaire from "./OpenOfferingQuestionnaire";
import OpenOfferingSubscription from "./OpenOfferingSubscription";
import OpenOfferingUserInfo from "./OpenOfferingUserInfo";

const OfferingJoin = () => {
  const history = useHistory();
  const { offeringId } = useParams();

  const { data: offering, isError: isOfferingError } = useOpenOffering(
    offeringId,
    {
      onError: (err) => {
        toast.error("Sorry! Offering could not be found");
      },
    }
  );

  const { data: investment } = useCurrentInvestment();
  const continueUrl = investmentContinueUrlOpenOffering(
    investment?.id,
    investment?.status,
    offeringId
  );

  useEffect(() => {
    if (continueUrl) {
      history.replace(continueUrl);
    }
  }, [continueUrl, history]);

  const isExpired = useMemo(() => {
    if (!offering?.termination_date) return false;
    return moment().isSameOrAfter(moment(offering?.termination_date));
  }, [offering?.termination_date]);

  if (isExpired) {
    toast.error("That offering's termination date has passed.");
    return <Redirect to="/" />;
  }

  if (isOfferingError) {
    return <Redirect to="/" />;
  }

  if (!offering) {
    return <Spinner show />;
  }

  return (
    <>
      <Countdown from={offering.termination_date} />
      <div className="mt-4">
        <div className="container">
          <Switch>
            <Route exact path="/offering/:offeringId/start">
              {offering.status !== "Active" ? (
                <OpenOfferingNotAvailable
                  offering={offering}
                  onAccept={() => {
                    history.push(`/`);
                  }}
                />
              ) : (
                <OpenOfferingDisclosure
                  offering={offering}
                  onAccept={() => {
                    history.push(`/offering/${offeringId}/info`);
                  }}
                />
              )}
            </Route>
            <Route exact path="/offering/:offeringId/login">
              <OpenOfferingProgressBar index={1} />
              <OpenOfferingLogin />
            </Route>
            <Route exact path="/offering/:offeringId/info">
              <OpenOfferingProgressBar index={1} />
              <OpenOfferingUserInfo />
            </Route>
            <PrivateRoute exact path="/offering/:offeringId/info/:investmentId">
              <OpenOfferingProgressBar index={1} />
              <OpenOfferingUserInfo />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/offering/:offeringId/questionnaire/:investmentId"
            >
              <OpenOfferingProgressBar index={2} />
              <OpenOfferingQuestionnaire offering={offering} />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/offering/:offeringId/verify/start/:investmentId"
            >
              <OpenOfferingProgressBar index={3} />

              <OpenOfferingPersonaInquiry offering={offering} />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/offering/:offeringId/verify/result/:investmentId"
            >
              <OpenOfferingProgressBar index={3} />
              <OpenOfferingPersonaInquiryResult />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/offering/:offeringId/subscription/:investmentId"
            >
              <OpenOfferingProgressBar index={4} />
              <OpenOfferingSubscription offering={offering} />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/offering/:offeringId/agreements/:investmentId"
            >
              <OpenOfferingProgressBar index={5} />
              <OpenOfferingAgreements />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/offering/:offeringId/payment/:investmentId/manual"
            >
              <OpenOfferingProgressBar index={6} />
              <OpenOfferingPaymentManual />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/offering/:offeringId/payment/:investmentId"
            >
              <OpenOfferingProgressBar index={6} />
              <OpenOfferingPayment />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/offering/:offeringId/payment-complete/:investmentId/"
            >
              <OpenOfferingPaymentProcessing index={6} />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/offering/:offeringId/password/:investmentId"
            >
              <OpenOfferingProgressBar index={7} />
              <OpenOfferingEnterPassword />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/offering/:offeringId/accreditation/:investmentId"
            >
              <OpenOfferingProgressBar index={8} />
              <OpenOfferingAccreditation />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/offering/:offeringId/complete/:investmentId"
            >
              <OpenOfferingProgressBar index={9} />
              <OpenOfferingCompleted />
            </PrivateRoute>

            <Redirect
              exact
              path="/offering/:offeringId/:any*"
              to="/offering/:offeringId/start"
            />
          </Switch>
        </div>
      </div>
    </>
  );
};

export default OfferingJoin;
