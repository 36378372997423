import moment from "moment";
import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import InvestmentClient from "../../clients/InvestmentClient";
import {
  addInvestmentBroker,
  isCommonStockType,
  isConvertibleOffering,
  removeInvestmentBroker,
} from "../../helpers";

import OfferingBrokerSelect from "../../components/offerings/OfferingBrokerSelect";
import { useOfferingBrokers } from "../../hooks/queries/offerings";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import Countdown from "./../../components/Countdown";
import ProgressBar from "./../../components/ProgressBar";
import Spinner from "./../../components/spinner";

const Subscription = (props) => {
  const dispatch = useDispatch();
  const investment = useSelector((store) => store.investment);
  const offering = useSelector((store) => store.offering);
  const { data: brokers, isLoading } = useOfferingBrokers(offering.id);
  const [selectedBroker, setSelectedBroker] = useState();

  const totalUnits = offering.total_units;
  const totalAmount = Number(offering.total_amount.replace(/[^0-9.-]+/g, ""));
  const unitPrice = Number(offering.unit_price.replace(/[^0-9.-]+/g, ""));

  useDocumentTitle([
    "Subscription",
    investment?.offering?.name,
    investment?.issuer?.name,
  ]);

  const [quantity, setQuantity] = useState(investment.quantity || 0);
  const [amount, setAmount] = useState(investment.quantity * unitPrice);

  const [quantityError, setQuantityError] = useState("");
  const [amountError, setAmountError] = useState("");
  const [spinner, showSpinner] = useState(false);

  useEffect(() => {
    let regex = /^[-+]?[0-9]+\.[0-9]+$/;
    if (
      (quantity > 0 && quantity < offering.investor_minimum_units) ||
      regex.test(quantity)
    ) {
      if (regex.test(quantity)) {
        setQuantityError("Value cannot be a decimal");
      } else {
        setQuantityError(
          `Minimum ${offering.investor_minimum_units} shares required`
        );
        setAmountError(
          `Minimum ${
            offering.investor_minimum_units * unitPrice
          } investment required`
        );
      }
    } else {
      setQuantityError("");
      setAmountError("");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quantity]);

  const handleQuantityChange = ({ value }) => {
    setQuantity(value);
    setAmount(value * unitPrice);
  };

  const handleAmountChange = ({ value }) => {
    setAmount(value);
    setQuantity(unitPrice > 0 ? value / unitPrice : 0);
  };

  const submitForm = async () => {
    showSpinner(true);
    const response = await InvestmentClient.update(investment.id, {
      amount: amount,
      status: "Agreement Pending",
    });

    dispatch({ type: "investment", payload: response });
    props.history.push("/agreement");
    showSpinner(false);

    if (selectedBroker) {
      await addInvestmentBroker(investment.id, selectedBroker.value.id);
    } else {
      await removeInvestmentBroker(investment.id);
    }
  };

  return (
    <>
      <Countdown from={offering.termination_date} />
      <ProgressBar index={3} />

      <section className="mt-4">
        <div className="container">
          <h2 className="text-md-start mb-0 mt-4">Subscription</h2>
          <p className="text-muted">
            Please enter your investment amount either in the dollar value or
            number of shares
          </p>
          {investment && (
            <>
              <div className="row">
                {isCommonStockType(offering.stock_type) && (
                  <div className="col-md-4">
                    <div className="card">
                      <div className="card-body p-4">
                        <h6 className="text-uppercase mb-1 text-muted">
                          Price per Share
                        </h6>
                        <h3 className="mb-0">
                          <NumberFormat
                            displayType="text"
                            thousandSeparator={true}
                            value={offering.unit_price}
                            prefix="$"
                          />
                        </h3>
                      </div>
                    </div>
                  </div>
                )}
                {isCommonStockType(offering.stock_type) && (
                  <div className="col-md-4">
                    <div className="card">
                      <div className="card-body p-4">
                        <h6 className="text-uppercase mb-1 text-muted">
                          Available Shares
                        </h6>
                        <h3 className="mb-0">
                          <NumberFormat
                            displayType="text"
                            thousandSeparator={true}
                            value={offering.total_units}
                          />
                        </h3>
                      </div>
                    </div>
                  </div>
                )}
                <div className="col-md-4">
                  <div className="card">
                    <div className="card-body p-4">
                      <h6 className="text-uppercase mb-1 text-muted">
                        Total Amount
                      </h6>
                      <h3 className="mb-0">
                        <NumberFormat
                          displayType="text"
                          thousandSeparator={true}
                          value={offering.total_amount}
                          prefix="$"
                          decimalScale={0}
                        />
                      </h3>
                    </div>
                  </div>
                </div>
                {isConvertibleOffering(offering.stock_type) &&
                  (offering?.additional_info?.enter_maturity_date ? (
                    <div className="col-md-4">
                      <div className="card">
                        <div className="card-body p-4">
                          <h6 className="text-uppercase mb-1 text-muted">
                            Maturity Date
                          </h6>
                          <h3 className="mb-0">
                            {moment(
                              offering?.additional_info?.maturity_date
                            ).format("LL")}
                          </h3>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="col-md-4">
                      <div className="card">
                        <div className="card-body p-4">
                          <h6 className="text-uppercase mb-1 text-muted">
                            Term (in months)
                          </h6>
                          <h3 className="mb-0">
                            {offering?.additional_info?.term}
                          </h3>
                        </div>
                      </div>
                    </div>
                  ))}
                {isConvertibleOffering(offering.stock_type) && (
                  <div className="col-md-4">
                    <div className="card">
                      <div className="card-body p-4">
                        <h6 className="text-uppercase mb-1 text-muted">
                          Conversion Price
                        </h6>
                        <h3 className="mb-0">
                          {offering?.unit_price === "$0.00"
                            ? `As per Note`
                            : `${offering?.unit_price}`}
                        </h3>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="row mt-4">
                {isConvertibleOffering(offering.stock_type) && (
                  <div className="col-md-4">
                    <div className="card">
                      <div className="card-body p-4">
                        <h6 className="text-uppercase mb-1 text-muted">
                          Original Issuance Discount ($)
                        </h6>
                        <h3 className="mb-0">
                          ${offering?.additional_info?.oic_amount}
                        </h3>
                      </div>
                    </div>
                  </div>
                )}
                {isConvertibleOffering(offering.stock_type) && (
                  <div className="col-md-4">
                    <div className="card">
                      <div className="card-body p-4">
                        <h6 className="text-uppercase mb-1 text-muted">
                          Original Issuance Discount (%)
                        </h6>
                        <h3 className="mb-0">
                          {offering?.additional_info?.oic_percentage}%
                        </h3>
                      </div>
                    </div>
                  </div>
                )}
                {isConvertibleOffering(offering.stock_type) && (
                  <div className="col-md-4">
                    <div className="card">
                      <div className="card-body p-4">
                        <h6 className="text-uppercase mb-1 text-muted">
                          Original Principal Amount
                        </h6>
                        <h3 className="mb-0">
                          {offering?.additional_info
                            ?.original_principle_amount && (
                            <NumberFormat
                              prefix="$"
                              decimalScale={0}
                              value={
                                offering?.additional_info
                                  ?.original_principle_amount
                              }
                              displayType="text"
                              thousandSeparator={true}
                            />
                          )}
                        </h3>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="row mt-4">
                {isCommonStockType(offering.stock_type) && (
                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <label>Number of shares</label>
                      <NumberFormat
                        className="form-control form-control-lg"
                        allowNegative={false}
                        thousandSeparator={true}
                        value={quantity}
                        defaultValue={quantity || ""}
                        isAllowed={({ formattedValue, floatValue }) => {
                          return (
                            formattedValue === "" || floatValue <= totalUnits
                          );
                        }}
                        onValueChange={handleQuantityChange}
                        decimalScale={2}
                      />
                      {quantityError && (
                        <div className="text-danger">{quantityError}</div>
                      )}
                    </div>
                  </div>
                )}

                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <label>Investment amount</label>
                    <NumberFormat
                      className="form-control form-control-lg"
                      prefix="$"
                      allowNegative={false}
                      thousandSeparator={true}
                      value={amount}
                      defaultValue={amount || ""}
                      isAllowed={({ formattedValue, floatValue }) => {
                        return (
                          formattedValue === "" || floatValue <= totalAmount
                        );
                      }}
                      onValueChange={handleAmountChange}
                      decimalScale={2}
                    />
                    {amountError && (
                      <div className="text-danger">{amountError}</div>
                    )}
                  </div>
                </div>
              </div>

              <p className="text-muted">
                Where you directed to this subscription by your broker?
              </p>
              <div className="row mt-4">
                <div className="col-4 col-md-6">
                  <div className="form-group">
                    <OfferingBrokerSelect
                      offeringId={offering.id}
                      value={selectedBroker}
                      onChange={setSelectedBroker}
                    />
                  </div>
                </div>
              </div>
              <Spinner show={spinner || isLoading}>
                <button
                  disabled={amount <= 0 || quantityError || amountError}
                  className="btn btn-eq-primary shadow lift me-1"
                  onClick={submitForm}
                >
                  Continue <i className="fe fe-arrow-right ms-1"></i>
                </button>
              </Spinner>
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default Subscription;
