import React from "react";
import NumberFormat from "react-number-format";

import { NumberFormatter } from "../../../../constants/format";

const OfferingInfoCard = ({ offering }) => {
  return (
    <div className="card">
      <div className="card-header">
        <h4 className="card-header-title">Offering Info</h4>
      </div>
      <div className="card-body p-0 px-4">
        <div className="list-group list-group-flush">
          <div className="list-group-item">
            <div className="row align-items-center">
              <div className="col">Stock Ticker</div>
              <div className="col col-sm-auto text-muted">
                {offering?.stock_ticker ?? "N/A"}
              </div>
            </div>
          </div>
          <div className="list-group-item">
            <div className="row align-items-center">
              <div className="col">Total amount</div>
              <div className="col col-auto text-muted">
                <NumberFormat
                  value={offering?.total_amount}
                  displayType="text"
                  thousandSeparator={true}
                  prefix="$"
                />
              </div>
            </div>
          </div>
          {(offering?.type === "COMMON_STOCK" ||
            offering?.type === "PREFERRED_STOCK") && (
            <div className="list-group-item">
              <div className="row align-items-center">
                <div className="col">Number of shares</div>
                <div className="col col-sm-auto text-muted">
                  {NumberFormatter.format(offering?.total_units)}
                </div>
              </div>
            </div>
          )}
          <div className="list-group-item">
            <div className="row align-items-center">
              <div className="col">Price per share</div>
              <div className="col col-sm-auto text-muted">
                <NumberFormat
                  value={offering?.unit_price}
                  displayType="text"
                  thousandSeparator={true}
                  prefix="$"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OfferingInfoCard;
