import APIClient from "./api";

class OfferingClient {
  static async find(id) {
    const response = await APIClient().get(`/offering/${id}`);
    return response.data;
  }

  static async findOpen(id) {
    const response = await APIClient().get(`/v2/offerings/${id}`);
    return response.data?.data;
  }

  static async getLegacySettings(id) {
    const response = await APIClient().get(`/offering/${id}/setting`);
    return response.data;
  }

  static async getBrokers(id) {
    const response = await APIClient().get(`/v2/offerings/${id}/brokers`);
    return response.data;
  }
}

export default OfferingClient;
