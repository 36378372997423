import { Elements } from "@stripe/react-stripe-js";
import moment from "moment";
import { Fragment, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import NumberFormat from "react-number-format";
import { useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert";

import { ACCREDITATION_PENDING } from "@equidefi/shared/constants/investments";
import InfoCard from "../../components/infoCard";
import { CurrencyFormatter } from "../../constants/format";
import { stripeAccountPromise, stripePromise } from "../../constants/stripe";
import {
  useOfferingSettings,
  useOpenOffering,
} from "../../hooks/queries/offerings";
import { useCurrentInvestor } from "../../hooks/use-current-investor";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import {
  useInitiatePaymentMutation,
  useInvestment,
  useUpdateInvestment,
} from "../../hooks/useInvestments";
import PaymentForm from "./../../components/paymentForm";
import Spinner from "./../../components/spinner";

const APPEARANCE = {
  theme: "stripe",
  variables: {
    colorPrimary: "#0570de",
    colorBackground: "#ffffff",
    colorText: "#30313d",
    colorDanger: "#df1b41",
    fontFamily: "Ideal Sans, system-ui, sans-serif",
    spacingUnit: "2px",
    borderRadius: "4px",
  },
};

const OpenOfferingPayment = (props) => {
  const history = useHistory();
  const { investmentId, offeringId } = useParams();

  useCurrentInvestor({ investmentId });

  const createPayment = useInitiatePaymentMutation(investmentId);
  const updateInvestment = useUpdateInvestment(investmentId);

  const investmentQuery = useInvestment(investmentId);
  const offeringQuery = useOpenOffering(offeringId);
  const settingsQuery = useOfferingSettings(offeringId);

  const { data: offering } = offeringQuery;

  const investment = investmentQuery.data;
  const settings = settingsQuery.data;

  useDocumentTitle([
    "Payment",
    investment?.offering?.name,
    investment?.issuer?.name,
  ]);

  const [spinner, showSpinner] = useState(false);
  const [paymentModel, showPaymentModel] = useState(false);
  const [clientSecret, setClientSecret] = useState("");
  const [stripe, setStripe] = useState(null);

  const isLoading =
    spinner || investmentQuery.isFetching || offeringQuery.isFetching;

  const bankData = useMemo(() => {
    return settings?.find((item) => item.id === "payment_bank_type");
  }, [settings]);
  const payments = useMemo(() => bankData?.value === "escrow est", [bankData]);

  const showStripePayment = async () => {
    const status = await Swal({
      title: `Pay Now`,
      text: `Are you sure that you want to continue with Online Payment using Stripe? It may take 4-5 business days to complete the transaction. Stripe may charge upto $5 for Online Payment.`,
      buttons: ["Cancel", "Yes"],
      icon: "warning",
      dangerMode: true,
    });

    if (status) {
      showSpinner(true);

      const clientSecretData = await createPayment.mutateAsync();

      if (offering.stripe_account_id) {
        setStripe(stripeAccountPromise(offering.stripe_account_id));
      } else {
        setStripe(stripePromise);
      }
      setClientSecret(clientSecretData.clientSecret);
      showPaymentModel(true);
      showSpinner(false);
    }
  };

  const showManualPayment = async () => {
    const status = await Swal({
      title: `Pay Later`,
      text: `Are you sure that you would like to continue with Wire transfer? It may take 1 business day to complete the transaction. Wire transfer instructions will be shared in the next step.`,
      buttons: ["Cancel", "Yes"],
      icon: "warning",
      dangerMode: true,
    });

    if (status) {
      showSpinner(true);

      await updateInvestment.mutateAsync({
        status: ACCREDITATION_PENDING,
      });
      history.push(`/offering/${offeringId}/payment/${investmentId}/manual`);
    }
  };

  const supportsStripePayment = bankData?.value === "escrow est";

  const options = {
    clientSecret: clientSecret,
    appearance: {
      appearance: APPEARANCE,
    },
  };

  return (
    <>
      <section className="py-4">
        <div className="container">
          <h2 className="text-md-start mb-0 mt-4">Payment</h2>
          <div className="text-muted">
            Your bank account information and identity is being checked as part
            of our KYC/AML process and no money is being withdrawn at this time.
            Below is the summary of funds that will be deducted from your
            verified bank account and will be transferred to escrow. Please
            continue to payment.
          </div>

          <Spinner show={isLoading}>
            <div className="row my-3">
              <div className="col">
                <InfoCard title="Investment Amount">
                  {CurrencyFormatter.format(investment?.amount)}
                </InfoCard>
              </div>
              <div className="col">
                <InfoCard title="Total Due Now">
                  {CurrencyFormatter.format(investment?.amount)}
                </InfoCard>
              </div>
            </div>

            <div className="card mb-3 mt-4 d-none">
              <div className="row d-flex justify-content-center">
                {investment?.payments?.map((payment) => {
                  return (
                    <Fragment key={payment.id}>
                      <div className="col-12 col mb-3 col-lg-3 col-md-3 m-0 p-1 cardItem">
                        <div>
                          <div className="card-body p-4">
                            <h6 className=" my-4 ms-4 dateItem pb-4 titleStyle">
                              Date
                            </h6>

                            <h3 className="mb-0 text-center">
                              {moment(payment.create_date).format(
                                "MMM DD, YYYY h:mm a"
                              )}
                            </h3>
                          </div>
                        </div>
                        <hr className="d-md-none" />
                      </div>

                      <div className="col-12 col mb-3 col-lg-2 col-md-3 m-0 p-1 cardItem">
                        <div>
                          <div className="card-body p-4">
                            <h6 className="my-4 text-center pb-4 titleStyle">
                              Amount
                            </h6>
                            <h3 className="mb-0 text-center">
                              <NumberFormat
                                value={payment.amount}
                                displayType="text"
                                thousandSeparator={true}
                                prefix="$"
                              />
                            </h3>
                          </div>
                        </div>
                        <hr className="d-md-none" />
                      </div>

                      <div className="col-12 col mb-3 col-lg-2 col-md-3 m-0 p-1 cardItem">
                        <div>
                          <div className="card-body p-4">
                            <h6 className="my-4 text-center pb-4 titleStyle">
                              Platform Fee
                            </h6>
                            <h3 className="mb-0 text-center">
                              <NumberFormat
                                value={payment.payment_fee}
                                displayType="text"
                                thousandSeparator={true}
                                prefix="$"
                              />
                            </h3>
                          </div>
                        </div>
                        <hr className="d-md-none" />
                      </div>

                      <div className="col-12 col mb-3 col-lg-2 col-md-3 m-0 p-1 cardItem">
                        <div>
                          <div className="card-body p-4">
                            <h6 className="my-4 text-center pb-4 titleStyle">
                              Total
                            </h6>
                            <h3 className="mb-0 text-center">
                              <NumberFormat
                                value={payment.amount + payment.payment_fee}
                                displayType="text"
                                thousandSeparator={true}
                                prefix="$"
                              />
                            </h3>
                          </div>
                        </div>
                        <hr className="d-md-none" />
                      </div>

                      <div className="col-12 col mb-3 col-lg-3 col-md-12 m-0 p-1">
                        <hr className="d-none d-md-block d-lg-none" />

                        <div>
                          <div className="card-body p-4">
                            <h6 className="my-4 text-center pb-4 titleStyle">
                              Status
                            </h6>
                            <h3 className="mb-0 text-center">
                              {(payment.status === "created" ||
                                payment.status === "requires_source" ||
                                payment.status === "payment_failed") &&
                                payments && <></>}
                              {payment.status === "processing" && (
                                <span className="badge bg-warning">
                                  Payment Processing
                                </span>
                              )}
                              {payment.status === "succeeded" && (
                                <span className="badge bg-success">
                                  Payment Successful
                                </span>
                              )}
                            </h3>
                          </div>
                        </div>
                      </div>
                    </Fragment>
                  );
                })}
              </div>
            </div>

            {supportsStripePayment ? (
              <>
                <button
                  className="btn btn-eq-primary"
                  onClick={() => showStripePayment()}
                >
                  Pay Now <i className="fe fe-arrow-right ms-1"></i>
                </button>
                <button
                  className="btn btn-light mt-0 payLater-button"
                  onClick={() => showManualPayment()}
                >
                  Pay Later <i className="fe fe-arrow-right ms-1"></i>
                </button>
              </>
            ) : (
              <button
                className="btn btn-eq-primary"
                onClick={() => showManualPayment()}
              >
                Pay<i className="fe fe-arrow-right ms-1"></i>
              </button>
            )}
          </Spinner>
        </div>
      </section>

      <Modal
        show={paymentModel}
        onHide={() => {
          showPaymentModel(false);
        }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        dialogClassName="stopModal"
        keyboard={false}
      >
        <Modal.Header closeButton className="p-0"></Modal.Header>
        <Modal.Body className="">
          <div className="eq-badge text-small mb-3">
            If manual bank authentication is selected, then a microdeposit
            verification is needed and will result in delayed funding.
          </div>
          {clientSecret && (
            <Elements stripe={stripe} options={options}>
              <PaymentForm
                returnPath={`/payment/${offeringId}/${investmentId}/processing`}
              />
            </Elements>
          )}
          <div className="d-flex align-items-center justify-content-center flex-column"></div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default OpenOfferingPayment;
