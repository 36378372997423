import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import NumberFormat from "react-number-format";
import { useDispatch, useSelector } from "react-redux";

import axios from "axios";
import moment from "moment";
import Swal from "sweetalert";

import { useDocumentTitle } from "../../hooks/useDocumentTitle";

import InfoCard from "../../components/infoCard";
import Countdown from "./../../components/Countdown";
import ProgressBar from "./../../components/ProgressBar";
import PaymentForm from "./../../components/paymentForm";
import Spinner from "./../../components/spinner";

const Payment = (props) => {
  const dispatch = useDispatch();
  const investment = useSelector((store) => store.investment);
  const offering = useSelector((store) => store.offering);

  useDocumentTitle([
    "Payment",
    investment?.offering?.name,
    investment?.issuer?.name,
  ]);

  let canContinue = true;
  const [spinner, showSpinner] = useState(false);
  const [paymentModel, showPaymentModel] = useState(false);
  const [clientSecret, setClientSecret] = useState("");
  const [stripePromise, setStripePromise] = useState();

  const [bankData, setBankData] = useState();
  const [payments, setPayment] = useState(true);

  useEffect(() => {
    axios.get(`investment/${investment.id}`).then((response) => {
      dispatch({ type: "investment", payload: response.data });
    });

    axios.get(`offering/${investment.offering_id}`).then((response) => {
      dispatch({ type: "offering", payload: response.data });
    });

    axios.get(`/offering/${investment.offering_id}/setting`).then((res) => {
      const filter = res.data.filter(
        (item, key) => item.id === "payment_bank_type"
      );
      setBankData(filter[0]);
    });
    bankData?.value === "escrow est" ? setPayment(false) : setPayment(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showStripePayment = () => {
    Swal({
      title: `Pay Now`,
      text: `Are you sure that you want to continue with Online Payment using Stripe? It may take 4-5 business days to complete the transaction. Stripe may charge upto $5 for Online Payment.`,
      buttons: ["Cancel", "Yes"],
      icon: "warning",
      dangerMode: true,
    }).then((status) => {
      if (status) {
        showSpinner(true);

        axios
          .post(`investment/${investment.id}/payment-intent`)
          .then(async (response) => {
            const { clientSecret } = response.data;
            axios
              .get(`investment/${investment.id}/payment-token`)
              .then(async (response) => {
                const { publishableKey } = response.data;
                const stripePromise = await loadStripe(publishableKey);
                setStripePromise(stripePromise);
                setClientSecret(clientSecret);
                showPaymentModel(true);
                showSpinner(false);
              });
          });
      }
    });
  };

  const showManualPayment = () => {
    Swal({
      title: `Pay Later`,
      text: `Are you sure that you would like to continue with Wire transfer? It may take 1 business day to complete the transaction. Wire transfer instructions will be shared in the next step.`,
      buttons: ["Cancel", "Yes"],
      icon: "warning",
      dangerMode: true,
    }).then((status) => {
      if (status) {
        axios
          .post(`investment/${investment.id}/status/payment_pending`)
          .then((response) => props.history.push("/payment/manual"));
        showSpinner(true);
        props.history.push("/payment/manual");
      }
    });
  };

  const goToPayment = () => {
    showSpinner(true);
    axios
      .post(`investment/${investment.id}/status/payment_pending`)
      .then((response) => {
        props.history.push("/payment/pending");
      });
  };

  const appearance = {
    theme: "stripe",
    variables: {
      colorPrimary: "#0570de",
      colorBackground: "#ffffff",
      colorText: "#30313d",
      colorDanger: "#df1b41",
      fontFamily: "Ideal Sans, system-ui, sans-serif",
      spacingUnit: "2px",
      borderRadius: "4px",
    },
  };

  const options = {
    clientSecret: clientSecret,
    appearance: {
      appearance,
    },
  };

  return (
    <>
      <Countdown from={offering.termination_date} />
      <ProgressBar index={5} />

      <section className="py-4">
        <div className="container">
          <h2 className="text-md-start mb-0 mt-4">Payment</h2>
          <div className="text-muted">
            Your bank account information and identity is being checked as part
            of our KYC/AML process and no money is being withdrawn at this time.
            Below is the summary of funds that will be deducted from your
            verified bank account and will be transferred to escrow. Please
            continue to payment.
          </div>

          <Spinner show={spinner}>
            <div className="row my-3">
              <div className="col">
                <InfoCard title="Investment Amount">
                  {investment?.amount}
                </InfoCard>
              </div>
              <div className="col">
                <InfoCard title="Total Due Now">{investment?.amount}</InfoCard>
              </div>
            </div>

            <div className="card mb-3 mt-4 d-none">
              <div className="row d-flex justify-content-center">
                {investment.payments?.map((payment, index) => {
                  if (payment.status !== "succeeded") canContinue = false;
                  return (
                    <>
                      <div className="col-12 col mb-3 col-lg-3 col-md-3 m-0 p-1 cardItem">
                        <div>
                          <div className="card-body p-4">
                            <h6 className=" my-4 ms-4 dateItem pb-4 titleStyle">
                              Date
                            </h6>

                            <h3 className="mb-0 text-center">
                              {moment(payment.create_date).format(
                                "MMM DD, YYYY h:mm a"
                              )}
                            </h3>
                          </div>
                        </div>
                        <hr className="d-md-none" />
                      </div>

                      <div className="col-12 col mb-3 col-lg-2 col-md-3 m-0 p-1 cardItem">
                        <div>
                          <div className="card-body p-4">
                            <h6 className="my-4 text-center pb-4 titleStyle">
                              Amount
                            </h6>
                            <h3 className="mb-0 text-center">
                              <NumberFormat
                                value={payment.amount}
                                displayType="text"
                                thousandSeparator={true}
                                prefix="$"
                              />
                            </h3>
                          </div>
                        </div>
                        <hr className="d-md-none" />
                      </div>

                      <div className="col-12 col mb-3 col-lg-2 col-md-3 m-0 p-1 cardItem">
                        <div>
                          <div className="card-body p-4">
                            <h6 className="my-4 text-center pb-4 titleStyle">
                              Platform Fee
                            </h6>
                            <h3 className="mb-0 text-center">
                              <NumberFormat
                                value={payment.payment_fee}
                                displayType="text"
                                thousandSeparator={true}
                                prefix="$"
                              />
                            </h3>
                          </div>
                        </div>
                        <hr className="d-md-none" />
                      </div>

                      <div className="col-12 col mb-3 col-lg-2 col-md-3 m-0 p-1 cardItem">
                        <div>
                          <div className="card-body p-4">
                            <h6 className="my-4 text-center pb-4 titleStyle">
                              Total
                            </h6>
                            <h3 className="mb-0 text-center">
                              <NumberFormat
                                value={payment.amount + payment.payment_fee}
                                displayType="text"
                                thousandSeparator={true}
                                prefix="$"
                              />
                            </h3>
                          </div>
                        </div>
                        <hr className="d-md-none" />
                      </div>

                      <div className="col-12 col mb-3 col-lg-3 col-md-12 m-0 p-1">
                        <hr className="d-none d-md-block d-lg-none" />

                        <div>
                          <div className="card-body p-4">
                            <h6 className="my-4 text-center pb-4 titleStyle">
                              Status
                            </h6>
                            <h3 className="mb-0 text-center">
                              {(payment.status === "created" ||
                                payment.status === "requires_source" ||
                                payment.status === "payment_failed") &&
                                payments && <></>}
                              {payment.status === "processing" && (
                                <span className="badge bg-warning">
                                  Payment Processing
                                </span>
                              )}
                              {payment.status === "succeeded" && (
                                <span className="badge bg-success">
                                  Payment Successful
                                </span>
                              )}
                            </h3>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>

            {bankData?.value === "escrow est" ? (
              <>
                <button
                  className="btn btn-eq-primary"
                  onClick={() => showStripePayment()}
                >
                  Pay Now <i className="fe fe-arrow-right ms-1"></i>
                </button>
                <button
                  className="btn btn-light mt-0 payLater-button"
                  onClick={() => showManualPayment()}
                >
                  Pay Later <i className="fe fe-arrow-right ms-1"></i>
                </button>
              </>
            ) : (
              <button
                className="btn btn-eq-primary"
                onClick={() => showManualPayment()}
              >
                Pay<i className="fe fe-arrow-right ms-1"></i>
              </button>
            )}

            {/* {
              canContinue &&
              <div className='text-center text-md-start mt-4'>
                <button className='btn btn-eq-primary shadow lift' onClick={() => goToPayment()}>
                  Continue <i className='fe fe-arrow-right-circle'></i>
                </button>
              </div>
            } */}
          </Spinner>
        </div>
      </section>
      <Modal
        show={paymentModel}
        onHide={() => {
          showPaymentModel(false);
        }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        dialogClassName="stopModal"
        keyboard={false}
      >
        <Modal.Header closeButton className="p-0"></Modal.Header>
        <Modal.Body className="">
          <div className="eq-badge text-small mb-3">
            If manual bank authentication is selected, then a microdeposit
            verification is needed and will result in delayed funding.
          </div>
          {stripePromise && (
            <Elements stripe={stripePromise} options={options}>
              <PaymentForm />
            </Elements>
          )}
          <div className="d-flex align-items-center justify-content-center flex-column"></div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Payment;
