import axios from "axios";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import Spinner from "../../components/spinner";

const VerifyInvestor = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const params = useParams();

  useEffect(() => {
    dispatch({ type: "offering", payload: {} });
    dispatch({ type: "email", payload: "" });
    (async () => {
      const { token } = params;
      try {
        const response = await axios.post(`/user/verify/${token}`);
        dispatch({ type: "user", payload: response.data.user });
        dispatch({ type: "investor", payload: response.data.investor });
        history.push(`/investor/register/questionnaire`);
      } catch (error) {
        toast.error("We couldn't verify that account. Please try again later.");
        history.push("/");
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Spinner show />;
};

export default VerifyInvestor;
