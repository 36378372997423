import { useEffect, useState } from 'react';
import Confetti from 'react-confetti';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getSettingsByOfferingId } from '../../helpers/index';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';

const PaymentInstructions = (props) => {
  const investment = useSelector((store) => store.investment);
  const user = useSelector((store) => store.user);
  const offering = useSelector((store) => store.offering)
  const [bankData, setBankData] = useState({})

  useEffect(() => {
    getSettingsByOfferingId(offering?.id).then((data) => {
      let bankDetails = {
        bank_name: '',
        account_name: '',
        account_number: '',
        routing_number: '',
        transfer_agent_name: ''
      };

      data.forEach(element => {
        if (element?.id === 'payment_bank_name') {
          bankDetails.bank_name = element?.value
        }
        else if (element?.id === 'payment_account_name') {
          bankDetails.account_name = element?.value
        }
        else if (element?.id === 'payment_account_number') {
          bankDetails.account_number = element?.value
        }
        else if (element?.id === 'payment_routing_number') {
          bankDetails.routing_number = element?.value
        }
        else if (element?.id === 'transfer_agent_company') {
          bankDetails.transfer_agent_name = element?.value
        }
      });
      setBankData(bankDetails)
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useDocumentTitle([
    "Payment Instructions",
    investment?.offering?.name,
    investment?.issuer?.name,
  ]);


  return (
    <>
      <div className="container">
        <div className="row align-items-center vh-100">
          <div className="col-12">
            <h1 className="display-4 text-center text-md-start d-print-none">Thank You!</h1>
            <p className="lead text-muted d-print-none">Please initiate your wire to the account listed below:</p>
            <div className="card bg-eq-cardbg">
              <div className="card-body p-4 p-md-5">
                <div className="row">
                  <div className="col col-12 col-md-6">
                    <span>
                      <h3 className="fw-bolder d-inline">PAYMENT INSTRUCTIONS</h3>
                    </span>

                    <span className="d-block">
                      <h4 className="fw-bold d-inline pt-4">Bank Name:</h4>
                      <span> {bankData?.bank_name}</span>
                    </span>

                    <span className="d-block">
                      <h4 className="fw-bold d-inline pt-4">Account Name:</h4>
                      <span> {bankData?.account_name} </span>
                    </span>
                    <span className="d-block">
                      <h4 className="fw-bold d-inline pt-4">Account Number:</h4>
                      <span> {bankData?.account_number}</span>
                    </span>
                    <span className="d-block">
                      <h4 className="fw-bold d-inline pt-4">ABA Routing No:</h4>
                      <span> {bankData?.routing_number}</span>
                    </span>
                    {bankData?.transfer_agent_name && <span className="d-block">
                      <h4 className="fw-bold d-inline pt-4">Reference:</h4>
                      <span> {bankData?.transfer_agent_name}, as escrow agent for {offering?.issuer?.name}</span>
                    </span>}
                  </div>
                  <div className="col  d-none d-md-inline col-md-6 mt-3 mt-md-0 text-md-end">
                    <span className="d-print-none">
                      <small>
                        Would you like to print a copy of it?
                        <span className="btn btn-eq-primary d-inline mx-3 px-1 py-1" onClick={() => window.print()}>
                          Print
                        </span>
                      </small>
                    </span>
                  </div>
                  <div className="col  d-md-none col-md-6 mt-3 mt-md-0 text-md-end">
                    <span style={{ borderTop: '.1px solid rgb(37, 105, 195,0.3)' }} className="d-flex flex-column align-items-center">
                      <div className="text-center mt-3 d-print-none">
                        Would you like to print a copy of it?
                        <div className="btn btn-eq-primary btn-sm mt-2" onClick={() => window.print()}>
                          Print<i className="fe fe-printer ms-2"></i>
                        </div>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <p className="d-print-none">Your EquiDeFi Investor Account has been created. With this unique account you can access all of your private placement participations in one convenient and secure location. Whether you plan to deposit your shares into your brokerage account or convert them into shares of a public company, we have you covered. Through a system of smart notifications and important anniversaries and events, we will assist you with the necessary legal requirements and will always be available to answer questions in connection with your investment Subscription Plan. </p>

            {user.active ? (
              <Link to="/app/vault" className="btn btn-eq-primary">
                Go to My Vault <i className="fe fe-arrow-right ms-1"></i>
              </Link>
            ) : (
              <Link to="/complete-profile" className="btn btn-eq-primary">
                Complete your Profile <i className="fe fe-arrow-right ms-1"></i>
              </Link>
            )}
          </div>
        </div>
      </div>
      <div className="d-print-none">
        <Confetti />
      </div>
    </>
  );
};

export default PaymentInstructions;
