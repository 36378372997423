import axios from "axios";
import FileSaver from "file-saver";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import Countdown from "./../../components/Countdown";
import ProgressBar from "./../../components/ProgressBar";
import Spinner from "./../../components/spinner";

const Agreement = (props) => {
  const dispatch = useDispatch();
  const investment = useSelector((store) => store.investment);
  const offering = useSelector((store) => store.offering);

  useDocumentTitle([
    "Subscription Agreement",
    offering?.name,
    investment?.issuer?.name,
  ]);

  let canContinue = true;
  const [spinner, showSpinner] = useState(false);

  useEffect(() => {
    axios.get(`investment/${investment.id}`).then((response) => {
      dispatch({ type: "investment", payload: response.data });
    });

    axios.get(`offering/${offering.id}`).then((response) => {
      dispatch({ type: "offering", payload: response.data });
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const download = async (agreementId) => {
    showSpinner(true);

    let agreements =
      investment.agreements?.filter((x) => x.id === agreementId) || [];
    let agreement = {};
    if (agreements.length <= 0) {
      await axios.post(`investment/${investment.id}/agreement/${agreementId}`);
      let response = await axios.get(`investment/${investment.id}`);
      agreement = response.data.agreements?.filter(
        (x) => x.id === agreementId
      )[0];
      dispatch({ type: "investment", payload: response.data });
    } else {
      agreement = agreements[0];
    }

    let response = await axios.get(
      `investment/${investment.id}/agreement/${agreement.envelope_id}`
    );
    let toBytes = new Uint8Array(response.data.data),
      toBinary = ``;
    toBytes.forEach((res) => (toBinary += String.fromCharCode(res)));

    const file = window.btoa(toBinary);
    FileSaver.saveAs(
      `data:application/pdf;base64,${file}`,
      `${agreement.name.replace(/[^a-zA-Z ]/g, "")}`
    );

    showSpinner(false);
  };

  const signature = async (agreementId) => {
    showSpinner(true);

    let agreements =
      investment.agreements?.filter((x) => x.id === agreementId) || [];
    let agreement = {};
    if (agreements.length <= 0) {
      await axios.post(`investment/${investment.id}/agreement/${agreementId}`);
      let response = await axios.get(`investment/${investment.id}`);
      agreement = response.data.agreements?.filter(
        (x) => x.id === agreementId
      )[0];
      dispatch({ type: "investment", payload: response.data });
    } else {
      agreement = agreements[0];
    }

    let response = await axios.get(
      `investment/${investment.id}/agreement/${agreement.envelope_id}/url/investor`
    );
    window.location = response.data.url;
  };

  const goToPayment = () => {
    showSpinner(true);
    axios
      .post(`investment/${investment.id}/status/agreement_signed`)
      .then((response) => {
        props.history.push("/payment");
        // if (parseInt(investment.amount.replace(/[^0-9.-]+/g, '')) < 1000000) {
        //   axios.post(`investment/${investment.id}/payment-intent`)
        //     .then(response => );
        // } else {
        //   axios.post(`investment/${investment.id}/status/payment_pending`)
        //     .then(response => props.history.push('/payment/manual'));
        // }
      });
  };

  return (
    <>
      <Countdown from={offering.termination_date} />
      <ProgressBar index={4} />

      <section className="py-4">
        <div className="container">
          <h2 className="text-md-start mb-0 mt-4">Subscription Agreement</h2>
          <div className="text-muted">
            Execution of the agreement does not indicate the transaction is
            closed. Your funds and transaction documents will be secured in
            escrow and you will be notified when the transaction closes. Please
            download the agreement for reference or continue to signing.
          </div>

          <Spinner show={spinner}>
            <div className="list-group mt-4">
              {offering.agreements?.map((agreement, index) => {
                const myAgreement =
                  investment.agreements?.filter(
                    (x) => x.template_id === agreement.template_id
                  ) || [];
                const isAgreementSigned =
                  myAgreement.length > 0 &&
                  myAgreement[0].investor_status === "complete";
                const iconClass = isAgreementSigned
                  ? "check-circle"
                  : "alert-triangle";
                if (!isAgreementSigned) canContinue = false;
                return (
                  <div className="list-group-item" key={index}>
                    <div className="row">
                      <div className="col-12 col-lg-8 mb-4 text-center document-name">
                        <i className={`fe me-3 fe-${iconClass}`} />
                        {agreement.name}
                      </div>
                      <div className="col-12 col-lg-4 mx-auto text-center m-0 px-1">
                        <button
                          target="_blank"
                          rel="noreferrer"
                          className="btn btn-xs btn-info-soft me-1 button-space"
                          onClick={() => download(agreement.template_id)}
                        >
                          Download <i className="fe fe-download ms-1" />
                        </button>
                        {isAgreementSigned === false && (
                          <button
                            target="_blank"
                            rel="noreferrer"
                            className="btn btn-xs btn-info-soft ms-sm-1 button-space"
                            onClick={() => signature(agreement.template_id)}
                          >
                            Continue to Sign{" "}
                            <i className="fe fe-arrow-right-circle ms-1" />
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>

            {canContinue && (
              <div className="text-center text-md-start mt-4">
                <button
                  className="btn btn-eq-primary shadow lift"
                  onClick={() => goToPayment()}
                >
                  Continue <i className="fe fe-arrow-right-circle"></i>
                </button>
              </div>
            )}
          </Spinner>
        </div>
      </section>
    </>
  );
};

export default Agreement;
