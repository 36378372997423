import { useMutation, useQuery } from "@tanstack/react-query";
import UserClient from "../clients/UserClient";
import UserTokenService from "../services/UserTokenService";

/**
 * @param {import("@tanstack/react-query").QueryObserverOptions} options
 */
export const useCurrentUser = (options = {}) => {
  return useQuery(["me"], () => UserClient.me(), {
    enabled: !!UserTokenService.token(),
    ...options,
  });
};

export const useRegisterUserMutation = () => {
  return useMutation((userdata) => UserClient.legacyRegister(userdata));
};

export const useChangePassword = () => {
  return useMutation((payload) => UserClient.changePassword(payload));
};

/**
 * @param {import("@tanstack/react-query").MutationObserverOptions} options
 */
export const useUpdateCurrentUser = (options = {}) => {
  return useMutation(async (data) => UserClient.update(data), options);
};
