import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import Spinner from "./../../components/spinner";

import { offeringIsCrowdfunded } from "@equidefi/shared";
import { toast } from "react-toastify";
import APIClient from "../../clients/api";
import {
  investmentContinueStepRegD,
  investmentContinueUrlOpenOffering,
} from "../../helpers/investment";
import { useInvestment } from "../../hooks/useInvestments";
import { useOffering } from "../../hooks/queries/offerings";
import UserTokenService from "../../services/UserTokenService";
import { useInvestmentJumpToStep } from "../../hooks/use-investment-jump-to-step";

const Auth = (props) => {
  const dispatch = useDispatch();

  const { investmentId, token, redirectUrl } = useParams();
  const { history } = props;

  const [enableLoad, setEnableLoad] = useState(() => false);
  const { data: investment } = useInvestment(investmentId, {
    onError: () => {
      toast.error("Sorry, the link has expired.");
      history.push(`/investor/link-expired`);
    },
  });

  const { data: offering } = useOffering(investment?.offering_id, {
    onError: () => {
      toast.error("Sorry, the link has expired.");
      history.push(`/investor/link-expired`);
    },
  });

  const redirectRegDOffering = useCallback(() => {
    const { warning, url } = investmentContinueStepRegD(
      investment?.status,
      redirectUrl
    );

    if (warning) {
      toast.warn(warning);
    }

    history.push(url);
  }, [history, investment?.status, redirectUrl]);

  const redirectRegAOffering = useCallback(() => {
    const url = investmentContinueUrlOpenOffering(
      investment?.id,
      investment?.status,
      investment?.offering_id
    );
    if (!url) {
      toast.warn("Something went wrong. Please login to your vault.");
    }
    history.push(url ?? "/");
  }, [history, investment?.id, investment?.offering_id, investment?.status]);

  const [, setInvestmentId] = useInvestmentJumpToStep(offering?.id);

  useEffect(() => {
    const dataCall = async () => {
      UserTokenService.setToken(token);
      const me = await APIClient(token).get("/me");
      dispatch({ type: "user", payload: me.data });
      setEnableLoad(true);
    };

    dataCall();
  }, []);

  useEffect(() => {
    if (enableLoad && investment && offering) {
      if (offeringIsCrowdfunded(offering)) {
        setInvestmentId(investment.id);
        redirectRegAOffering();
      } else {
        // We still only use investment/offering redux in Reg D currently
        dispatch({ type: "investment", payload: investment });
        dispatch({ type: "offering", payload: offering });

        redirectRegDOffering();
      }
    }
  }, [enableLoad, investment, offering]);

  return <Spinner show={true} />;
};

export default Auth;
