import moment from "moment";
import { useSelector } from "react-redux";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";

const OfferingClosed = (props) => {
  const investment = useSelector((store) => store.investment);
  const offering = useSelector((store) => store.offering);

  useDocumentTitle([
    "CLOSED",
    investment?.offering?.name,
    investment?.issuer?.name,
  ]);

  return (
    <>
      <div className="container">
        <div className="row align-items-center vh-100">
          <div className="col-12 col-md-7 col-lg-6 order-md-2">
            <h1 className="display-4 text-center text-md-start">Oops!</h1>
            <p className="lead text-muted">{`Sorry! "${
              offering.name
            }" was closed/terminated on "${moment(
              offering.termination_date
            ).format("LL")}"`}</p>
          </div>
          <div className="col-12 col-md-5 col-lg-6 order-md-2  d-flex justify-content-end">
            <img
              src="/img/offering-closed.svg"
              className="img-fluid mb-6 mb-md-0 aos-init aos-animate"
              alt="..."
              data-aos="fade-up"
              data-aos-delay="100"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default OfferingClosed;
