import React from 'react';
import LeftMenu from './appMenu';

const Layout = ({ children }) => {
  const theme = document.getElementById('theme');
  theme.href = '/css/theme.css';

  const body = document.getElementById('body');
  body.className = '';

  return (
    <>
      <LeftMenu />
      <div className='main-content' id='admin'> {children}</div>
    </>
  );
};

export default Layout;
