import { offeringIsRegD, offeringIsRule506b } from "@equidefi/shared";
import moment from "moment";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";

const DISCLOSURE = ({ offering }) => {
  return (
    <>
      <h2>Offering Disclosures</h2>
      <div className="small ps-0">
        <p>
          {offering.issuer.name} is currently undertaking a private placement
          offering pursuant to Section 4(a)(2) of the Securities Act of 1933, as
          amended (the “Securities Act”), and/or Rule 506(c) of Regulation D
          promulgated thereunder. Investors should consider the investment
          objectives, risks, and investment time horizon of the Company
          carefully before investing. The offering documents relating to each
          offering of equity interests by the Company will contain this and
          other information concerning the Company, including risk factors,
          which should be read carefully before investing. Securities of the
          Company are being offered and sold in reliance on exemptions from
          registration under the Securities Act. In accordance therewith, you
          should be aware that (i) the securities may be sold only to
          “accredited investors,” as defined in Rule 501 of Regulation D; (ii)
          the securities will only be offered in reliance on an exemption from
          the registration requirements of the Securities Act and will not be
          required to comply with specific disclosure requirements that apply to
          registration under the Securities Act; (iii) the United States
          Securities and Exchange Commission (the “SEC”) will not pass upon the
          merits of or give its approval to the securities, the terms of the
          offering, or the accuracy or completeness of any offering materials;
          (iv) the securities will be subject to legal restrictions on transfer
          and resale and investors should not assume they will be able to resell
          their securities; investing in these securities involves a high degree
          of risk, and investors should be able to bear the loss of their entire
          investment. Furthermore, investors must understand that such
          investment could be illiquid for an indefinite period of time. The
          offering documents may include “forward-looking statements” within the
          meaning of Section 27A of the Securities Act and Section 21E of the
          Exchange Act of 1934, as amended, and are intended to be covered by
          the safe harbor provisions for forward looking statements. This
          information is supplied from sources we believe to be reliable but we
          cannot guarantee accuracy. Although we believe our expectations
          expressed in such forward-looking statements are reasonable, we cannot
          assure you that they will be realized. Investors are cautioned that
          such forward-looking statements involve risks and uncertainties,
          including, but not limited to the risks and uncertainties set forth in
          the attached materials, which could cause actual results to differ
          materially from the anticipated results set forth in such
          forward-looking statements. Any forward-looking statement made by us
          speaks only as of the date on which it is made, and we undertake no
          obligation to publicly update any forward-looking statement except as
          may be required by law.
        </p>
        <p>
          The Company is “Testing the Waters” under Regulation A under the
          Securities Act of 1933. The Company is not under any obligation to
          make an offering under Regulation A. No money or other consideration
          is being solicited in connection with the information provided, and if
          sent in response, will not be accepted. No offer to buy the securities
          can be accepted and no part of the purchase price can be received
          until an offering statement on Form 1-A has been filed and until the
          offering statement is qualified pursuant to Regulation A of the
          Securities Act of 1933, as amended, and any such offer may be
          withdrawn or revoked, without obligation or commitment of any kind, at
          any time before notice of its acceptance given after the qualification
          date. Any person&apos;s indication of interest involves no obligation
          or commitment of any kind. The information in that offering statement
          will be more complete than the information the Company is providing
          now, and could differ materially. You must read the documents filed.
          No offer to sell the securities or solicitation of an offer to buy the
          securities is being made in any state where such offer or sale is not
          permitted under the “blue sky” or securities laws thereof. No offering
          is being made to individual investors in any state unless and until
          the offering has been registered in that state or an exemption from
          registration exists therein. The securities offered using Regulation A
          are highly speculative and involve significant risks. The investment
          is suitable only for persons who can afford to lose their entire
          investment. Furthermore, investors must understand that such
          investment could be illiquid for an indefinite period of time. No
          public market currently exists for the securities, and if a public
          market develops following the offering, it may not continue. The
          Company intends to list its securities on a national exchange and
          doing so entails significant ongoing corporate obligations including
          but not limited to disclosure, filing and notification requirements,
          as well compliance with applicable continued quantitative and
          qualitative listing standards.
        </p>
        {/* <p>
          EquiDefi is a technology platform and not a licensed broker-dealer and
          as such will not act as a placement agent in connection with any
          financing transactions nor will take any commission-based fees for any
          services and is solely acting as a technology provider. All
          securities-related transactions and activities will be conducted
          through Digital Offering LLC, a registered Broker/Dealer and member
          FINRA/SIPC.
        </p>
        <b>For non-accredited investors:</b>
        <p>
          Restrictions on Investment Amount: no sale may be made to you in this
          offering if the aggregate purchase price you pay is more than 10% of
          the greater of your annual income or net worth. Different rules apply
          to accredited investors and non-natural persons. Before making any
          representation that your investment does not exceed applicable
          thresholds, we encourage you to review Rule 251(d)(2)(i)(c) of
          Regulation A. For general information on investing, we encourage you
          to refer to www.investor.gov.
        </p> */}
      </div>
    </>
  );
};

const NON_DISCLOSURE = (offering) => {
  return (
    <>
      <h2>Non-Disclosure Agreement</h2>
      <ol className="small">
        <li>
          I have requested and agree to receive certain information regarding{" "}
          {offering?.issuer?.name} a{" "}
          <span className="text-capitalize">{offering?.issuer?.name}</span>{" "}
          corporation (the “Company”). Information I receive whether written or
          oral or whether prepared by the Company or otherwise (the
          “Information”),may be deemed to be material non-public information. As
          a condition to my being furnished the Information, I agree to treat
          the Information as confidential and to take necessary precautions to
          safeguard and protect the confidentiality of the Information.
        </li>
        <li>
          I hereby agree that the Information will not be used for any purpose
          other than in connection with my evaluating the Company, and that I
          will not disclose in any manner whatsoever such Information, the fact
          that I have received such Information or that discussions or
          negotiations are taking place concerning my interest in the Company or
          any transaction it may propose
        </li>
        <li>
          I am aware that the United States securities laws prohibit any person
          who has received material, nonpublic information concerning a company
          from purchasing or selling securities of such company, or from
          communicating such information to any other person under circumstances
          in which it is reasonably foreseeable that such person is likely to
          purchase or sell such securities. I represent and warrant that I am an
          “accredited investor” as defined in Rule 501(a) under the Securities
          Act of 1933, as amended.{" "}
        </li>
        <li>
          Upon the Company’s request, I shall promptly redeliver to the Company
          or destroy all written Information (whether prepared by the Company or
          otherwise), and any oral information provided to me will continue to
          be subject to the terms of this agreement.
        </li>
        <li>
          The Company shall be entitled to specific performance or other
          equitable relief, including injunction, in the event of any breach or
          threatened breach of the provisions of this agreement and that I shall
          not oppose the granting of such relief. Such remedy shall not be
          deemed to be the exclusive remedy for a breach of this agreement but
          shall be in addition to all other remedies at law or in equity.
        </li>
        <li>
          Unless and until a definitive agreement with the Company has been
          executed and delivered, neither the Company nor I will be under any
          legal obligation of any kind whatsoever with respect to such a
          transaction by virtue of this agreement, except for the matters
          specifically agreed to herein, the Company a third-party beneficiary
          of this agreement.
        </li>
        <li>
          By executing this Agreement and accessing EquiDeFi.com (the “Website”)
          I acknowledge and agree that EquiDeFi, Ltd., a Wyoming corporation, is
          not offering or selling any securities and merely provides certain
          services as a software vendor to the Company and is not responsible
          for the accuracy, adequacy or completeness of any Information. I agree
          to hold EquiDeFi, Ltd., and its officers, directors, consultants,
          owners, employees, and agents, harmless from and against any and all
          claims involving the Company or my use of the Website or any products
          or services offered therein that are subscribed or purchased by me or
          any of my affiliates
        </li>
      </ol>
    </>
  );
};

const OpenOfferingDisclosure = ({ offering, onAccept = () => {} }) => {
  const [disclosureModal, showDisclosure] = useState(() => true);

  const handleAccept = () => {
    showDisclosure(false);
    localStorage.setItem(
      `offering/${offering.id}/accept-disclosure`,
      JSON.stringify({
        time: moment().toISOString(),
      })
    );
    onAccept();
  };

  const Content = () => {
    if (offeringIsRegD(offering) && offeringIsRule506b(offering)) {
      return <NON_DISCLOSURE offering={offering} />;
    } else {
      // Show Disclosure for Reg A, Reg A+ and 506 (c)
      return <DISCLOSURE offering={offering} />;
    }
  };

  return (
    <Modal
      show={disclosureModal}
      scrollable
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="xl"
    >
      <Modal.Body>
        <Content />
        <button
          className="btn btn-eq-primary shadow lift"
          onClick={handleAccept}
        >
          I AGREE
        </button>
      </Modal.Body>
    </Modal>
  );
};

export default OpenOfferingDisclosure;
