import React from 'react';

const InfoCard = ({ title, children }) => {
  return (
    <div className='card'>
      <div className='card-body'>
        <div className='row align-items-center'>
          <div className='col'>
            <h6 className='text-uppercase text-muted mb-2'>{title}</h6>
            <span className='h4 mb-0'>{children}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoCard;
