import clsx from "clsx";
import React from "react";
import { Form } from "react-bootstrap";

export const YesNoToggle = ({
  name,
  onChange = () => {},
  value = undefined,
  ...props
}) => {
  const noClasses = clsx("mt-1 me-2", {
    "fw-bolder": value === false,
    "text-muted": typeof value === "undefined" || value !== false,
  });
  const yesClasses = clsx("mt-1", {
    "fw-bolder": value === true,
    "text-muted": typeof value === "undefined" || value !== true,
  });

  return (
    <div className="d-flex align-items-center align-content-center">
      <span className={noClasses}>No</span>
      <Form.Check
        type="switch"
        {...props}
        id={props.id ?? `${name}-switch`}
        name={name}
        checked={value}
        value={value}
        onChange={onChange}
      />
      <span className={yesClasses}>Yes</span>
    </div>
  );
};
