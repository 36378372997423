import moment from "moment";
import React from "react";

const RecentActivityCard = ({ statusHistory }) => {
  return (
    <div className="card">
      <div className="card-header">
        <h4 className="card-header-title">Recent Activity</h4>
      </div>
      <div className="card-body p-0 px-4">
        <div className="list-group list-group-flush">
          {statusHistory?.map((status, index) => {
            return (
              <div className="list-group-item" key={index}>
                {status?.status}
                <div className="small text-muted">
                  {" "}
                  {moment(status.create_date).format("lll Z")}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default RecentActivityCard;
